import { Box, Flex } from '@chakra-ui/react'
import { LogoutActiveIcon, LogoutIcon } from 'assets/images/linkIcons'
import { useDprWithOs } from 'hooks/useDprWithOs'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const LogoutButton: FC<{
  onClick: () => void
}> = ({ onClick }) => {
  const { t } = useTranslation()
  const [isMouseOver, setMouseOver] = useState<boolean>(false)
  const handleMouseOver = () => setMouseOver(true)
  const handleMouseOut = () => setMouseOver(false)
  const dpr = useDprWithOs()

  const iconSize = 20 / dpr

  return (
    <Flex
      w='full'
      h='2.85rem'
      borderRadius={'0.85rem'}
      _hover={{ background: 'rgba(255,255,255, 0.12)' }}
      bg='transparent'
      px='1.14rem'
      alignItems={'center'}
      gap='0.85rem'
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onClick={onClick}
      cursor='pointer'
    >
      <Box fontSize={'2.14rem'}>
        {isMouseOver ? (
          <LogoutActiveIcon width={iconSize} height={iconSize} />
        ) : (
          <LogoutIcon width={iconSize} height={iconSize} />
        )}
      </Box>
      <Box color={'white'} fontSize={'1.14rem'} fontWeight={600} opacity={isMouseOver ? 1 : 0.6}>
        {t('links.logOut')}
      </Box>
    </Flex>
  )
}
