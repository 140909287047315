import { MiddlewareAPI, isRejected, Middleware } from '@reduxjs/toolkit'
import { OTPResponse, OTPType, PurposeType } from 'types'
import { UnverifiedResponse } from 'types/UnverifiedReponse'
import { setOtpState, setToken } from '../slices/auth'

export const rtkQueryAuthErrorHandler: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (action.payload) {
    if (action.payload.code === 'OTP_REQUIRED') {
      next(setOtpState(action.payload))
    }
    if (action.payload.status === 401) {
      if (
        action.payload.data.code === 'UNVERIFIED_EMAIL' ||
        action.payload.data.code === 'UNVERIFIED_PHONE_NUMBER'
      ) {
        const { data } = action.payload.data as UnverifiedResponse
        next(
          setOtpState({
            code: action.payload.data.code,
            purpose: PurposeType.SIGNUP,
            otpType: OTPType.EMAIL,
            userId: data.user.id,
          }),
        )
      }
    }
  }
  return next(action)
}
