import { Box, BoxProps, Flex, Image } from '@chakra-ui/react'
import React, { FC } from 'react'
import logo from 'assets/images/logo-no-bg.png'

interface Props extends BoxProps {
  logoVariant?: 'login' | 'navigation' | 'mobileHeader'
  width?: string | number
}

export const FSGLogoText: FC<Props> = ({ logoVariant = 'login', width, ...props }) => {
  if (logoVariant === 'login' || logoVariant === 'mobileHeader') {
    return (
      <Box
        {...props}
        as='h1'
        textStyle={logoVariant === 'login' ? 'logoText' : 'headerLogoText'}
        h={logoVariant === 'login' ? 'auto' : 'full'}
      >
        <Flex w={width || '70%'} h={'full'} justify='center'>
          <Image
            src={logo}
            alt='FSGXChange Logo'
            h={logoVariant === 'mobileHeader' ? '40px' : 'full'}
          ></Image>
        </Flex>
        <span hidden>FSGXChange</span>
        {/* FSG
        <Box as='span' color='brand.primary.purple'>
          XChange
        </Box> */}
      </Box>
    )
  } else {
    return (
      <Box {...props} as='h1' color='brand.primary.purple' textStyle={'logoTextNavigation'}>
        FSG
        <Box as='span' color='white'>
          XChange
        </Box>
      </Box>
    )
  }
}
