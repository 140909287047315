import { switchAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  switchAnatomy.keys,
)

const baseStyle = definePartsStyle({
  thumb: {
    bg: 'white',
  },
  track: {
    borderRadius: '1.71rem',
    bg: 'brand.secondary.red',
    _checked: {
      bg: 'brand.secondary.green',
    },
  },
})

export const Switch = defineMultiStyleConfig({
  baseStyle,
  defaultProps: {
    size: 'lg',
  },
})
