import { Box, Divider, Flex, Skeleton, Stack } from '@chakra-ui/react'
import moment from 'moment'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { DealTransaction } from 'types'
import { currencyFormat } from 'utils/currencyFormat'
import { TransactionRenderer } from './TransactionRenderer'
import { use } from 'i18next'
import { useDealTransactionSettings } from 'hooks/useDealTransactionSettings'

interface Props {
  dealTransaction?: DealTransaction
  onClick: (transaction: DealTransaction) => void
}

export const MobileDealTransaction: FC<Props> = ({ dealTransaction, onClick }) => {
  const { t } = useTranslation()
  const transactionTypeSettings = useDealTransactionSettings(dealTransaction)

  return (
    <Box paddingBottom={'1.14rem'} onClick={() => dealTransaction && onClick(dealTransaction)}>
      <Box borderRadius={'1.71rem'} boxShadow={'0 0 5.14rem rgba(15, 3, 30, 0.08)'} minH='11.42rem'>
        <Flex px='1.14rem' pt='1.14rem' justifyContent={'space-between'} alignItems='center'>
          {dealTransaction ? (
            <>
              <Flex gap='0.85rem'>
                <TransactionRenderer
                  variant='details'
                  transactionType={dealTransaction.remark}
                ></TransactionRenderer>
                <Box>
                  <Box
                    textTransform={'capitalize'}
                    color='brand.black.900'
                    fontSize={'1.14rem'}
                    fontWeight={600}
                    mb='0.28rem'
                  >
                    {dealTransaction.remark}
                  </Box>
                  <Box color='brand.black.900' opacity={0.4} fontSize='1rem'>
                    {dealTransaction.reference}
                  </Box>
                </Box>
              </Flex>
              <Box color='brand.black.900' fontSize={'1.14rem'} fontWeight={600}>
                {currencyFormat(dealTransaction.amount)}{' '}
                {
                  dealTransaction.deal?.[
                    transactionTypeSettings.slugKey as 'fromCurrency' | 'toCurrency'
                  ]?.slug
                }
              </Box>
            </>
          ) : (
            <Skeleton></Skeleton>
          )}
        </Flex>
        <Divider my='1.14rem'></Divider>

        <Flex px='1.14rem' pb='1.14rem' justifyContent={'space-between'}>
          <Stack>
            {dealTransaction ? (
              <>
                <Box color='brand.black.900' fontSize={'1.14rem'} opacity={0.4}>
                  {t('createDeal.exchangeRate')}
                </Box>
                <Box color='brand.black.900' fontSize={'1.14rem'}>
                  1 {dealTransaction.deal.fromCurrency.slug} =
                  {currencyFormat(+(dealTransaction.deal.rate || 0))}
                  {dealTransaction.deal.toCurrency.slug}
                </Box>
              </>
            ) : (
              <Skeleton></Skeleton>
            )}
          </Stack>
          <Stack>
            {dealTransaction ? (
              <>
                <Box color='brand.black.900' fontSize={'1.14rem'} opacity={0.4}>
                  Date
                </Box>
                <Box color='brand.black.900' fontSize={'1.14rem'}>
                  {moment(dealTransaction.createdAt).format('MMM DD, YYYY')}
                </Box>
              </>
            ) : (
              <Skeleton></Skeleton>
            )}
          </Stack>
        </Flex>
      </Box>
    </Box>
  )
}
