import {
  Box,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  chakra,
  Button,
  Stack,
} from '@chakra-ui/react'
import { FSGModalWrapper } from 'components'
import moment from 'moment'
import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DealTransaction, WalletTransaction } from 'types'
import { currencyFormat } from 'utils/currencyFormat'
// import { DealTransactionDetails } from './DealTransactionDetails'
import { TransactionRenderer } from '../TransactionRenderer'
import { TransactionDetails } from './TransactionDetails'

interface Props {
  isOpen: boolean
  onClose: () => void
  transaction: WalletTransaction | null
}

export const WalletTransactionDetails: FC<Props> = ({ isOpen, onClose, transaction }) => {
  const { t } = useTranslation()
  const dateFormat = useMemo(() => {
    if (transaction) {
      const momentDate = moment(transaction.createdAt)
      return `${momentDate.format('MMM DD, YYYY')} at ${momentDate.format('hh:mm A')}`
    }
  }, [transaction])

  return (
    <FSGModalWrapper isOpen={isOpen} onClose={onClose} isCentered size={{ base: 'full', md: 'md' }}>
      <Flex w='full' justifyContent={'center'} direction='column' height='full'>
        {transaction && (
          <>
            <Flex justifyContent={'center'} direction='column' alignItems={'center'}>
              <TransactionRenderer
                variant='details'
                transactionType={transaction.type}
              ></TransactionRenderer>
              <Box textTransform={'capitalize'} mt='1.14rem' fontSize={'1rem'}>
                {transaction.type}
              </Box>
            </Flex>
            <Box fontSize={'1.71rem'} fontWeight={800} textAlign='center'>
              {currencyFormat(transaction.amount)} {transaction.wallet.currency.slug}
            </Box>
            <Stack justifyContent={'space-between'} mt='1.14rem' mb='1.71rem' fontSize={'1rem'}>
              <Box>
                <chakra.span fontWeight={600}>Reference number:</chakra.span>{' '}
                {transaction.reference}
              </Box>
              <Box>
                <chakra.span fontWeight={600}>Date:</chakra.span> {dateFormat}
              </Box>
            </Stack>
            {<TransactionDetails transactionDetails={transaction} />}
            <Button w='full' mt='2.28rem' onClick={onClose}>
              {t('buttons.close')}
            </Button>
          </>
        )}
      </Flex>
    </FSGModalWrapper>
  )
}
