import { Flex, Grid, Spinner } from '@chakra-ui/react'
import { FSGLogoText } from 'components'
import React from 'react'

export const LoaderScreen = () => {
  return (
    <Flex justifyContent={'center'} px={'1.42rem'}>
      <Grid
        h='100vh'
        alignItems={'center'}
        justifyContent='center'
        gap={'0.57rem'}
        gridTemplateColumns={'max-content 1fr'}
      >
        <Spinner
          thickness='0.28rem'
          speed='0.65s'
          emptyColor='gray.200'
          color='brand.primary.purple'
          size='xl'
        />
        <FSGLogoText width={'100%'} />
      </Grid>
    </Flex>
  )
}
