import { createSelector } from '@reduxjs/toolkit'
import { flatten, groupBy } from 'lodash'
import { RootStore } from 'store'

export const currencyState = (state: RootStore) => state.currency

export const supportedCurrenciesSelector = createSelector(currencyState, (state) => state.supported)
export const payoutRequirementsSelector = createSelector(
  currencyState,
  (state) => state.payoutRequirements,
)
export const banksSelector = createSelector(currencyState, (state) => state.banks)

export const pairsBySlugSelector = createSelector(currencyState, (state) => {
  return groupBy(flatten(state.supported.map((currency) => currency.pairs)), 'currency.slug')
})
