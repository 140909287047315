import { Box, Select } from '@chakra-ui/react'
import { VerificationContext } from 'App'
import { FSGModalWrapper } from 'components'
import { toast } from 'components/FSGToast'
import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import { useGetAccountsQuery } from 'store/services/user.api'
import { Account, ModalProps } from 'types'
import BasePayoutForm from './BasePayoutForm'

interface Props extends ModalProps {
  account: Account | null
  createNew: boolean
}

export const EditCreateModal: FC<Props> = ({ account, createNew, ...props }) => {
  const [currency, setCurrency] = useState<'NGN' | 'CAD'>('CAD')
  const [skip, setSkip] = useState<boolean>(true)
  const { setOnConfirm } = useContext(VerificationContext)
  useGetAccountsQuery(undefined, { skip })

  const activeSlug = useMemo(() => {
    if (createNew) {
      return currency
    }
    return account?.currency.slug
  }, [account, currency, createNew])

  useEffect(() => {
    setOnConfirm?.(() => {
      toast({
        title: 'Payment accounts',
        description: 'Payment account successfully updated',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      })
      setSkip(false)
    })
  })

  return (
    <FSGModalWrapper {...props}>
      {createNew && (
        <>
          <Box fontSize={'1.71rem'} fontWeight={800} color='brand.black.900' mb='0.57rem'>
            Create new account
          </Box>
          <Select
            value={currency}
            onChange={(e) => setCurrency(e.target.value as 'NGN' | 'CAD')}
            mb='1.14rem'
          >
            <option value='NGN'>NGN</option>
            <option value='CAD'>CAD</option>
          </Select>
        </>
      )}
      {activeSlug && (
        <BasePayoutForm
          currencySlug={activeSlug}
          account={account}
          onCancel={props.onClose}
          createNew={createNew}
        ></BasePayoutForm>
      )}
    </FSGModalWrapper>
  )
}
