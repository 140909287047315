import React, { FC, PropsWithChildren, useEffect, useState } from 'react'
import Joyride, { Step } from 'react-joyride'
import { useSelector } from 'react-redux'
import { userInfoSelector } from 'store/selectors/user'
import { JoyrideTooltip } from './JoyrideTooltip'
import { use } from 'i18next'

const JoyrideContext = React.createContext<{
  setRun: (run: boolean) => void
}>({
  setRun: (run: boolean) => {
    return
  },
})

const stepsTemplate: Step[] = [
  {
    target: '.userSettingsFinish',
    content: 'Set up your account',
    disableBeacon: true,
  },
  {
    target: '.walletInfo',
    content: 'Here is your wallet info!',
    disableBeacon: true,
  },
  {
    target: '.marketPlaceDeal',
    content: 'Here is your wallet info!',
    disableBeacon: true,
  },
  {
    target: '.createDealButton',
    content: 'Or you can create a deal to sell money from your wallet',
    disableBeacon: true,
  },
]

export const JoyrideWrapper: FC<PropsWithChildren> = ({ children }) => {
  const userData = useSelector(userInfoSelector)
  const [steps, setSteps] = useState(stepsTemplate)
  const [run, setRun] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setSteps((steps) => steps.filter((step) => !!document.querySelector(step.target as string)))
      if (userData && !userData.hasFinishedTour) {
        setRun(true)
      }
    }, 2000)
  }, [userData, setSteps, setRun])

  const joyrideValue = React.useMemo(() => ({ setRun }), [setRun])

  return (
    <JoyrideContext.Provider value={joyrideValue}>
      <Joyride
        steps={steps}
        run={run}
        tooltipComponent={JoyrideTooltip}
        continuous
        callback={(e) => {
          if (e.action === 'reset') {
            setRun(false)
          }
        }}
      />
      {children}
    </JoyrideContext.Provider>
  )
}

export const useSetRun = () => {
  const context = React.useContext(JoyrideContext)
  if (!context) {
    throw new Error('useSetRun must be used within a JoyrideWrapper')
  } else {
    return context.setRun
  }
}
