import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OTPResponse } from 'types'

interface AuthState {
  token: string | null
  refreshToken: string | null
  otpState: {
    state: OTPResponse | null
    isOpen: boolean
  }
}

const initialState = {
  token: null,
  refreshToken: null,
  otpState: {
    state: null,
    isOpen: false,
  },
} as AuthState

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state: AuthState, action: PayloadAction<string | null>) => ({
      ...state,
      token: action.payload,
    }),
    setRefreshToken: (state: AuthState, action: PayloadAction<string | null>) => ({
      ...state,
      refreshToken: action.payload,
    }),
    logOut: (state) => ({
      ...state,
      token: null,
    }),
    setOtpState: (state: AuthState, action: PayloadAction<OTPResponse | null>) => ({
      ...state,
      otpState: { state: action.payload, isOpen: true },
    }),
    closeVerificationModal: (state: AuthState) => ({
      ...state,
      otpState: { state: null, isOpen: false },
    }),
  },
})

export const { setToken, setRefreshToken, logOut, setOtpState, closeVerificationModal } =
  authSlice.actions
export default authSlice.reducer
