import UAParser from 'ua-parser-js'

export const useDeviceOs = () => {
  // get the device os from ua-parser-js
  // return the device os
  const parser = new UAParser('user-agent')
  parser.setUA(navigator.userAgent)
  const parserResults = parser.getResult()

  return parserResults.os.name || 'undefined_os'
}
