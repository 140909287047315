import React, { PropsWithChildren, useCallback } from 'react'
import { useGetBanksQuery } from 'store/services/banks.api'
import {
  useGetPayoutRequirementsMutation,
  useGetSupportedCurrenciesQuery,
} from 'store/services/currency.api'
import {
  useGetAccountsQuery,
  useGetKYCStatusQuery,
  useGetNotificationSettingsQuery,
  useGetUserQuery,
  useGetWalletQuery,
} from 'store/services/user.api'

interface InitialDataLoader {
  refetchData?: () => void
  isDataLoaded?: boolean
}

export const InitialDataLoaderContext = React.createContext<InitialDataLoader>({})

export const InitialLoaderProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const token = localStorage.getItem('token')
  const { isLoading, refetch: refetchUserData } = useGetUserQuery()
  const { data: notificationSettings, isLoading: isNotificationsLoading } =
    useGetNotificationSettingsQuery()
  const { data: accounts, isLoading: isAccountsLoading } = useGetAccountsQuery()
  const request = useGetBanksQuery()
  const { data: supportedCurrencies, isLoading: isCurrenciesLoading } =
    useGetSupportedCurrenciesQuery()
  const [getRequirements, { data: requirements, isLoading: isRequirementsLoading }] =
    useGetPayoutRequirementsMutation()
  const { data: walletData, isLoading: isWalletLoading } = useGetWalletQuery(undefined, {
    pollingInterval: token ? 60000 : undefined,
  })
  const { data: KYCStatus, isLoading: isKYCLoading, refetch: refetchKYC } = useGetKYCStatusQuery()

  const refetchData = useCallback(() => {
    refetchKYC()
    refetchUserData()
  }, [refetchKYC, refetchUserData])

  React.useEffect(() => {
    if (supportedCurrencies) {
      for (const currency of supportedCurrencies.currencies) {
        getRequirements(currency)
      }
    }
  }, [supportedCurrencies])

  const isDataLoaded = React.useMemo(() => {
    return (
      isLoading ||
      isNotificationsLoading ||
      isAccountsLoading ||
      isCurrenciesLoading ||
      isRequirementsLoading ||
      isWalletLoading ||
      isKYCLoading
    )
  }, [
    isLoading,
    isNotificationsLoading,
    isAccountsLoading,
    isCurrenciesLoading,
    isRequirementsLoading,
    isWalletLoading,
    isKYCLoading,
  ])

  return (
    <InitialDataLoaderContext.Provider
      value={{
        isDataLoaded: !isDataLoaded,
        refetchData,
      }}
    >
      {children}
    </InitialDataLoaderContext.Provider>
  )
}
