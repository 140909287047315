import { Box, Button, Flex, Grid, Image, Stack } from '@chakra-ui/react'
import { FSGModalWrapper } from 'components'
import React from 'react'
import { ModalProps } from 'types'
import trashIcon from 'assets/images/trash.svg'

interface Props extends ModalProps {
  onConfirm: () => void
}

export const DeleteDealModal: React.FC<Props> = ({ onConfirm, ...props }) => {
  return (
    <FSGModalWrapper {...props}>
      <Stack spacing={'1.14rem'} mb='1.71rem'>
        <Flex justifyContent={'center'}>
          <Image src={trashIcon}></Image>
        </Flex>
        <Box fontSize={'1.42rem'} fontWeight={800} color='brand.black.900' textAlign={'center'}>
          Delete deal
        </Box>
        <Box fontSize={'1.42rem'} color='brand.black.900' textAlign={'center'} lineHeight={'150%'}>
          Deleting your deal will remove it from the marketplace. Any amount of your deal that was
          already purchased, has been processed as usual
        </Box>
      </Stack>
      <Grid templateColumns={'1fr 1fr'} gap={'1.28rem'}>
        <Button variant={'outline'} onClick={props.onClose}>
          Cancel
        </Button>
        <Button onClick={onConfirm}>Delete deal</Button>
      </Grid>
    </FSGModalWrapper>
  )
}
