import {
  Box,
  Flex,
  Image,
  Stack,
  Grid,
  Button,
  Select,
  FormControl,
  HStack,
  FormLabel,
  InputGroup,
} from '@chakra-ui/react'
import { FSGModalWrapper, MoneyInput, WarningMessage } from 'components'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { getWalletsDictsSelector, userAccountsByCurrencySlugSelector } from 'store/selectors/user'
import { Account, Currency, ModalProps } from 'types'
import withdraw from 'assets/images/transactionTypes/withdraw-no-circle.svg'
import { useTranslation } from 'react-i18next'
import { WithdrawTransactionDetails } from './WithdrawTransactionDetails'
import { usePayoutsMutation, useVerifyPayoutMutation } from 'store/services/user.api'
import { AmplitudeEvents, useTrackAction } from 'pages/MainLayout/AmplitudeProvider'
import { useDebounce } from 'usehooks-ts'

type Props = ModalProps

export const WithdrawFundsModal: React.FC<Props> = ({ ...props }) => {
  const { t } = useTranslation()
  const track = useTrackAction()

  const [
    verifyPayout,
    { data: verificationResult, isLoading: isVerifying, reset: resetVerification },
  ] = useVerifyPayoutMutation()
  const [payout, { isLoading: isPayingOut }] = usePayoutsMutation()
  const [accountId, setAccountId] = useState<string | undefined>(undefined)

  const wallets = useSelector(getWalletsDictsSelector)
  const payoutAccounts = useSelector(userAccountsByCurrencySlugSelector)

  const [verifiedValue, setVerifiedValue] = useState(0)
  const [amount, setAmount] = useState('0')
  const numberAmount = useMemo(() => parseFloat(amount), [amount])
  const debouncedAmount = useDebounce(numberAmount, 500)

  const [currency, setCurrency] = useState<Currency | null>()

  const activePayoutAccount = useMemo(() => {
    if (currency && payoutAccounts) {
      return payoutAccounts[currency.slug]
    } else {
      return null
    }
  }, [currency, payoutAccounts])

  const activeCurrencyData = useMemo(() => {
    if (activePayoutAccount?.length) {
      return {
        currencyId: activePayoutAccount[0].currency.id,
        accountId: activePayoutAccount.filter((acc) => acc.id === accountId).length
          ? accountId
          : activePayoutAccount[0].id,
      }
    }
    return null
  }, [activePayoutAccount, accountId])

  const handleSubmitClick = useCallback(
    async (action: 'confirm') => {
      if (activePayoutAccount && amount && (accountId || activePayoutAccount.length === 1)) {
        const actionCall = payout
        const response = await actionCall({
          accountId: accountId || activePayoutAccount[0].id,
          amount: numberAmount,
          currencyId: activePayoutAccount[0].currency.id,
        })
        action === 'confirm' &&
          track?.(AmplitudeEvents.WITHDRAW_FUNDS, {
            currency: activePayoutAccount[0].currency.slug,
            amount,
          })
        if ('data' in response) {
          setVerifiedValue(numberAmount)
          action === 'confirm' && props.onClose()
        } else {
          console.error('Oops', response.error)
        }
      }
    },
    [activePayoutAccount, numberAmount, accountId, track],
  )

  const verifyWithdrawAmount = useCallback(
    async (amount: number, accountId?: string, currencyId?: string) => {
      if (
        activePayoutAccount &&
        amount &&
        (accountId || activePayoutAccount?.length === 1) &&
        currencyId
      ) {
        const response = await verifyPayout({
          accountId: accountId || activePayoutAccount[0].id,
          amount,
          currencyId: currencyId,
        })
        if ('data' in response) {
          setVerifiedValue(amount)
        } else {
          console.error('Oops', response.error)
        }
      }
    },
    [activePayoutAccount, track],
  )

  useEffect(() => {
    if (activeCurrencyData)
      verifyWithdrawAmount(
        debouncedAmount,
        activeCurrencyData?.accountId,
        activeCurrencyData?.currencyId,
      )
  }, [debouncedAmount, activeCurrencyData])

  useEffect(() => {
    if (activePayoutAccount?.length) {
      setAccountId(activePayoutAccount[0].id)
    }
  }, [activePayoutAccount, currency])

  const disableWithdraw = useMemo(() => {
    if (currency?.slug && wallets?.[currency.slug] && activePayoutAccount) {
      return +numberAmount <= +wallets?.[currency.slug][0].availableBalance && numberAmount !== 0
    }
    return false
  }, [wallets, currency, amount, activePayoutAccount])

  return (
    <FSGModalWrapper {...props} size={{ base: 'full', md: 'md' }}>
      <Box w='full'>
        <Stack spacing={'1.14rem'} mb='1.71rem'>
          <Flex justifyContent={'center'}>
            <Image src={withdraw}></Image>
          </Flex>

          <Box textAlign={'center'} fontSize={['1.71rem']} fontWeight={800}>
            {t('wallet.withdrawFunds')}
          </Box>
          <Box textAlign={'center'} fontSize={['1.14rem']}>
            {t('wallet.proceedWithdraw')}
          </Box>
        </Stack>
        <Box mb='1.71rem'>
          <MoneyInput
            id='amount'
            label='Amount'
            isDisabled={isPayingOut}
            value={amount}
            onChange={(e) => setAmount(e as string)}
            onCurrencyChange={(currency) => setCurrency(currency)}
          ></MoneyInput>
        </Box>
        {activePayoutAccount && (
          <Box mb='1.71rem'>
            <FormControl>
              <FormLabel
                as='label'
                display='block'
                fontSize='md'
                fontWeight={800}
                htmlFor={'account'}
                marginBottom='0.57rem'
              >
                <HStack justifyContent={'space-between'}>
                  <Box>Payout account</Box>
                </HStack>
              </FormLabel>
              <InputGroup>
                <Select
                  id='account'
                  value={accountId}
                  isRequired
                  onChange={(e) => setAccountId(e.target.value)}
                >
                  {activePayoutAccount.map((account) => {
                    return (
                      <option key={account.id} value={account.id}>
                        {account.account.accountName} {account.account.bankName}{' '}
                        {account.account.destinationBankAccountNumber || account.account.email}
                      </option>
                    )
                  })}
                </Select>
              </InputGroup>
            </FormControl>
          </Box>
        )}

        {currency?.slug && wallets?.[currency.slug] && activePayoutAccount ? (
          <Box>
            <WithdrawTransactionDetails
              amount={numberAmount}
              wallet={wallets[currency.slug][0]}
              verificationResult={verificationResult}
            ></WithdrawTransactionDetails>
          </Box>
        ) : (
          <WarningMessage
            text={
              currency?.slug && !wallets?.[currency.slug]
                ? t('wallet.noWallet')
                : !activePayoutAccount
                ? t('wallet.noPayoutAccount')
                : ''
            }
          ></WarningMessage>
        )}
      </Box>

      <Grid templateColumns={'1fr 1fr'} gap={'2.14rem'} mt='2.28rem'>
        <Button isDisabled={isPayingOut} variant={'outline'} onClick={props.onClose}>
          {t('buttons.cancel')}
        </Button>
        <Button
          isLoading={isVerifying || isPayingOut}
          onClick={() => handleSubmitClick('confirm')}
          disabled={!disableWithdraw}
        >
          {t('wallet.withdraw')} {currency?.slug}
        </Button>
      </Grid>
    </FSGModalWrapper>
  )
}
