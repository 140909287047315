import { Box, Flex, Modal, ModalContent, ModalOverlay, chakra, Button } from '@chakra-ui/react'
import moment from 'moment'
import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DealTransaction } from 'types'
import { currencyFormat } from 'utils/currencyFormat'
import { DealTransactionDetails } from './DealTransactionDetails'
import { TransactionRenderer } from './TransactionRenderer'
import { useDealTransactionSettings } from 'hooks/useDealTransactionSettings'

interface Props {
  isOpen: boolean
  onClose: () => void
  transaction: DealTransaction | null
}

export const TransactionDetailsModal: FC<Props> = ({ isOpen, onClose, transaction }) => {
  const { t } = useTranslation()
  const dateFormat = useMemo(() => {
    if (transaction) {
      const momentDate = moment(transaction.createdAt)
      return `${momentDate.format('MMM DD, YYYY')} at ${momentDate.format('hh:mm A')}`
    }
  }, [transaction])
  const transactionSettings = useDealTransactionSettings(transaction)
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={{ base: 'full', md: 'md' }}>
      <ModalOverlay />
      <ModalContent minWidth={{ base: 'none', md: '37.85rem' }}>
        <Flex w='full' justifyContent={'center'} direction='column' height='full'>
          {transaction && (
            <>
              <Flex justifyContent={'center'} direction='column' alignItems={'center'}>
                <TransactionRenderer
                  variant='details'
                  transactionType={transaction.remark}
                ></TransactionRenderer>
                <Box textTransform={'capitalize'} mt='1.14rem' fontSize={'1rem'}>
                  {transaction.remark}
                </Box>
              </Flex>
              <Box fontSize={'1.71rem'} fontWeight={800} textAlign='center'>
                {currencyFormat(transaction.amount)} {transaction.deal[transactionSettings.slugKey as 'fromCurrency' | 'toCurrency'].slug}
              </Box>
              <Flex justifyContent={'space-between'} mt='1.14rem' mb='1.71rem' fontSize={'1rem'}>
                <Box>
                  <chakra.span fontWeight={600}>Order number:</chakra.span> {transaction.reference}
                </Box>
                <Box>
                  <chakra.span fontWeight={600}>Date:</chakra.span> {dateFormat}
                </Box>
              </Flex>
              <DealTransactionDetails transactionDetails={transaction} />
              <Button w='full' mt='2.28rem' onClick={onClose}>
                {t('buttons.close')}
              </Button>
            </>
          )}
        </Flex>
      </ModalContent>
    </Modal>
  )
}
