import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputProps,
} from '@chakra-ui/react'
import React, { FC } from 'react'

export interface Props extends InputProps {
  label?: string
  error?: string
  link?: React.ReactNode
  prependElement?: React.ReactNode
}

export const FSGInput: FC<Props> = ({ children, label, error, prependElement, link, ...props }) => {
  return (
    <FormControl isInvalid={props.isInvalid}>
      {label && (
        <FormLabel
          as='label'
          display='block'
          fontSize='md'
          fontWeight={800}
          htmlFor={props.id}
          marginBottom='0.57rem'
        >
          <HStack justifyContent={'space-between'}>
            <Box>
              {label}{' '}
              <Box as='span' color={'brand.secondary.red'}>
                {props.isRequired && '*'}
              </Box>
            </Box>
            {link}
          </HStack>
        </FormLabel>
      )}
      <InputGroup>
        {prependElement}
        <Input {...props} variant='something' />
        {children}
      </InputGroup>
      {!!error && (
        <FormErrorMessage position={'absolute'} bottom={0}>
          {error}
        </FormErrorMessage>
      )}
    </FormControl>
  )
}
