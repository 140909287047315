import { Box, chakra, Stack, Flex, Grid, GridItem, Divider } from '@chakra-ui/react'
import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Currency, Pair } from 'types'
import { currencyFormat } from 'utils/currencyFormat'
import { MAIN_DEAL_SLUG, TO_CURRENCY_SLUG } from 'utils/constants'

const billRenderer = (text: string, value: string, discountValue?: string, discount?: number) => {
  return (
    <Flex
      position='relative'
      justifyContent={'space-between'}
      color='brand.black.700'
      lineHeight={'120%'}
      width='100%'
    >
      <Box
        position={'absolute'}
        width='100%'
        borderStyle={'dashed'}
        borderWidth='0.07rem'
        opacity={0.5}
        top='50%'
        borderColor={'brand.black.700'}
      ></Box>
      <Box
        fontSize={'1rem'}
        position={'relative'}
        zIndex={3}
        bg='brand.black.100'
        fontWeight={'600'}
        paddingRight={'0.57rem'}
      >
        {text}
      </Box>
      <Box
        fontSize={'1rem'}
        position={'relative'}
        zIndex={3}
        fontWeight='800'
        bg='brand.black.100'
        paddingLeft={'0.57rem'}
        paddingRight='0.14rem'
      >
        {discount ? (
          <Box>
            <Box as={'span'} textDecoration='line-through'>
              {discountValue}
            </Box>
            <Box as={'span'} ml='0.57rem'>
              {value}
            </Box>
          </Box>
        ) : (
          value
        )}
      </Box>
    </Flex>
  )
}

interface Props {
  fromCurrency: Currency
  toCurrency: Currency
  pair: Pair
  rate: number
  realRate: number
  amount: number
  commission?: number
  discount?: number
}

export const PaymentInfo: FC<Props> = ({
  fromCurrency,
  toCurrency,
  rate,
  realRate,
  amount,
  commission,
  discount,
}) => {
  const { t } = useTranslation()
  const totalPay = useMemo(() => {
    return amount + (commission || 0)
  }, [amount, commission])
  const totalAmount = useMemo(() => {
    return amount * realRate
  }, [amount, rate])

  return (
    <Box bg='brand.black.100' p='1.71rem' borderRadius={'1.14rem'}>
      <chakra.h4 textStyle={'h4'} fontWeight='800' color={'brand.black.900'} mb='1.14rem'>
        {t('createDeal.transactionDetails')}
      </chakra.h4>
      <Stack spacing={'1.14rem'}>
        {billRenderer(
          t('createDeal.exchangeRate'),
          `1${MAIN_DEAL_SLUG} = ${rate}${TO_CURRENCY_SLUG}`,
        )}
        {billRenderer(t('createDeal.dealAmount'), `${currencyFormat(amount)} ${fromCurrency.slug}`)}
        {billRenderer(
          t('createDeal.commission'),
          `${currencyFormat(commission || 0)} ${fromCurrency.slug}`,
          `${currencyFormat(discount || 0)} ${fromCurrency.slug}`,
          discount,
        )}
        {/* {billRenderer(t('createDeal.discount'), `${discount} ${fromCurrency.slug}`)} */}
        <Divider borderColor={'brand.black.200'} opacity='1'></Divider>
        {billRenderer(
          t('createDeal.payAmount'),
          `${currencyFormat(totalPay)} ${fromCurrency.slug}`,
        )}
        <Divider borderColor={'brand.black.200'} opacity='1'></Divider>
        <Flex
          justifyContent={'space-between'}
          textStyle={'h4'}
          fontWeight='800'
          color={'brand.black.900'}
        >
          <Box>{t('createDeal.receiveAmount')}</Box>
          <Box>
            {currencyFormat(isFinite(totalAmount) ? totalAmount || 0 : 0)} {toCurrency.slug}
          </Box>
        </Flex>
      </Stack>
    </Box>
  )
}
