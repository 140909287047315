import {
  Box,
  Flex,
  Grid,
  GridItem,
  InputLeftElement,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from '@chakra-ui/react'
import { useFormikContext, useField } from 'formik'
import React, { FC } from 'react'
import { FSGInput } from './FSGInput'

interface Props {
  value?: number
  name?: string
  onChange: (e: React.ChangeEvent<any>) => void
}

export const FSGExchangeRate: FC<Props> = (props) => {
  const { setFieldValue } = useFormikContext()
  const [field] = useField<number>(props.name || '')

  return (
    <>
      <Box w='full' px='0.85rem'>
        <>
          <Flex
            mb='1.71rem'
            fontSize={'1.28rem'}
            color='brand.black.900'
            fontWeight={800}
            alignItems='center'
            gap='0.57rem'
          >
            <Flex>
              1 CAD&nbsp;<Box>=</Box>
            </Flex>

            <Flex justifyContent={'flex-start'} alignItems={'center'} gap='1.14rem'>
              <FSGInput
                fontSize={'1.28rem'}
                color='brand.black.900'
                fontWeight={800}
                display='flex'
                value={props.value || 0}
                type='numeric'
                h={'2.28rem'}
                w={'4.85rem'}
                isRequired
                onChange={(e) => setFieldValue(field.name, +e.target.value)}
              ></FSGInput>
              <Box>NGN</Box>
            </Flex>
          </Flex>
          <Slider
            aria-label='slider-ex-4'
            defaultValue={props.value}
            value={props.value || 0}
            onChange={(e) => setFieldValue(field.name, e)}
            focusThumbOnChange={false}
            min={1}
            max={400}
          >
            <SliderTrack bg='brand.black.200' height={'0.57rem'}>
              <SliderFilledTrack bg='brand.primary.purple' height={'0.57rem'} />
            </SliderTrack>
            <SliderThumb
              boxSize={6}
              background='brand.primary.purple'
              border='solid 0.42rem'
              borderColor={'white'}
            ></SliderThumb>
          </Slider>
        </>
      </Box>
    </>
  )
}
