import { range } from 'lodash'
import { useMemo } from 'react'
import { PaginationMeta } from 'types'

export const DOTS = 'dots'

export const usePagination = ({
  meta,
}: {
  meta: PaginationMeta
}): (string | number)[] | undefined => {
  return useMemo(() => {
    const siblingCount = 1
    const totalPageCount = Math.ceil(meta.total / meta.perPage)

    const totalPageNumbers = siblingCount + 5

    const leftSiblingIndex = Math.max(meta.currentPage - siblingCount, 1)
    const rightSiblingIndex = Math.min(meta.currentPage + siblingCount, totalPageCount)
    const shouldShowLeftDots = leftSiblingIndex > 2
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2
    const firstPageIndex = 1
    const lastPageIndex = totalPageCount

    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount + 1)
    }

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 3 + 2 * siblingCount
      const leftRange = range(1, leftItemCount)

      return [...leftRange, DOTS, totalPageCount]
    }

    /*
    	Case 3: No right dots to show, but left dots to be shown
    */
    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 3 + 2 * siblingCount
      const rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount)
      return [firstPageIndex, DOTS, ...rightRange]
    }

    /*
    	Case 4: Both left and right dots to be shown
    */
    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex)
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex]
    }
  }, [meta])
}
