import UAParser from 'ua-parser-js'
import { v4 } from 'uuid'
import Cookies from 'js-cookie'
import { Device } from 'types'

const DEVICE_COOKIE = 'device_id'

export const getDeviceData = (): Device => {
  const parser = new UAParser('user-agent')
  parser.setUA(navigator.userAgent)
  const parserResults = parser.getResult()

  const deviceId = Cookies.get(DEVICE_COOKIE) || v4()
  Cookies.set(DEVICE_COOKIE, deviceId, { expires: 7 })

  return {
    deviceId,
    operatingSystem: parserResults.os.name || 'undefined_os',
    name: `${parserResults.device.vendor}-${parserResults.device.type}-${parserResults.device.model}`,
  }
}
