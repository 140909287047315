import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const success = defineStyle({
  background: 'brand.secondary.greenLight',
  color: 'brand.secondary.green',
  fontSize: '0.85rem',
  fontWeight: 600,
})

const error = defineStyle({
  background: 'brand.secondary.redLight',
  color: 'brand.secondary.red',
  fontSize: '0.85rem',
  fontWeight: 600,
})

const warning = defineStyle({
  background: 'brand.secondary.yellowLight',
  color: 'brand.secondary.yellow',
  fontSize: '0.85rem',
  fontWeight: 600,
})

const filter = defineStyle({
  background: 'brand.secondary.purpleLight',
  color: 'brand.primary.purple',
  fontSize: '0.85rem',
  px: '0.57rem',
  py: '0.14rem',
  h: 'auto',
  fontWeight: 600,
})

export const Badge = defineStyleConfig({
  baseStyle: {
    h: '1.57rem',
    px: '1.14rem',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '0.42rem',
    textTransform: 'none',
  },
  variants: { success, filter, error, warning },
})
