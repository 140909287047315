import { useMemo } from 'react'
import { exchangeFormat } from 'utils/currencyFormat'

export const useDealExchangeRate = (
  toCurrency: string,
  fromCurrency: string,
  rate: number,
): string => {
  return useMemo(() => {
    if (toCurrency === 'CAD') {
      return `${exchangeFormat(1 / rate)} ${fromCurrency} = 1 ${toCurrency}`
    } else {
      return `1 ${fromCurrency} = ${exchangeFormat(rate)} ${toCurrency}`
    }
  }, [toCurrency, fromCurrency, rate])
}
