import { Box, Flex, Modal, ModalContent, ModalOverlay, Image, Button } from '@chakra-ui/react'
import React, { FC } from 'react'
import check from 'assets/images/checkGreen.png'

interface Props {
  isOpen: boolean
  title: string
  subTitle?: string
  closeButtonText?: string
  onClose: () => void
  onConfirm?: () => void
  hideButton?: boolean
}

export const SuccessModal: FC<Props> = ({
  isOpen,
  onClose,
  title,
  subTitle,
  onConfirm,
  hideButton,
  closeButtonText,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered variant={'auto'}>
      <ModalOverlay />
      <ModalContent>
        <Flex alignItems='center' flexDirection={'column'}>
          <Image h='3.42rem' w='3.42rem' src={check}></Image>
          <Box textStyle='h2' fontWeight='800' mt='1.14rem'>
            {title}
          </Box>
          {subTitle && (
            <Box textStyle='p1' mt='1.14rem'>
              {subTitle}
            </Box>
          )}

          {!hideButton && (
            <Button mt='1.14rem' onClick={() => onConfirm && onConfirm()}>
              {closeButtonText || 'Get started'}
            </Button>
          )}
        </Flex>
      </ModalContent>
    </Modal>
  )
}
