import { Box, Grid, GridItem, Tab, TabList, Tabs, useBreakpointValue } from '@chakra-ui/react'
import { AmplitudeEvents, useTrackAction } from 'pages/MainLayout/AmplitudeProvider'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom'

const links = [
  {
    title: 'myDeals.links.inProgress',
    link: 'in-progress',
  },
  {
    title: 'myDeals.links.completed',
    link: 'completed',
  },
]

export const MyDeals = () => {
  const { t } = useTranslation()
  const track = useTrackAction()
  const size = useBreakpointValue({ base: 'mobile', lg: 'desktop' }, { ssr: false })
  const navigate = useNavigate()
  const params = useParams<{
    dealsStatus: string
  }>()
  const tabIndex = useMemo(() => {
    return links.findIndex((item) => item.link === params.dealsStatus)
  }, [params])

  useEffect(() => {
    track?.(AmplitudeEvents.VIEW_MY_DEALS)
    if (!params.dealsStatus) {
      navigate(links[0].link)
    }
  }, [params])

  return (
    <Box>
      <Box textStyle={'h1'} fontWeight={800} mb={['1.71rem', '2.28rem']}>
        {t('myDeals.title')}
      </Box>
      <Grid
        aria-label='Home header'
        templateAreas={{
          base: `
            "tabs"
          `,
          md: `"tabs"`,
        }}
        templateColumns={{ base: '1fr', md: 'auto 16.42rem' }}
        rowGap={{ base: '1.71rem' }}
        templateRows={'auto'}
      >
        <GridItem area={'tabs'}>
          <Box width={'auto'}>
            <Tabs index={tabIndex} variant='homeScreen' isFitted={size !== 'desktop'}>
              <TabList>
                {links.map((tab, index) => (
                  <Tab key={index}>
                    <Link to={tab.link}>{t(tab.title)}</Link>
                  </Tab>
                ))}
              </TabList>
            </Tabs>
          </Box>
        </GridItem>
      </Grid>
      <Box>
        <Outlet></Outlet>
      </Box>
    </Box>
  )
}
