import { Currency } from './Currency'
import { User } from './User'
import { DealTransaction } from './Wallet'

export enum DealStatus {
  ACTIVE = 'active',
  PENDING = 'pending',
  SUSPENDED = 'suspended',
  FINISHED = 'finished',
}

export enum DealLabel {
  GOOD_DEAL = 'Good deal',
  LOWEST_PRICE = 'Best deal',
}
export type DealCurrency = Pick<Currency, 'id' | 'slug'>

export interface Deal {
  id: string
  user: User['id']
  toCurrency: DealCurrency
  fromCurrency: DealCurrency
  progress: number
  totalSold: number
  rate: number
  commission: number
  discount?: number
  amount: number
  status: DealStatus
  createdAt?: string
  transactions?: DealTransaction[]
  label?: DealLabel
}

export interface MarketplaceDeal {
  amount: number
  commission: number
  createdAt: string
  displayName: string
  fromCurrencySlug: string
  id: string
  progress: string
  rank: number
  rate: number
  status: string
  toCurrencySlug: string
  totalSold: string
  creatorId: string
}

export interface VerificationDeal {
  id: string
  amount: number
  commission: number
  createdAt: string
  fromCurrency: DealCurrency
  toCurrency: DealCurrency
  progress: number
  promoCode: string
  rate: number
  status: string
  totalSold: number
}

export interface BuyDeal {
  dealId: string
  amount: number
  promoCode?: string
}

export interface DealVerificationResult {
  commissionAmount: number
  discount: number
}

export type BuyDealVerificationResult = { deals?: VerificationDeal[] } & DealVerificationResult
