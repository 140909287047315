import { Badge, Box, Button, Flex } from '@chakra-ui/react'
import { useRefetchKYC } from 'hooks'
import { useOpenKycModal } from 'pages/MainLayout/ModalsProvider'
import React, { FC, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { kycStatusSelector } from 'store/selectors/user'

export const KYCStatus: FC = () => {
  const { t } = useTranslation()

  const kycStatus = useSelector(kycStatusSelector)

  const refetchKYC = useRefetchKYC()
  const openKycModal = useOpenKycModal()

  const badgeData = useMemo(() => {
    let text = ''
    let variant = ''
    let verificationNeeded = false

    if (!kycStatus) return null

    if ('success' in kycStatus) {
      text = kycStatus.success ? 'Success' : 'Failed'
      variant = kycStatus.success ? 'success' : 'error'
    }

    if ('code' in kycStatus) {
      verificationNeeded = true
      switch (kycStatus.code) {
        case 'KYC_REQUIRED':
          if (
            (kycStatus.data.status === 'pending' || kycStatus.data.status === 'created') &&
            kycStatus.data.inquiryId
          ) {
            text = 'Not finished'
            variant = 'warning'
          } else {
            text = 'Not verified'
            variant = 'warning'
          }
          break
        case 'KYC_VERIFICATION_FAILED':
          text = 'Failed'
          variant = 'error'
          break
        case 'NEW_INQUIRY':
          text = 'Not finished'
          variant = 'warning'
          break
        default:
          text = 'Not verified'
          variant = 'warning'
          break
      }
    }

    return {
      text,
      variant,
      verificationNeeded,
    }
  }, [kycStatus])

  useEffect(() => {
    refetchKYC?.()
  }, [])
  return (
    <>
      {badgeData && (
        <Flex gap='0.57rem' alignItems='center'>
          <Box fontSize={'1.28rem'} fontWeight={500} color='brand.black.900'>
            {t('settings.personal-info.kyc')}:
          </Box>
          <Badge variant={badgeData.variant}>{badgeData.text}</Badge>
          {badgeData.verificationNeeded && (
            <Button variant={'link'} color='brand.primary.purple' onClick={openKycModal}>
              Verify
            </Button>
          )}
        </Flex>
      )}
    </>
  )
}
