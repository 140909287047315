import { configureStore, combineReducers, AnyAction } from '@reduxjs/toolkit'
import { authApi } from './services/auth.api'
import { currencyApi } from './services/currency.api'
import { dealsFakeApi } from './services/deals.fake.api'
import { rtkQueryAuthErrorHandler } from './services/middlewares'
import { userApi } from './services/user.api'
import { banksApi } from './services/banks.api'
import auth from './slices/auth'
import user from './slices/user'
import faq from './slices/faq'
import currency from './slices/currency'
import deals from './slices/deals'
import wallet from './slices/wallet'
import { emptySplitApi } from './services/emptySplit.api'
import { useDispatch } from 'react-redux'

const rootReducer = combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [currencyApi.reducerPath]: currencyApi.reducer,
  [dealsFakeApi.reducerPath]: dealsFakeApi.reducer,
  [banksApi.reducerPath]: banksApi.reducer,
  auth,
  user,
  faq,
  currency,
  deals,
  wallet,
})

const resettableRootReducer = (
  state: ReturnType<typeof rootReducer> | undefined,
  action: AnyAction,
) => {
  if (action.type === 'store/reset') {
    return rootReducer(undefined, action)
  }
  return rootReducer(state, action)
}

export const store = configureStore({
  reducer: resettableRootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      rtkQueryAuthErrorHandler,
      dealsFakeApi.middleware,
      emptySplitApi.middleware,
    ),
})

export type RootStore = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useTypedDispatch = () => useDispatch<AppDispatch>()
