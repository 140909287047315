import { Box, Divider, Flex, Stack } from '@chakra-ui/react'
import { useDealExchangeRate } from 'hooks'
import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { WalletTransaction } from 'types'
import { currencyFormat } from 'utils/currencyFormat'

const billRenderer = (text: string, value: string) => {
  return (
    <Flex
      position='relative'
      justifyContent={'space-between'}
      color='brand.black.700'
      lineHeight={'120%'}
      width='100%'
    >
      <Box
        position={'absolute'}
        width='100%'
        borderStyle={'dashed'}
        borderWidth='0.07rem'
        opacity={0.5}
        top='50%'
        borderColor={'brand.black.700'}
      ></Box>
      <Box
        fontSize={'1rem'}
        position={'relative'}
        zIndex={3}
        bg='brand.black.100'
        fontWeight={'600'}
        paddingRight={'0.57rem'}
      >
        {text}
      </Box>
      <Box
        fontSize={'1rem'}
        position={'relative'}
        zIndex={3}
        fontWeight='800'
        bg='brand.black.100'
        paddingLeft={'0.57rem'}
        paddingRight='0.14rem'
      >
        {value}
      </Box>
    </Flex>
  )
}

interface Props {
  transactionDetails: WalletTransaction
}

export const TransactionDetails: FC<Props> = ({ transactionDetails }) => {
  const { t } = useTranslation()

  return (
    <Box bg='brand.black.100' borderRadius={'1.14rem'} p={['0.85rem', '1.14rem']}>
      <Box fontWeight={800} fontSize='1.28rem' color={'brand.black.900'} mb='1.14rem'>
        {t('wallet.transactionDetails')}
      </Box>
      <Stack fontSize={'1rem'} spacing='1.14rem'>
        {billRenderer(t('transactions.accountName'), transactionDetails.accountName)}
        {billRenderer(t('transactions.accountNumber'), transactionDetails.accountNumber)}
        {billRenderer(t('transactions.paymentReference'), transactionDetails.paymentReference)}
        {billRenderer(
          t('transactions.fee'),
          `${currencyFormat(transactionDetails.transferFee)} ${
            transactionDetails.wallet.currency.slug
          }`,
        )}
      </Stack>
    </Box>
  )
}
