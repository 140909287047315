import { Box, Image, Stack } from '@chakra-ui/react'
import React, { FC } from 'react'

interface Props {
  image: string
  title: string
  description: string
}

export const Information: FC<Props> = ({ image, title, description }) => {
  return (
    <Stack
      bg='brand.black.100'
      padding={'1.71rem'}
      borderRadius='1.14rem'
      height={'auto'}
      spacing='1.14rem'
    >
      <Image src={image} w='1.71rem' h='1.71rem'></Image>
      <Box fontSize={'2xl'} fontWeight={800}>
        {title}
      </Box>
      <Box fontSize={'lg'} lineHeight='1.71rem'>
        {description}
      </Box>
    </Stack>
  )
}
