import { tableAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  tableAnatomy.keys,
)

const homeScreen = definePartsStyle({
  table: {
    borderCollapse: 'collapse',
    //borderSpacing: '0 16px',
    position: 'relative',
  },
  thead: {
    borderBottom: 'none',
    mb: '16px',
    position: 'sticky',
    top: '0',
    bg: 'rgba(255,255,255,0)',
    transition: 'color 0.2s',
    zIndex: '9',

    _before: {
      content: '""',
      display: 'block',
      position: 'absolute',
      h: 'full',
      width: '3rem',
      left: '-2.5rem',
      top: 0,
      bg: 'rgba(255,255,255,0)',
    },
    _after: {
      content: '""',
      display: 'block',
      position: 'absolute',
      h: 'full',
      width: '3rem',
      right: '-2.5rem',
      bg: 'rgba(255,255,255,0)',
      top: 0,
    },
    // _after: {
    //   content: '""',
    //   display: 'block',
    //   position: 'absolute',
    //   top: '-16px',
    //   height: '16px',
    //   width: '100%',
    //   background: 'white',
    // },

    tr: {
      position: 'relative',
    },
  },
  th: {
    fontSize: '1.14rem',
    fontWeight: 500,
    px: '1.71rem',
    pt: 0,
    textTransform: 'none',
  },
  td: {},
  tbody: {
    tr: {
      '&.noData:not(.spacing)': {
        boxShadow: 'none',
      },
      '&.spacing': {
        td: {
          py: 0,
        },
      },
      '&:not(.spacing), &:not(.inner-content)': {
        // boxShadow: '0px 0px 48px rgba(15, 3, 30, 0.08)',
        position: 'relative',
        td: {
          p: 0,
          fontWeight: 600,
          fontSize: '1.14rem',
          button: {
            zIndex: '1',
          },

          '.content-box': {
            px: '1.71rem',
            py: '2rem',
            h: 'full',
          },

          _first: {
            _before: {
              boxSizing: 'border-box',
              content: "''",
              position: 'absolute',
              /* do not use top and bottom */
              left: '0',
              top: 0,
              right: '0.14rem',

              display: 'block',
              height: '100%',
              boxShadow: '0 0 3.42rem rgba(15, 3, 30, 0.08)',
              borderRadius: '1.14rem',
            },
          },
        },
      },
      //boxShadow: '0px 0px 48px rgba(15, 3, 30, 0.08)',
      '&.inner-content': {
        borderRadius: 0,
        td: {
          _first: {
            _before: {
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              borderTop: '1px solid #F1F0F1',
            },
          },
          _last: {
            _before: {
              borderBottomLeftRadius: '1.14rem',
              borderBottomRightRadius: '1.14rem',
            },
          },
        },
        '&.last': {
          borderBottomLeftRadius: '1.14rem',
          borderBottomRightRadius: '1.14rem',
        },
      },
      '&.expanded': {
        td: {
          _first: {
            _before: {
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            },
          },
        },
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
  },
})

const transactions = definePartsStyle({
  table: {
    position: 'relative',
  },
  thead: {
    position: 'sticky',
    top: 0,
    bg: 'white',
    zIndex: '9',
    _after: {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '-1.14rem',
      height: '1.14rem',
      width: '100%',
      background: 'white',
    },

    tr: {
      position: 'relative',
      th: {
        paddingTop: '2.28rem',
        paddingBottom: '1.71rem',
        fontSize: '1.14rem',
        fontWeight: 800,
        color: 'brand.black.900',
      },
    },
  },
  th: {
    fontSize: '1.14rem',
    fontWeight: 500,
    px: '1.71rem',
    pt: 0,
    textTransform: 'none',
  },
  td: {},
  tbody: {
    tr: {
      ':nth-of-type(2n + 1)': {
        bg: '#F0F0F1',
        '&.noData': {
          boxShadow: 'none',
          bg: 'white',
        },
      },
      td: {
        fontSize: '1.14rem',
        height: '4rem',
        color: 'brand.black.900',
      },
    },
  },
})

export const Table = defineMultiStyleConfig({
  variants: {
    homeScreen,
    transactions,
  },
})
