import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Deal, PaginationResponse, MarketplaceDeal } from 'types'

interface DealsState {
  deals: Array<Deal | any>
  dealsPerPage: Record<string, MarketplaceDeal[]>
  totalItems: number
}

const initialState = {
  deals: [],
  dealsPerPage: {},
  totalItems: 0,
} as DealsState

const dealsSlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    setDealsPerPage: (
      state: DealsState,
      action: PayloadAction<PaginationResponse<{ deals: MarketplaceDeal[] }>>,
    ) => {
      const { deals, meta } = action.payload
      if (deals.length) {
        return {
          ...state,
          dealsPerPage: {
            ...state.dealsPerPage,
            [meta.currentPage]: deals,
          },
        }
      }
    },
    resetDealsState: (state: DealsState) => {
      return initialState
    },
    setDeals: (state: DealsState, action: PayloadAction<PaginationResponse<{ data: Deal[] }>>) => {
      const { perPage, currentPage } = action.payload.meta
      const { data } = action.payload
      const duplicate = [...data]

      const start = perPage * (currentPage - 1) > 0 ? perPage * (currentPage - 1) - 1 : 0
      const end = start + perPage
      if (currentPage === 1) {
        return {
          ...state,
          deals: [...Array(action.payload.meta.total)]
            .map((item) => null)
            .map((_, index) => {
              if (index >= start && index < end) {
                //const shifted = action.payload.data.shift()

                return duplicate.shift()
              }
              return _
            }),
        }
      }
      return {
        ...state,
        deals: [...state.deals].map((_, index) => {
          if (index >= start && index < end) {
            //const shifted = action.payload.data.shift()

            return duplicate.shift()
          }
          return _
        }),
      }
      // return {
      //   ...state,
      //   deals: [...state.deals, ...action.payload.data],
      // }
    },
    setTotal: (state: DealsState, action: PayloadAction<number>) => {
      return {
        ...state,
        totalItems: action.payload,
      }
    },
  },
})

export const { setDeals, setTotal, setDealsPerPage, resetDealsState } = dealsSlice.actions
export default dealsSlice.reducer
