import { AmplitudeEvents, useTrackAction } from 'pages/MainLayout/AmplitudeProvider'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useLogoutMutation } from 'store/services/auth.api'
import { useCloseChat } from 'pages/MainLayout/ChatProvider'

export const useLogout = () => {
  const [logoutAction] = useLogoutMutation()
  const closeChat = useCloseChat()
  const track = useTrackAction()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  return async () => {
    await logoutAction()
    track?.(AmplitudeEvents.LOGOUT)
    closeChat()
    dispatch({ type: 'store/reset' })
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
    navigate('/sign-in')
  }
}
