import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { stat } from 'fs'
import {
  Account,
  Deal,
  DealTransaction,
  NotificationSettings,
  PaginationResponse,
  PayinAccount,
  User,
  Wallet,
} from 'types'
import { KYCStatus } from 'types/KYCStatus'

interface UserState {
  user?: User
  notificationSettings: NotificationSettings
  accounts: Account[]
  payinAccounts: PayinAccount[]
  wallets: Wallet[]
  deals: Record<number, Deal[]>
  kycStatus?: KYCStatus
}

const initialState = {
  user: undefined,
  notificationSettings: { emailEnabled: false, smsEnabled: false },
  accounts: [],
  payinAccounts: [],
  wallets: [],
  deals: {},
} as UserState

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state: UserState, action: PayloadAction<User | undefined>) => ({
      ...state,
      user: action.payload,
    }),
    setNotificationSettings: (state: UserState, action: PayloadAction<NotificationSettings>) => ({
      ...state,
      notificationSettings: action.payload || state.notificationSettings,
    }),
    setAccounts: (state: UserState, action: PayloadAction<{ accounts: Account[] }>) => ({
      ...state,
      accounts: action.payload.accounts,
    }),
    addAccount: (state: UserState, action: PayloadAction<Account>) => ({
      ...state,
      //accounts: [...state.accounts, action.payload],
    }),
    setWallets: (state: UserState, action: PayloadAction<Wallet[]>) => ({
      ...state,
      wallets: action.payload,
    }),
    setPayinAccounts: (state: UserState, action: PayloadAction<{ accounts: PayinAccount[] }>) => ({
      ...state,
      payinAccounts: action.payload.accounts,
    }),
    setMyDealsPerPage: (
      state: UserState,
      action: PayloadAction<PaginationResponse<{ deals: Deal[] }>>,
    ) => {
      return {
        ...state,
        deals: {
          ...state.deals,
          [action.payload.meta.currentPage]: action.payload.deals,
        },
      }
    },
    resetMyDeals: (state: UserState, action: PayloadAction<void>) => {
      return { ...state, deals: {} }
    },
    setDealTransactions: (
      state: UserState,
      action: PayloadAction<{ data: DealTransaction[]; dealId: string }>,
    ) => {
      return {
        ...state,
        deals: Object.fromEntries(
          Object.entries(state.deals).map(([page, deals]) => [
            page,
            deals.map((deal) => {
              if ((deal as Deal)?.id === action.payload.dealId) {
                return {
                  ...deal,
                  transactions: action.payload.data,
                }
              }
              return deal
            }),
          ]),
        ),
      }
    },
    removeDealFromList: (state: UserState, action: PayloadAction<{ dealId: string }>) => {
      return {
        ...state,
        deals: Object.fromEntries(
          Object.entries(state.deals).map(([page, deals]) => [
            page,
            deals.filter((deal) => deal?.id !== action.payload.dealId),
          ]),
        ),
      }
    },
    updateDeal: (state: UserState, action: PayloadAction<Deal>) => {
      return {
        ...state,
        deals: Object.fromEntries(
          Object.entries(state.deals).map(([page, deals]) => [
            page,
            deals.map((deal) => {
              if (deal?.id !== action.payload.id) return deal
              return action.payload
            }),
          ]),
        ),
      }
    },
    setKYCStatus: (state: UserState, action: PayloadAction<KYCStatus>) => ({
      ...state,
      kycStatus: action.payload,
    }),
  },
})

export const {
  setUser,
  setPayinAccounts,
  setMyDealsPerPage,
  setNotificationSettings,
  setAccounts,
  addAccount,
  setWallets,
  setDealTransactions,
  removeDealFromList,
  updateDeal,
  resetMyDeals,
  setKYCStatus,
} = userSlice.actions
export default userSlice.reducer
