import React, { FC } from 'react'
import { Grid, Box, Container, useBreakpointValue } from '@chakra-ui/react'
import { Outlet } from 'react-router-dom'
import { Drawer } from './Drawer'
import { Header } from './Header'
import { BottomNavigation } from './BottomNavigation'
import { useSelector } from 'react-redux'
import { userAccountsSelector, userInfoSelector } from 'store/selectors/user'
import { useIsInitialDataLoaded } from 'hooks'
import { ModalsProvider } from './ModalsProvider'
import { JoyrideWrapper } from './common/JoyrideWrapper'
import { RootStore } from 'store'
import { UncompletedAccountWarning } from './UncompletedAccountWarning'
import { LoaderScreen } from './LoaderScreen'
import { useSetUser } from './ChatProvider'

const MainLayout: FC = () => {
  const containerRef = React.useRef(null)
  const user = useSelector(userInfoSelector)
  const payoutAccounts = useSelector(userAccountsSelector)
  const hasCompletedKyc = useSelector((state: RootStore) => state.user.user?.hasCompletedKyc)
  const emailVerified = useSelector(userInfoSelector)?.emailVerified
  const isDataLoaded = useIsInitialDataLoaded()
  const setUser = useSetUser()

  React.useEffect(() => {
    if (user) {
      setUser(user)
    }
  }, [user])

  const hasFinishedRegistration = payoutAccounts.length > 0 && hasCompletedKyc && emailVerified

  const size = useBreakpointValue({ base: 'mobile', lg: 'desktop' }, { ssr: false })
  if (!isDataLoaded) {
    return <LoaderScreen />
  }
  return (
    <JoyrideWrapper>
      <ModalsProvider>
        <Box>
          {size === 'mobile' && (
            <Box
              position={'fixed'}
              transition={'top 0.5s ease'}
              left='0'
              height={'70px'}
              w='full'
              zIndex={10}
              boxShadow='rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset'
            >
              <Header></Header>
            </Box>
          )}
          <Grid
            gridTemplateColumns={{ base: '1fr', lg: 'auto 1fr' }}
            gridTemplateRows={{ base: '100% auto' }}
            minH={{ base: 'none', md: '100vh' }}
            maxH={{ base: 'none', md: '100vh' }}
            p={{ base: '70px 0', lg: '8px' }}
            pr={{ base: 0, lg: 0 }}
          >
            {size === 'desktop' && <Drawer></Drawer>}
            <Box w={'full'} pt={{ base: `${'0'}`, lg: '0' }} transition='padding-top 0.5s ease'>
              <Container
                h='full'
                maxW={'1182px'}
                px={{ base: '20px', xl: '80px' }}
                pt={{ base: '32px', lg: '48px' }}
                pb={'20px'}
                overflow='auto'
                ref={containerRef}
              >
                {!hasFinishedRegistration && <UncompletedAccountWarning />}
                <Outlet></Outlet>
              </Container>
            </Box>
          </Grid>
          {size === 'mobile' && (
            <Box
              position={'fixed'}
              w='full'
              bottom='0'
              background={'brand.secondary.purpleDark'}
              h='70px'
              zIndex={10}
            >
              <BottomNavigation></BottomNavigation>
            </Box>
          )}
        </Box>
      </ModalsProvider>
    </JoyrideWrapper>
  )
}

export default MainLayout
