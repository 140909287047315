import { ComponentStyleConfig } from '@chakra-ui/react'

export const Modal: ComponentStyleConfig = {
  baseStyle: {
    overlay: {
      bg: 'brand.secondary.darkPurpleAlpha80',
    },
    dialog: {
      bg: 'white',
      boxShadow: '0 0 4.85rem rgba(3, 7, 30, 0.08)',
      borderRadius: '2.28rem',
      padding: '2.28rem',
    },
  },
  variants: {
    auto: {
      dialog: {
        maxWidth: '95%',
        minWidth: { base: '95%', md: '38.21rem' },
        width: 'auto',
      },
    },
  },
}
