import { Box, Divider, Flex, Stack } from '@chakra-ui/react'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Currency, DealTransaction, PayoutVerifyResponse, Wallet } from 'types'
import { currencyFormat } from 'utils/currencyFormat'

const billRenderer = (text: string, value: string) => {
  return (
    <Flex
      position='relative'
      justifyContent={'space-between'}
      color='brand.black.700'
      lineHeight={'120%'}
      width='100%'
    >
      <Box
        position={'absolute'}
        width='100%'
        borderStyle={'dashed'}
        borderWidth='0.07rem'
        opacity={0.5}
        top='50%'
        borderColor={'brand.black.700'}
      ></Box>
      <Box
        fontSize={'1rem'}
        position={'relative'}
        zIndex={3}
        bg='brand.black.100'
        fontWeight={'600'}
        paddingRight={'0.57rem'}
      >
        {text}
      </Box>
      <Box
        fontSize={'1rem'}
        position={'relative'}
        zIndex={3}
        fontWeight='800'
        bg='brand.black.100'
        paddingLeft={'0.57rem'}
        paddingRight='0.14rem'
      >
        {value}
      </Box>
    </Flex>
  )
}

interface Props {
  wallet: Wallet
  amount: number
  verificationResult?: PayoutVerifyResponse
}

export const WithdrawTransactionDetails: FC<Props> = ({ wallet, amount, verificationResult }) => {
  const { t } = useTranslation()

  return (
    <Box bg='brand.black.100' borderRadius={'1.14rem'} p='1.14rem'>
      <Box fontWeight={800} fontSize='1.28rem' color={'brand.black.900'} mb='1.14rem'>
        {t('wallet.transactionDetails')}
      </Box>
      <Stack fontSize={'1rem'} spacing='1.14rem'>
        {billRenderer(
          t('wallet.totalAmount'),
          verificationResult
            ? `${currencyFormat(verificationResult.totalAmount)} ${wallet.currency.slug}`
            : 'N/A',
        )}
        {/* {billRenderer(
          t('wallet.commissionFee'),
          verificationResult
            ? `${currencyFormat(verificationResult?.commissionFee)} ${wallet.currency.slug}`
            : 'N/A',
        )} */}
        {/* {billRenderer(
          t('wallet.transferFee'),
          verificationResult
            ? `${currencyFormat(verificationResult?.transferFee)} ${wallet.currency.slug}`
            : 'N/A',
        )} */}
        {billRenderer(
          t('wallet.willRemain'),
          verificationResult
            ? `${currencyFormat(wallet.availableBalance - verificationResult?.totalAmount)} ${
                wallet.currency.slug
              }`
            : 'N/A',
        )}
      </Stack>
      <Divider my='1.14rem' borderColor={'brand.black.200'} opacity='1'></Divider>
      <Flex
        justifyContent={'space-between'}
        fontWeight={800}
        fontSize='1.28rem'
        color={'brand.black.900'}
      >
        <Box>{t('createDeal.receiveAmount')}</Box>
        <Box>
          {currencyFormat(amount)} {wallet.currency.slug}
        </Box>
      </Flex>
    </Box>
  )
}
