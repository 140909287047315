import {
  Button,
  HStack,
  chakra,
  Badge,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
} from '@chakra-ui/react'
import { FilterIcon } from 'assets/icons'
import React, { FC } from 'react'

type FilterContextModel = {
  closePopover?: () => void
}

export const FilterContext = React.createContext<FilterContextModel>({})

type Props = { children: React.ReactElement; appliedFiltersCount?: number }

export const FilterButton: FC<Props> = ({ children, appliedFiltersCount = 0 }) => {
  const [isOpen, setOpen] = React.useState<boolean>(false)

  return (
    <FilterContext.Provider
      value={{
        closePopover: () => setOpen(false),
      }}
    >
      <Popover
        closeOnBlur={true}
        placement='bottom-start'
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        isOpen={isOpen}
      >
        <PopoverTrigger>
          <Button variant={'outline'} w='auto'>
            <HStack alignItems={'center'}>
              <FilterIcon fill='#8C00E2'></FilterIcon>
              <chakra.p display={{ base: 'none', md: 'block' }}>Filters</chakra.p>
              <Badge variant={'filter'}>{appliedFiltersCount}</Badge>
            </HStack>
          </Button>
        </PopoverTrigger>
        <PopoverContent w='23.36rem' border={'none'} position={'relative'} zIndex='10000'>
          <PopoverBody p={0}>{children}</PopoverBody>
        </PopoverContent>
      </Popover>
    </FilterContext.Provider>
  )
}
