import { ChevronDownIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons'
import { Button, Flex, Grid, GridItem, IconButton, Stack, chakra, Box } from '@chakra-ui/react'
import moment from 'moment'
import { ExchangeRate } from 'pages/HomePage/DealsTable/ExchangeRate'
import React, { useState } from 'react'
import { Deal, DealStatus, DealTransaction } from 'types'
import { currencyFormat } from 'utils/currencyFormat'
import { InfoPopover } from './InfoPopover'
import { ProgressRenderer } from './ProgressRenderer'

interface Props {
  data: Deal
  onDelete: () => void
  onEdit: () => void
  onExpand: (dealId: string) => void
  onDetailsClick: (transactionId: DealTransaction) => void
}

export const MobileRenderer: React.FC<Props> = ({
  data,
  onDelete,
  onEdit,
  onExpand,
  onDetailsClick,
}) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const isCompleted = data.status === DealStatus.FINISHED || data.status === DealStatus.SUSPENDED

  return (
    <Box pb='0.85rem' w='full'>
      <Box
        w='full'
        borderRadius={'1.14rem'}
        boxShadow='0 0 3.42rem rgba(15, 3, 30, 0.08)'
        bg='white'
        position={'relative'}
        p={'1.14rem'}
      >
        <Button
          variant={'link'}
          onClick={async () => {
            await onExpand(data.id)
            setExpanded(!expanded)
          }}
          position='absolute'
          right={0}
          color='brand.primary.purple'
          fontSize={'1.42rem'}
          width='1.42rem'
          h={'1.42rem'}
          zIndex={3}
        >
          <ChevronDownIcon transform={`rotateX(${expanded ? '180deg' : '0'})`}></ChevronDownIcon>
        </Button>
        <Box mb='1.14rem'>
          <Box fontSize={'1.14rem'} color='brand.black.900' opacity={0.4} mb={'0.28rem'}>
            Amount
          </Box>
          <Box fontSize={'1.14rem'} color='brand.black.900'>
            {currencyFormat(data.amount)} {data.fromCurrency.slug}
          </Box>
        </Box>
        <Grid templateColumns={`1fr 1fr`}>
          <GridItem>
            <Stack spacing={'1.14rem'}>
              <Stack spacing={'0.28rem'}>
                <Box fontSize={'1.14rem'} color='brand.black.900' opacity={0.4}>
                  Sold
                </Box>
                <ProgressRenderer deal={data}></ProgressRenderer>
              </Stack>
              {expanded && (
                <>
                  <Stack spacing={'0.28rem'}>
                    <Box fontSize={'1.14rem'} color='brand.black.900' opacity={0.4}>
                      Exchange rate
                    </Box>
                    <ExchangeRate
                      fromCurrency={data.fromCurrency}
                      toCurrency={data.toCurrency}
                      rate={data.rate}
                    ></ExchangeRate>
                  </Stack>
                  <Stack spacing={'0.28rem'}>
                    <Box fontSize={'1.14rem'} color='brand.black.900' opacity={0.4}>
                      Date
                    </Box>
                    <Box>{moment(data.createdAt).format('MMM DD, YYYY')}</Box>
                  </Stack>
                </>
              )}
            </Stack>
          </GridItem>
          <GridItem justifySelf={'end'} alignSelf='end'>
            <Flex gap='1.14rem'>
              <IconButton
                aria-label='Edit'
                variant={'link'}
                border='0.07rem solid #D9D7DB'
                color={'brand.black.900'}
                maxW='2.28rem'
                minW='2.28rem'
                minH='2.28rem'
                icon={<EditIcon></EditIcon>}
                onClick={() => onEdit()}
                isDisabled={isCompleted}
              ></IconButton>
              <IconButton
                aria-label='Delete'
                variant={'link'}
                border='0.07rem solid #D9D7DB'
                color={'brand.black.900'}
                maxW='2.28rem'
                minW='2.28rem'
                minH='2.28rem'
                isDisabled={isCompleted}
                icon={<DeleteIcon></DeleteIcon>}
                onClick={() => onDelete()}
              ></IconButton>
            </Flex>
          </GridItem>
        </Grid>
        {expanded && (
          <Box mt='1.14rem'>
            {data.transactions?.map((transaction) => {
              return (
                <Flex
                  key={transaction.id}
                  p='1.14rem'
                  borderTop={'solid 0.07rem #F1F0F1'}
                  justifyContent='space-between'
                >
                  {transaction.reference.includes('SOL') ? (
                    <Flex gap={'0.57rem'}>
                      <chakra.p color={'brand.secondary.blue'} fontSize='1.14rem' fontWeight={500}>
                        +{currencyFormat(transaction.amount)} {transaction.deal.toCurrency.slug}
                      </chakra.p>
                      <InfoPopover deal={data}></InfoPopover>
                    </Flex>
                  ) : (
                    <Flex gap={'0.57rem'}>
                      <chakra.p color={'brand.secondary.blue'} fontSize='1.14rem' fontWeight={500}>
                        {currencyFormat(transaction.amount)} {transaction.deal.fromCurrency.slug}
                      </chakra.p>
                      <InfoPopover deal={data}></InfoPopover>
                    </Flex>
                  )}
                  <Button
                    variant={'link'}
                    color='brand.primary.purple'
                    onClick={() => onDetailsClick(transaction)}
                  >
                    Details
                  </Button>
                </Flex>
              )
            })}
          </Box>
        )}
      </Box>
    </Box>
  )
}
