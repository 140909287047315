import { ComponentStyleConfig } from '@chakra-ui/react'

export const Input: ComponentStyleConfig = {
  baseStyle: {
    field: {
      border: '0.07rem solid',
      borderRadius: '0.6rem',
      borderColor: 'brand.black.200',
      transitionProperty: 'common',
      transitionDuration: 'normal',
      _hover: {
        borderColor: 'brand.black.900',
      },
      _focus: {
        borderColor: 'brand.black.900',
      },
      _disabled: {
        bg: 'brand.black.200',
      },
      _invalid: {
        borderColor: 'brand.secondary.red',
      },
      _placeholder: {
        color: 'brand.black.500',
      },
    },
  },
  sizes: {
    md: {
      field: {
        h: '2.85rem',
        borderRadius: '0.6rem',
        fontSize: 'lg',
      },
    },
  },
  defaultProps: {
    field: {
      border: '0.07rem solid',
      borderRadius: '0.6rem',
      borderColor: 'brand.black.200',
      _hover: {
        borderColor: 'brand.black.900',
      },
      _disabled: {
        bg: 'brand.black.200',
      },
      _invalid: {
        field: {
          borderColor: 'brand.secondary.red',
        },
      },
    },
  },
}
