export const textStyles = {
  h1: {
    // you can also use responsive styles
    fontSize: ['1.42rem', '2.28rem'],
    lineHeight: '120%',
  },
  h2: {
    fontSize: ['1.42rem', '1.7rem'],
    lineHeight: '120%',
  },
  h3: {
    fontSize: '1.42rem',
    lineHeight: '120%',
  },
  h4: {
    fontSize: ['1.14rem', '1.28rem'],
    lineHeight: '120%',
  },
  p1: {
    fontSize: '1.14rem',
  },
  logoText: {
    fontSize: ['2rem', '3.42rem'],
    fontWeight: 800,
  },
  headerLogoText: {
    fontSize: '1.42rem',
    fontWeight: 800,
  },
  logoTextNavigation: {
    fontSize: '1.71rem',
    fontWeight: 800,
  },
  pageTitle: {
    fontSize: ['1.42rem', '2.28rem'],
    fontWeight: 800,
  },
  cardTitle: {
    fontSize: ['1.28rem', '1.42rem'],
    fontWeight: 800,
    color: 'brand.black.900',
  },
  cardSubtitle: {
    fontSize: ['1rem', '1.14rem'],
    fontWeight: 400,
    color: 'brand.black.700',
  },
  link: {
    color: 'brand.primary.purple',
    fontWeight: 800,
    fontSize: '1.14rem',
  },
  modalTitle: {
    color: 'brand.black.900',
    fontSize: ['1.42rem', '1.71rem'],
    fontWeight: 800,
  },
  modalSubtitle: {
    color: 'brand.black.700',
    fontSize: ['1rem', '1.14rem'],
  },
}
