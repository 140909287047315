import { Box, FormControl, FormLabel, Input, InputGroup, InputProps } from '@chakra-ui/react'
import React, { FC } from 'react'
import MaskedInput from 'react-text-mask'
import { createNumberMask } from 'text-mask-addons'

const defaultMaskOptions = {
  prefix: '₦',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  allowNegative: false,
  allowLeadingZeroes: false,
  guide: true,
}

interface Props extends InputProps {
  currencySlug: string
  label?: string
  error?: string
}

export const FSGMaskedInput: FC<Props> = ({ label, currencySlug, error, ...props }) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    prefix: currencySlug === 'CAD' ? '$' : '₦',
  })

  return (
    <FormControl>
      <FormLabel
        as='label'
        display='block'
        fontSize='md'
        fontWeight={800}
        htmlFor={props.id}
        marginBottom='0.57rem'
      >
        {label}{' '}
        {props.isRequired && (
          <Box as='span' color='brand.secondary.red'>
            *
          </Box>
        )}
      </FormLabel>
      <InputGroup
        border='solid 1px'
        borderRadius='0.57rem'
        borderColor='brand.black.200'
        overflow={'hidden'}
        _hover={{
          borderColor: 'brand.black.900',
        }}
        _active={{
          borderColor: 'brand.black.900',
        }}
      >
        <Input
          as={MaskedInput}
          mask={currencyMask}
          border='none'
          background={'white'}
          //type={'numeric'}
          {...props}
          value={props.value}
          onChange={(e) => {
            props.onChange?.(e)
          }}
        />
      </InputGroup>
      <Box color='brand.secondary.red'>{error}</Box>
    </FormControl>
  )
}
