import { defineStyleConfig } from '@chakra-ui/react'

export const Accordion = defineStyleConfig({
  baseStyle: {
    container: {
      _first: {
        borderTop: 'none',
      },
      _last: {
        borderBottom: 'none',
      },
    },
    button: {
      width: 'auto',
      _hover: {
        background: 'none',
      },
    },
    panel: {
      padding: '0 1.71rem 1.71rem',
    },
  },
})
