import { Box, HStack, Stack } from '@chakra-ui/react'
import { CADIcon, NGNIcon } from 'assets/icons'
import React from 'react'
import { useSelector } from 'react-redux'
import { getWalletsDictsSelector } from 'store/selectors/user'
import { Currencies } from 'types'
import { currencyFormat } from 'utils/currencyFormat'

export const WalletInfo: React.FC = () => {
  const wallets = useSelector(getWalletsDictsSelector)

  return (
    <Stack py='0.85rem'>
      <Box color={'brand.black.900'} textStyle={'p1'} fontWeight={800}>
        Available balance
      </Box>
      <Stack spacing={'1.42rem'} fontSize={'1rem'}>
        <HStack spacing={'0.57rem'}>
          <CADIcon></CADIcon>
          <HStack color={'brand.black.900'}>
            <Box>{currencyFormat(+wallets.CAD?.[0].availableBalance || 0)}</Box>
            <Box fontWeight={800}>{Currencies.CAD}</Box>
          </HStack>
        </HStack>
        <HStack spacing={'0.57rem'}>
          <NGNIcon></NGNIcon>
          <HStack color={'brand.black.900'}>
            <Box>{currencyFormat(+wallets.NGN?.[0].availableBalance || 0)}</Box>
            <Box fontWeight={800}>{Currencies.NGN}</Box>
          </HStack>
        </HStack>
      </Stack>
    </Stack>
  )
}
