import React, { useCallback, useRef } from 'react'
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  BoxProps,
  useBreakpointValue,
  ChakraProps,
  Flex,
  Stack,
  Spinner,
} from '@chakra-ui/react'
import { TriangleDownIcon } from '@chakra-ui/icons'
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  ColumnDef,
  SortingState,
  getSortedRowModel,
  Row,
  Cell,
  ColumnMeta,
} from '@tanstack/react-table'
import { useVirtualizer, Virtualizer, useWindowVirtualizer } from '@tanstack/react-virtual'
import { PaginationMeta } from 'types'
import { PaginationPages } from './PaginationPages'
import { NoData } from './NoData'
import { LoadingData } from './LoadingData'
import { useDprWithOs } from 'hooks/useDprWithOs'

export type DataTableProps<Data extends object> = {
  data: Data[]
  columns: ColumnDef<Data, any>[]
  variant?: string
  height?: BoxProps['height']
  totalItemsCount?: number
  hasPagination?: boolean
  isFetching?: boolean
  meta?: PaginationMeta
  onScroll?: (e: HTMLDivElement) => void
  mobileRenderer: (data: Data) => JSX.Element
  onPaginationChange?: (page: number) => void
  innerRowRenderer?: (data: Data, cells: Cell<Data, unknown>[]) => JSX.Element
  onSorting?: (sorting: SortingState) => void
  boxSx?: ChakraProps['sx']
  noDataComponent?: JSX.Element
  children?: JSX.Element
  firstDealClass?: string
}

export function DataTable<Data extends object>({
  data,
  columns,
  variant = 'homeScreen',
  height,
  totalItemsCount,
  meta,
  boxSx,
  isFetching = false,
  noDataComponent,
  onScroll,
  mobileRenderer,
  onPaginationChange,
  innerRowRenderer,
  onSorting,
  children,
  firstDealClass,
}: DataTableProps<Data>) {
  const scrollContainer = useRef<HTMLDivElement>(null)
  const windowRef = useRef(window)
  const [sorting, setSorting] = React.useState<SortingState>([])
  const size = useBreakpointValue({ base: 'mobile', lg: 'desktop' }, { ssr: false })
  const dpr = useDprWithOs()
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: (updater) => {
      if ('bind' in updater) {
        const sortValues = updater(sorting)
        onSorting?.(sortValues)
      }
      setSorting(updater)
    },
    getSortedRowModel: getSortedRowModel(),
    getRowCanExpand: () => true,
    state: {
      sorting,
    },
    manualSorting: true,
    enableMultiSort: true,
    maxMultiSortColCount: 10,
    debugTable: true,
    enableSortingRemoval: true,
    enableSorting: true,
  })
  const { rows } = table.getRowModel()
  const rowVirtualizer = useVirtualizer({
    getScrollElement: () => scrollContainer.current,
    count: totalItemsCount || rows.length,
    estimateSize: (index) => (size === 'desktop' ? 105 : 1),
    enableSmoothScroll: true,
    overscan: 10,
  })
  const { getVirtualItems: virtualRows, getTotalSize } = rowVirtualizer

  const paddingTop = virtualRows().length > 0 ? virtualRows?.()?.[0]?.start || 0 : 0
  const paddingBottom =
    virtualRows().length > 0
      ? getTotalSize() - (virtualRows?.()?.[virtualRows?.()?.length - 1]?.end || 0)
      : 0
  const handleScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
      if (scrollContainer.current) {
        scrollContainer.current.dataset.scroll = `${(e.target as HTMLDivElement).scrollTop}`
      }
      onScroll?.(e.target as HTMLDivElement)
    },
    [onScroll, scrollContainer.current],
  )

  return (
    <Box
      maxHeight={height}
      ref={scrollContainer}
      onScroll={handleScroll}
      overflow='auto'
      overflowX={'hidden'}
      my='0'
      mx={{
        base: '-1.42rem',
        md: '-2.28rem',
      }}
      px={{
        base: '1.42rem',
        md: '2.28rem',
      }}
      //width={['none', 'full']}
      data-scroll='0'
      sx={{
        ...boxSx,
        '&:not([data-scroll="0"])': {
          'table thead': {
            _after: {
              bg: 'rgba(255,255,255,1)',
            },
            _before: {
              bg: 'rgba(255,255,255,1)',
            },
            bg: 'rgba(255,255,255,1)',
          },
        },
      }}
    >
      {children}
      {size === 'desktop' ? (
        <>
          <Table variant={variant}>
            <Thead>
              {table.getHeaderGroups().map((headerGroup, index) => (
                <React.Fragment key={index}>
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                      const meta: any = header.column.columnDef.meta
                      return (
                        <Th
                          key={header.id}
                          onClick={() => {
                            if (header.column.getCanSort()) {
                              const desc = header.column.getNextSortingOrder()
                              if (!desc) {
                                header.column.clearSorting()
                              } else {
                                header.column.toggleSorting(
                                  desc === 'desc',
                                  header.column.getCanMultiSort(),
                                )
                              }
                            }
                          }}
                          isNumeric={meta?.isNumeric}
                          minW={dpr <= 1 ? header.getSize() : 'none'}
                        >
                          <Flex
                            alignItems={'center'}
                            gap='0.57rem'
                            cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {header.column.getCanSort() && (
                              <Stack spacing={0}>
                                <TriangleDownIcon
                                  aria-label='sorted descending'
                                  transform={'rotate(180deg)'}
                                  boxSize={'0.71rem'}
                                  color={
                                    header.column.getIsSorted() === 'asc'
                                      ? 'black'
                                      : 'blackAlpha.600'
                                  }
                                />
                                <TriangleDownIcon
                                  aria-label='sorted ascending'
                                  boxSize={'0.71rem'}
                                  color={
                                    header.column.getIsSorted() === 'desc'
                                      ? 'black'
                                      : 'blackAlpha.600'
                                  }
                                />
                              </Stack>
                            )}
                          </Flex>
                        </Th>
                      )
                    })}
                  </Tr>
                  {variant === 'homeScreen' && (
                    <Tr className='spacing' height={'1.14rem'} key='spacer'>
                      <Td colSpan={table.getFlatHeaders().length}></Td>
                    </Tr>
                  )}
                </React.Fragment>
              ))}
            </Thead>
            <>
              {virtualRows().length === 0 && isFetching && (
                <Tbody>
                  <Tr className='noData'>
                    <Td colSpan={table.getVisibleFlatColumns().length}>
                      <Flex
                        justifyContent={'center'}
                        alignItems='center'
                        gap='1.42rem'
                        py='2.85rem'
                      >
                        <Spinner
                          thickness='0.28rem'
                          speed='0.65s'
                          emptyColor='gray.200'
                          color='brand.primary.purple'
                          size='xl'
                        />{' '}
                        <Box>Loading...</Box>
                      </Flex>
                    </Td>
                  </Tr>
                </Tbody>
              )}
              {virtualRows().length > 0 ? (
                <Tbody>
                  {paddingTop > 0 && (
                    <Tr>
                      <Td style={{ height: `${paddingTop}px` }} />
                    </Tr>
                  )}
                  {virtualRows().map((virtualRow) => {
                    const row = rows[virtualRow.index] as Row<Data>
                    return (
                      <React.Fragment key={row.id}>
                        {variant === 'homeScreen' && (
                          <Tr className='spacing' height={'0.57rem'}>
                            <Td colSpan={row.getVisibleCells().length}></Td>
                          </Tr>
                        )}
                        <Tr
                          className={`${row.getIsExpanded() ? 'expanded' : ''} ${
                            virtualRow.index === 0 ? firstDealClass : ''
                          }`}
                        >
                          {row.getVisibleCells().map((cell) => {
                            // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                            const meta: any = cell.column.columnDef.meta

                            return (
                              <Td
                                key={cell.id}
                                isNumeric={meta?.isNumeric}
                                maxW={cell.column.columnDef.maxSize}
                              >
                                <Box className='content-box'>
                                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </Box>
                              </Td>
                            )
                          })}
                        </Tr>
                        {row.getIsExpanded() && innerRowRenderer && (
                          <>{innerRowRenderer(row.original, row.getVisibleCells())}</>
                        )}
                        {variant === 'homeScreen' && (
                          <Tr className='spacing' height={'0.57rem'}>
                            <Td colSpan={row.getVisibleCells().length}></Td>
                          </Tr>
                        )}
                      </React.Fragment>
                    )
                  })}
                  {isFetching && (
                    <Tr className='noData'>
                      <Td colSpan={table.getVisibleFlatColumns().length}>
                        <Flex
                          justifyContent={'center'}
                          alignItems='center'
                          gap='1.42rem'
                          py='2.84rem'
                        >
                          <Spinner
                            thickness='0.28rem'
                            speed='0.65s'
                            emptyColor='gray.200'
                            color='brand.primary.purple'
                            size='xl'
                          />{' '}
                          <Box>Loading...</Box>
                        </Flex>
                      </Td>
                    </Tr>
                  )}
                  {paddingBottom > 0 && (
                    <Tr>
                      <Td sx={{ height: `${paddingBottom}px` }} />
                    </Tr>
                  )}
                </Tbody>
              ) : (
                <>
                  {!isFetching && (
                    <Tbody>
                      <Tr className='noData'>
                        <Td colSpan={table.getFlatHeaders().length}>
                          <Flex
                            justifyContent={'center'}
                            alignItems='center'
                            gap='1.42rem'
                            py='2.84rem'
                            w='full'
                          >
                            {noDataComponent || <Box>No data available</Box>}
                          </Flex>
                        </Td>
                      </Tr>
                    </Tbody>
                  )}
                </>
              )}
            </>
          </Table>
          {meta && onPaginationChange && !isFetching && !!virtualRows().length && (
            <Box
              aria-label='pagination'
              height={'6.42rem'}
              position='sticky'
              bottom={0}
              left={0}
              bg='white'
              zIndex={2}
              borderTopStyle='solid'
              borderColor={'brand.black.100'}
              borderTopWidth='0.07rem'
            >
              <Flex w='full' h='full' px='2.28rem' alignItems={'center'} justifyContent='flex-end'>
                {/* <Box fontSize='1rem' fontWeight={400} color='brand.black.900'>
                  Displaying {meta.from} of {meta.total}
                </Box> */}
                <PaginationPages
                  meta={meta}
                  onPaginationChange={onPaginationChange}
                  currentPage={meta.currentPage}
                ></PaginationPages>
              </Flex>
            </Box>
          )}
        </>
      ) : (
        <>
          {isFetching && virtualRows().length === 0 ? (
            <Flex justifyContent={'center'} alignItems='center' gap='1.42rem' py='2.84rem'>
              <Spinner
                thickness='0.28rem'
                speed='0.65s'
                emptyColor='gray.200'
                color='brand.primary.purple'
                size='xl'
              />{' '}
              <Box>Loading...</Box>
            </Flex>
          ) : virtualRows().length > 0 ? (
            <>
              <Box
                sx={{
                  height: `${rowVirtualizer.getTotalSize()}px`,
                  width: '100%',
                  position: 'relative',
                }}
              >
                {virtualRows().map((virtualRow) => {
                  const row = data[virtualRow.index] as Data
                  return (
                    <Box
                      position={'absolute'}
                      w='full'
                      top='0'
                      left={'0'}
                      ref={rowVirtualizer.measureElement}
                      transform={`translateY(${virtualRow.start}px)`}
                      key={virtualRow.index}
                      data-index={virtualRow.index}
                    >
                      {mobileRenderer(row)}
                    </Box>
                  )
                })}
              </Box>
              {isFetching && <LoadingData />}
            </>
          ) : (
            <NoData>{noDataComponent}</NoData>
          )}
        </>
      )}
    </Box>
  )
}
