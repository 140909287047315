import { InfoIcon, WarningIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  useBreakpointValue,
} from '@chakra-ui/react'
import { FSGLink } from 'components'
import React from 'react'
import { SetupAccount } from './Drawer/SetupAccount'

export const UncompletedAccountWarning = () => {
  const size = useBreakpointValue({ base: 'mobile', lg: 'desktop' }, { ssr: false })

  return (
    <Flex
      bg='#FDF6EB'
      px='1.71rem'
      py='1.14rem'
      borderRadius={'1.14rem'}
      justifyContent='space-between'
      mb='2.5rem'
      flexWrap={'wrap'}
    >
      <Flex alignItems={'center'} gap='0.85rem'>
        <WarningIcon color={'#E19100'}></WarningIcon>
        <Box color={'#E19100'}>Complete your data to be able to do transactions</Box>
      </Flex>
      {size === 'mobile' && (
        <Popover>
          <PopoverTrigger>
            <Button variant='link' color='brand.primary.purple' fontWeight={800}>
              <Flex gap={'0.57rem'} alignItems='center'>
                <Box as='span'>Complete registration</Box>{' '}
                <InfoIcon fontSize={'1.14rem'} color='brand.primary.purple'></InfoIcon>
              </Flex>
            </Button>
          </PopoverTrigger>
          <PopoverArrow></PopoverArrow>
          <PopoverContent>
            <SetupAccount variant='mobile' />
          </PopoverContent>
        </Popover>
      )}
      {size === 'desktop' && (
        <FSGLink to='/settings' fontWeight={800}>
          Complete registration
        </FSGLink>
      )}
    </Flex>
  )
}
