import { Accordion, Box, Grid } from '@chakra-ui/react'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { faqSelector } from 'store/selectors/faq'
import { SettingsLayout } from '../common/SettingsLayout'
import { FaqAccordionItem } from './FaqAccordionItem'
import { AmplitudeEvents, useTrackAction } from 'pages/MainLayout/AmplitudeProvider'

export const FAQ: FC = () => {
  const track = useTrackAction()
  const { t } = useTranslation()
  const faqItems = useSelector(faqSelector)

  React.useEffect(() => {
    track?.(AmplitudeEvents.GO_FAQ)
  }, [track])

  return (
    <SettingsLayout title={t('settings.faq.title')} subTitle={t('settings.faq.description')}>
      <div>
        <Accordion allowToggle>
          {faqItems.map((item, index) => (
            <React.Fragment key={index}>
              <FaqAccordionItem item={item}></FaqAccordionItem>
            </React.Fragment>
          ))}
        </Accordion>
      </div>
    </SettingsLayout>
  )
}
