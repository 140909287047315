export const colors = {
  brand: {
    primary: {
      purple: '#8C00E2',
      black: '#0F031E',
    },
    secondary: {
      pink: '#E200A3',
      red: '#D00000',
      yellow: '#E19100',
      green: '#00A524',
      blue: '#0066FF',
      purple: '#8C00E2',
      pinkLight: '#FDEBF8',
      redLight: '#FBEBEB',
      yellowLight: '#FDF6EB',
      greenLight: '#EBF8ED',
      blueLight: '#EBF3FF',
      purpleLight: '#F6EBFD',
      purpleSelected: '#EDD6FA',
      purpleDark: '#39005C',
      darkPurpleAlpha80: 'rgba(15, 3, 30, 0.8)',
    },
    black: {
      900: '#0F031E',
      700: '#524A5D',
      500: '#97929E',
      400: '#C5C2C9',
      300: '#D9D9D9',
      200: '#D9D7DB',
      100: '#F1F0F1',
    },
  },
}
