import { AuthLayout, FSGLink, FSGLogoText } from 'components'
import React, { FC, useEffect } from 'react'
import bg from 'assets/images/sign-up-bg.png'
import { Box, Flex } from '@chakra-ui/react'
import { SignInForm } from './SignInForm'
import { useTranslation } from 'react-i18next'
import { updateHasCalledToast } from 'store/services/baseQueryWithRefresh'

const SignIn: FC = () => {
  const { t } = useTranslation()

  useEffect(() => {
    updateHasCalledToast(false)
  }, [])

  return (
    <AuthLayout bg={bg}>
      <Box textStyle='h1' color='brand.black.900' fontWeight={800} mb='1.71rem'>
        {t('signIn.title')}
      </Box>
      <Box fontSize='lg' mb='1.71rem'>
        {t('signIn.description')}
      </Box>
      <SignInForm></SignInForm>
    </AuthLayout>
  )
}

export default SignIn
