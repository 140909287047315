import {
  Box,
  Button,
  Flex,
  Grid,
  Image,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Stack,
} from '@chakra-ui/react'
import { FSGInput, FSGLink, FSGModalWrapper } from 'components'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Deal, ModalProps, VerificationDeal } from 'types'
import penIcon from 'assets/images/pen.svg'
import { MAIN_DEAL_SLUG, TO_CURRENCY_SLUG } from 'utils/constants'
import { exchangeFormat } from 'utils/currencyFormat'
import { useUpdateDealMutation, useGetDealByIdMutation } from 'store/services/user.api'
import { AmplitudeEvents, useTrackAction } from 'pages/MainLayout/AmplitudeProvider'

interface Props extends ModalProps {
  deal: Deal | null
}

export const EditDealModal: React.FC<Props> = ({ deal, ...props }) => {
  const track = useTrackAction()
  const [betterDeal, setBetterDeal] = useState<boolean>()
  const [updateDeal, { isLoading: isUpdating }] = useUpdateDealMutation()
  const [getDeal] = useGetDealByIdMutation()
  const [rate, setRate] = useState<number>(0)

  const exchangeRate = useMemo(() => {
    return deal?.toCurrency.slug !== MAIN_DEAL_SLUG
      ? deal?.rate || 0
      : exchangeFormat(1 / deal?.rate || 0)
  }, [deal, deal?.rate])

  const calculatedNewDeal = useMemo(() => {
    return deal?.toCurrency.slug === MAIN_DEAL_SLUG ? 1 / rate : rate
  }, [rate])

  const updateDealFactory = useCallback(
    (dryRun: boolean) => {
      return () => updateDeal({ rate: calculatedNewDeal, dealId: deal?.id || '', dryRun })
    },
    [updateDeal, calculatedNewDeal, deal?.id],
  )

  const handleSubmit = React.useCallback(async () => {
    if (!deal) return
    let betterDealsResponse = null

    if (!betterDeal) {
      const betterDeals = await updateDealFactory(true)()
      if ('data' in betterDeals) {
        betterDealsResponse = ((betterDeals.data as any).deals?.length || 0) > 0
        setBetterDeal(betterDealsResponse)
      }
    }

    if (!betterDealsResponse) {
      const response = await updateDealFactory(false)()
      if ('data' in response) {
        track?.(AmplitudeEvents.EDIT_DEAL)
        getDeal({ dealId: deal.id })
        props.onClose()
      } else if ('error' in response) {
        const { code } = (response.error as { data: { success: boolean; code: string } }).data
        setBetterDeal(code === 'DEAL_RATE_EXISTS')
      }
    }
  }, [deal, calculatedNewDeal, betterDeal, updateDealFactory])

  useEffect(() => {
    setRate(exchangeRate || 0)
    return () => setRate(0)
  }, [deal, exchangeRate])

  if (!deal) return <></>
  return (
    <FSGModalWrapper {...props}>
      <Stack spacing={'1.14rem'} mb='1.71rem'>
        <Flex justifyContent={'center'}>
          <Image src={penIcon}></Image>
        </Flex>
        <Box fontSize={'1.42rem'} fontWeight={800} color='brand.black.900' textAlign={'center'}>
          Edit deal
        </Box>
        <Box fontSize={'1.14rem'} color='brand.black.900' textAlign={'center'} lineHeight={'150%'}>
          You only can edit the exchange rate from deals created. To change the quantity, you must
          create a new deal
        </Box>
      </Stack>
      <Box p='1.14rem' bg='#F1F0F199' mb='1.71rem' borderRadius={'1.14rem'}>
        <Box fontSize={['1rem', '1.14rem']} color='brand.black.700' mb='1.14rem'>
          Choose the exchange rate
        </Box>
        <Grid
          templateColumns={'repeat(4, auto)'}
          alignItems={'center'}
          gap='0.57rem'
          fontSize={'1.71rem'}
          fontWeight={800}
          mb='1.71rem'
        >
          <Box>1 {MAIN_DEAL_SLUG}</Box>
          <Box>=</Box>
          <Box>
            <FSGInput
              fontSize={'1.71rem'}
              fontWeight={800}
              type='numeric'
              onChange={(e) => {
                setBetterDeal(false)
                setRate(+e.target.value)
              }}
              value={rate}
              isRequired
            ></FSGInput>
          </Box>
          <Box>{TO_CURRENCY_SLUG}</Box>
        </Grid>
        {/* <Slider
          aria-label='slider-ex-4'
          defaultValue={30}
          value={rate}
          onChange={(e) => {
            setBetterDeal(false)
            setRate(e)
          }}
          focusThumbOnChange={false}
          min={1}
          max={400}
        >
          <SliderTrack bg='brand.black.200' height={'8px'}>
            <SliderFilledTrack bg='brand.primary.purple' height={'8px'} />
          </SliderTrack>
          <SliderThumb
            boxSize={6}
            background='brand.primary.purple'
            border='solid 6px'
            borderColor={'white'}
          ></SliderThumb>
        </Slider> */}
      </Box>
      {betterDeal && (
        <Flex mb='1.14rem'>
          <Box>Better deal already exists &nbsp;</Box>
          <FSGLink to={`/main/${deal.toCurrency.slug}`}>go to marketplace</FSGLink>{' '}
          <Box>&nbsp; to buy it</Box>
        </Flex>
      )}
      <Grid templateColumns={'1fr 1fr'} gap={'1.28rem'}>
        <Button isDisabled={isUpdating} variant={'outline'} onClick={props.onClose}>
          Cancel
        </Button>
        <Button isLoading={isUpdating} onClick={handleSubmit}>
          Edit deal
        </Button>
      </Grid>
    </FSGModalWrapper>
  )
}
