import { extendTheme } from '@chakra-ui/react'
import { Button } from './button'
import { colors } from './colors'
import { fonts } from './fonts'
import { Input } from './input'
import { Select } from './select'
import { Modal } from './modal'
import { textStyles } from './textStyles'
import { Badge } from './badge'
import { Accordion } from './accordion'
import { Switch } from './switcher'
import { Tabs } from './tabs'
import { Table } from './table'

const theme = (dpr: number) => {
  return extendTheme({
    styles: {
      global: {
        'html, body': {
          fontSize: `${14}px`,
          with: '100%',
          height: '100%',
        },
        '.crisp-client .cc-tlyw .cc-kxkl .cc-nsge': {
          bottom: `80px !important`,
        },
      },
    },
    components: {
      Input,
      Button,
      Modal,
      Select,
      Badge,
      Accordion,
      Switch,
      Tabs,
      Table,
    },
    ...fonts,
    colors,
    textStyles,
  })
}

export default theme
