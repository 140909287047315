import React, { createContext, FC, MutableRefObject, PropsWithChildren, useState } from 'react'

export type HandlerFunction = (refElement: HTMLDivElement | null) => void

export const ScrollContext = createContext<{
  scrollHandlers: Record<string, HandlerFunction>
  addHandler?: (key: string, handler: HandlerFunction) => void
  removeHandler?: (key: string) => void
  handleScroll?: (containerRef: MutableRefObject<HTMLDivElement | null>) => void
}>({
  scrollHandlers: {},
  addHandler: undefined,
  removeHandler: undefined,
  handleScroll: undefined,
})

export const ScrollHandlersProvider: FC<PropsWithChildren> = ({ children }) => {
  const [scrollHandlers, setScrollHandlers] = React.useState<Record<string, HandlerFunction>>({})
  const handleScroll = React.useCallback(
    (e: any) => {
      Object.values(scrollHandlers).forEach((handler) => handler(e.target.scrollingElement))
    },
    [scrollHandlers],
  )

  React.useEffect(() => {
    document.addEventListener('scroll', handleScroll)
    return () => document.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  return (
    <ScrollContext.Provider
      value={{
        scrollHandlers: scrollHandlers,
        addHandler: (key, handler) => {
          setScrollHandlers((old) => ({
            ...old,
            [key]: handler,
          }))
        },
        removeHandler: (key) =>
          setScrollHandlers((old) => {
            return Object.fromEntries(
              Object.entries(old).filter(([handlerKey]) => handlerKey !== key),
            )
          }),
        handleScroll: (containerRef: React.MutableRefObject<HTMLDivElement | null>) => {
          return 0
        },
      }}
    >
      {children}
    </ScrollContext.Provider>
  )
}
