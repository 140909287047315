import { Box, Grid, GridItem, InputLeftElement } from '@chakra-ui/react'
import { useFormikContext, useField } from 'formik'
import React, { FC, useEffect } from 'react'
import { FSGInput } from './FSGInput'

interface Props {
  value?: number[]
  name?: string
  onChange: (e: React.ChangeEvent<any>) => void
  currency?: string
}

export const FSGAmountRange: FC<Props> = (props) => {
  const { setFieldValue } = useFormikContext()
  const [field] = useField<number[]>(props.name || '')
  const [minValue, maxValue] = props.value || [0, 0]

  const handleMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value)
    if (!isNaN(value)) {
      setFieldValue(field.name, [value, maxValue])
    } else {
      setFieldValue(field.name, [0, maxValue])
    }
  }

  const handleMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value)
    if (!isNaN(value)) {
      setFieldValue(field.name, [minValue, value])
    } else {
      setFieldValue(field.name, [minValue, 0])
    }
  }

  useEffect(() => {
    if (maxValue < minValue) {
      setFieldValue(field.name, [minValue, minValue])
    }
  }, [maxValue, minValue])

  return (
    <>
      <Box w='full' px='0.85rem'></Box>
      <Grid templateColumns={'1fr 1fr'} gap={'2rem'}>
        <GridItem>
          <FSGInput
            label='Min amount'
            w='full'
            name={'amountRange'}
            h={'2.85rem'}
            onChange={handleMinChange}
            max={maxValue}
            value={minValue}
            /* eslint react/no-children-prop: 0 */
            prependElement={
              <InputLeftElement
                pointerEvents='none'
                children={<>{props.currency === 'NGN' ? '₦' : '$'}</>}
              />
            }
          ></FSGInput>
        </GridItem>
        <GridItem>
          <FSGInput
            label='Max amount'
            w='full'
            name={'amountRange'}
            h={'2.85rem'}
            type='numeric'
            onChange={handleMaxChange}
            min={minValue}
            value={maxValue}
            /* eslint react/no-children-prop: 0 */
            prependElement={
              <InputLeftElement
                pointerEvents='none'
                children={<>{props.currency === 'NGN' ? '₦' : '$'}</>}
              />
            }
          ></FSGInput>
        </GridItem>
      </Grid>
    </>
  )
}
