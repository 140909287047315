import { Button, Box } from '@chakra-ui/react'
import { FSGInput } from 'components'
import { FormikProvider, useFormik } from 'formik'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  label: string
  placeholder: string
  isLoading: boolean
  disabled?: boolean
  value?: string
  onChange: (value: string) => void
  onSubmit: () => void
}

export const PersonalInfoPanel: FC<Props> = ({
  value,
  label,
  placeholder,
  isLoading,
  disabled,
  onChange,
  onSubmit,
}) => {
  const { t } = useTranslation()
  const formik = useFormik({
    initialValues: {
      value: value,
    },
    onSubmit: () => {
      onSubmit()
    },
  })
  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <Box>
          <FSGInput
            value={formik.values.value}
            label={label}
            name='value'
            placeholder={placeholder}
            onChange={(e) => {
              formik.handleChange(e)
              onChange(e.target.value)
            }}
            mb='1.71rem'
            isDisabled={isLoading || disabled}
            isRequired
          ></FSGInput>
          <Button
            type='submit'
            isDisabled={isLoading || disabled || !formik.dirty}
            isLoading={isLoading}
          >
            {t('buttons.save')}
          </Button>
        </Box>
      </form>
    </FormikProvider>
  )
}
