import { Box, Button } from '@chakra-ui/react'
import { FSGPhoneInputFormikField } from 'components'
import { FormikProvider, useFormik } from 'formik'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { userInfoSelector } from 'store/selectors/user'
import { useUpdateUserPersonalInfoMutation } from 'store/services/user.api'
import { PhoneNumber, UpdateUserProfileRequest } from 'types'

interface Props {
  onSuccess: () => void
}

export const ChangePhoneNumber: FC<Props> = ({ onSuccess }) => {
  const userInfo = useSelector(userInfoSelector)
  const [updateUserInfo, { isLoading }] = useUpdateUserPersonalInfoMutation()
  const formik = useFormik<UpdateUserProfileRequest>({
    initialValues: {
      name: userInfo?.name,
      phoneNumber: userInfo?.phoneNumber,
      firstName: userInfo?.firstName,
      middleName: userInfo?.middleName,
      lastName: userInfo?.lastName,
    },
    onSubmit: async (val) => {
      const response = await updateUserInfo(val)
      if ('data' in response) {
        onSuccess()
      }
    },
  })
  const { values, handleChange, handleSubmit, handleBlur } = formik

  return (
    <FormikProvider value={formik}>
      <Box w='full'>
        <form onSubmit={handleSubmit}>
          <FSGPhoneInputFormikField
            marginBottom='1.71rem'
            name='phoneNumber'
            onChange={handleChange}
            value={
              values.phoneNumber as (string | number | readonly string[] | undefined) & PhoneNumber
            }
            onBlur={handleBlur}
          ></FSGPhoneInputFormikField>
          <Button type='submit' isLoading={isLoading} w='full' isDisabled={!formik.dirty}>
            Save
          </Button>
        </form>
      </Box>
    </FormikProvider>
  )
}
