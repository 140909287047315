import { Box, Button, Flex, Grid, Image, Stack, useDisclosure } from '@chakra-ui/react'
import { FSGInput, FSGModalWrapper } from 'components'
import React, { useCallback, useEffect } from 'react'
import { BuyDeal, MarketplaceDeal, ModalProps, VerificationDeal } from 'types'
import basketImage from 'assets/images/basket.svg'
import { useTranslation } from 'react-i18next'
import { FSGAvatar } from 'components/FSGAvatar'
import { FSGMaskedInput } from 'components/FSGMaskedInput'
import { TransactionDetails } from './TransactionDeatails'
import { useBuyDealMutation, useVerifyBuyDealMutation } from 'store/services/user.api'
import { useFormik } from 'formik'
import { useSelector } from 'react-redux'
import { getWalletsDictsSelector } from 'store/selectors/user'
import { ConfirmModal } from 'components/modals/ConfirmModal'
import { currencyFormat } from 'utils/currencyFormat'
import { supportedCurrenciesSelector } from 'store/selectors/currencies'
import { amountToNumber } from 'utils/amountToNumber'
import { AmplitudeEvents, useTrackAction } from 'pages/MainLayout/AmplitudeProvider'

interface IProps extends ModalProps {
  deal: MarketplaceDeal | VerificationDeal
}
/**
 * @author @Topsyaka077
 * @function ViewDealModal
 **/

export function ViewDealModal<FC>({ deal, ...props }: IProps) {
  const track = useTrackAction()
  const { t } = useTranslation()
  const walletData = useSelector(getWalletsDictsSelector)
  const currencies = useSelector(supportedCurrenciesSelector)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [buyDeal, { isLoading }] = useBuyDealMutation()
  const [verifyDeal, { isLoading: isVerifying, data: verificationData, reset: resetVerification }] =
    useVerifyBuyDealMutation()
  useEffect(() => {
    track?.(
      (deal as MarketplaceDeal).fromCurrencySlug === 'CAD'
        ? AmplitudeEvents.VIEW_CAD_DEAL
        : AmplitudeEvents.VIEW_NGN_DEAL,
    )
  }, [track])
  const formik = useFormik<BuyDeal>({
    initialValues: {
      amount: 0,
      dealId: deal.id,
      promoCode: '',
    },
    validate: (value) => {
      const amountNumber = amountToNumber(value.amount.toString())
      const availableBalance =
        walletData[(deal as MarketplaceDeal).toCurrencySlug]?.[0].availableBalance
      const errors: { amount?: string } = {}

      if (amountNumber > deal.amount - +deal.totalSold) {
        errors.amount = 'Amount is too big'
      }
      if (amountNumber <= 0) {
        errors.amount = "Amount value can't be 0"
      }
      if (amountNumber * deal.rate > availableBalance) {
        errors.amount = 'Amount is bigger than your available balance, add more funds'
      }
      return errors
    },
    onSubmit: async () => {
      onOpen()
    },
  })

  const handleVerify = useCallback(async () => {
    const fromCurrencyId = currencies.find(
      (item) => item.slug === (deal as MarketplaceDeal).fromCurrencySlug,
    )?.id
    const toCurrencyId = currencies.find(
      (item) => item.slug === (deal as MarketplaceDeal).toCurrencySlug,
    )?.id

    if (fromCurrencyId && toCurrencyId && amountToNumber(formik.values.amount.toString()) > 0) {
      await verifyDeal({
        amount: amountToNumber(formik.values.amount.toString()),
        rate: +deal.rate,
        fromCurrencyId,
        toCurrencyId,
      })
    }
  }, [formik.values])

  const handleConfirm = useCallback(async () => {
    const response = await buyDeal({
      ...formik.values,
      amount: amountToNumber(formik.values.amount.toString()),
    })
    if ('data' in response) {
      track?.(AmplitudeEvents.BUY_DEAL, {
        slug: (deal as MarketplaceDeal).fromCurrencySlug,
        amount: amountToNumber(formik.values.amount.toString()),
      })
      props.onClose()
    }
  }, [formik.values])

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null
    const fromCurrencyId = currencies.find(
      (item) => item.slug === (deal as MarketplaceDeal).fromCurrencySlug,
    )?.id
    const toCurrencyId = currencies.find(
      (item) => item.slug === (deal as MarketplaceDeal).toCurrencySlug,
    )?.id
    if (fromCurrencyId && toCurrencyId && amountToNumber(formik.values.amount.toString()) > 0) {
      timer = setTimeout(() => {
        verifyDeal({
          amount: amountToNumber(formik.values.amount.toString()),
          rate: +deal.rate,
          fromCurrencyId,
          toCurrencyId,
        })
      }, 1000)
    }

    return () => {
      timer && clearTimeout(timer)
    }
  }, [formik.values])

  return (
    <>
      <FSGModalWrapper
        {...props}
        size={{ base: 'full', md: 'md' }}
        preserveScrollBarGap
        allowPinchZoom
        isCentered
        autoFocus
        blockScrollOnMount={false}
        motionPreset='slideInBottom'
        height={'100vh'}
      >
        <Box w='full'>
          <form onSubmit={formik.handleSubmit}>
            {'displayName' in deal ? (
              <>
                <Stack alignItems={'center'} spacing='1.14rem' mb='2.28rem'>
                  <Image src={basketImage} h='3.85rem'></Image>
                  <Stack alignItems={'center'}>
                    <Box textStyle={'modalTitle'}>
                      {t('mainPage.acceptDeal', { slug: deal.fromCurrencySlug })}
                    </Box>
                    <Flex alignItems={'center'} gap={'0.57rem'}>
                      <FSGAvatar text={deal.displayName}></FSGAvatar>
                      <Box textStyle={'modalSubtitle'}>{deal.displayName}</Box>
                    </Flex>
                  </Stack>
                  <Box textStyle='modalSubtitle'>{t('mainPage.acceptSubtitle')}</Box>
                </Stack>

                <Stack mb='1.71rem'>
                  <FSGMaskedInput
                    currencySlug={deal.fromCurrencySlug}
                    onChange={(e) => {
                      resetVerification()
                      formik.handleChange(e)
                    }}
                    value={formik.values.amount}
                    name='amount'
                    label='Amount'
                    error={formik.errors.amount}
                    isRequired
                  ></FSGMaskedInput>
                  <Box>
                    <FSGInput
                      label='Promo code'
                      value={formik.values.promoCode}
                      onChange={formik.handleChange}
                      name='promoCode'
                    ></FSGInput>
                  </Box>
                </Stack>
                <Box mb='2.28rem'>
                  <TransactionDetails
                    deal={deal}
                    verificationResult={verificationData}
                    amount={amountToNumber(formik.values.amount.toString())}
                  ></TransactionDetails>
                </Box>
              </>
            ) : (
              <>
                <Stack alignItems={'center'} spacing='1.14rem' mb='2.28rem'>
                  <Image src={basketImage} h='3.85rem'></Image>
                  <Stack alignItems={'center'}>
                    <Box textStyle={'modalTitle'}>
                      {t('mainPage.acceptDeal', { slug: deal.fromCurrency.slug })}
                    </Box>
                  </Stack>
                  <Box textStyle='modalSubtitle'>{t('mainPage.acceptSubtitle')}</Box>
                </Stack>
                <Box mb='1.71rem'>
                  <FSGMaskedInput
                    currencySlug={deal.fromCurrency.slug}
                    onChange={formik.handleChange}
                    value={formik.values.amount}
                    name='amount'
                  ></FSGMaskedInput>
                </Box>
                <Box mb='2.28rem'>
                  <TransactionDetails
                    deal={deal}
                    verificationResult={verificationData}
                    amount={amountToNumber(formik.values.amount.toString())}
                  ></TransactionDetails>
                </Box>
              </>
            )}
            <Grid templateColumns={'1fr 1fr'} gap='2.14rem' mb={'1.14rem'}>
              <Button variant='outline' onClick={props.onClose}>
                {t('buttons.cancel')}
              </Button>
              <Button
                type='submit'
                isLoading={isLoading || isVerifying}
                isDisabled={!formik.isValid}
              >
                {t('buttons.exchange')}
              </Button>
            </Grid>
          </form>
        </Box>
      </FSGModalWrapper>

      <ConfirmModal title='Buy deal' isOpen={isOpen} onClose={onClose} onConfirm={handleConfirm}>
        <Box textStyle={'p1'} mb='0.85rem'>
          Are you sure you want to accept this deal with amount{' '}
          {currencyFormat(amountToNumber(formik.values.amount.toString()))}{' '}
          {(deal as MarketplaceDeal).fromCurrencySlug}
        </Box>
        <Box textStyle={'p1'}>
          You will pay{' '}
          {currencyFormat(
            amountToNumber(formik.values.amount.toString()) * deal.rate +
              (verificationData?.commissionAmount || 0),
          )}{' '}
          {(deal as MarketplaceDeal).toCurrencySlug}
        </Box>
      </ConfirmModal>
    </>
  )
}
