import { createSelector } from '@reduxjs/toolkit'
import { RootStore } from 'store'
import { flatten, groupBy } from 'lodash'

export const userInfoSelector = (state: RootStore) => state.user.user
export const notificationSettingsSelector = (state: RootStore) => state.user.notificationSettings
export const userAccountsSelector = (state: RootStore) => state.user.accounts
export const userAccountsByCurrencySlugSelector = createSelector(
  userAccountsSelector,
  (accounts) => {
    if (accounts) {
      return groupBy(accounts, 'currency.slug')
    }
  },
)
export const accountByCurrencySelector = (currencyId: string) =>
  createSelector(userAccountsSelector, (accounts) => {
    return accounts.find((acc) => acc.currency.id === currencyId)
    //return {}
  })
export const getWalletsDictsSelector = createSelector(
  (state: RootStore) => state.user.wallets,
  (state) => {
    return groupBy(state, (item) => item.currency.slug)
  },
)
export const getPayinAccountsByCurrency = (state: RootStore) =>
  groupBy(state.user.payinAccounts, 'currency.slug')

export const dealTransactionsSelectorPerPage = (state: RootStore) =>
  state.wallet.dealsTransactionsPerPage
export const dealTransactionsSelector = createSelector(dealTransactionsSelectorPerPage, (state) => {
  return flatten(Object.values(state))
})

export const walletTransactionsPerPage = (state: RootStore) =>
  state.wallet.walletTransactionsPerPage
export const walletTransactionsSelector = createSelector(walletTransactionsPerPage, (state) => {
  return flatten(Object.values(state))
})

export const myDeals = (state: RootStore) => flatten(Object.values(state.user.deals))
export const userAccountIsSetup = (state: RootStore) =>
  !!state.user.accounts.length &&
  state.user.user?.hasCompletedKyc &&
  state.user.user?.emailVerified &&
  state.user.user?.phoneNumberVerified

export const hasCompletedKyc = (state: RootStore) => state.user.user?.hasCompletedKyc

export const kycStatusSelector = (state: RootStore) => state.user.kycStatus
