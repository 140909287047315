import { TriangleDownIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  chakra,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverAnchor,
  PopoverBody,
  NumberInput,
  NumberInputField,
} from '@chakra-ui/react'
import { CurrencyIcons } from 'assets/icons/currencyIcons'
import { get } from 'lodash'
import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { supportedCurrenciesSelector } from 'store/selectors/currencies'
import { Currency } from 'types'

type Props = InputProps & {
  label?: string
  value?: string
  onChange?: (e: string) => void
  onCurrencyChange?: (e: Currency) => void
}

export const MoneyInput: FC<Props> = ({ label, onCurrencyChange, ...props }) => {
  const currencies = useSelector(supportedCurrenciesSelector)
  const [currency, setCurrency] = useState(() => currencies?.[0])
  const format = (val: string) => `${currency?.slug === 'CAD' ? '$' : '₦'}` + val
  const parse = (val: string) => val.replace(/^\$/, '')

  useEffect(() => {
    if (!currencies) return
    setCurrency(currencies[0])
    onCurrencyChange?.(currencies[0])
  }, [currencies])

  return (
    <Popover matchWidth isLazy>
      <FormControl>
        <FormLabel
          as='label'
          display='block'
          fontSize='md'
          fontWeight={800}
          htmlFor={props.id}
          marginBottom='0.57rem'
        >
          {label}{' '}
          {props.isRequired && (
            <Box as='span' color='brand.secondary.red'>
              *
            </Box>
          )}
        </FormLabel>
        <InputGroup
          border='solid 1px'
          borderRadius='0.57rem'
          borderColor='brand.black.200'
          overflow={'hidden'}
          _hover={{
            borderColor: 'brand.black.900',
          }}
          _active={{
            borderColor: 'brand.black.900',
          }}
          _focus={{
            borderColor: 'brand.black.900',
          }}
        >
          <NumberInput
            border='none'
            width='full'
            min={0}
            precision={2}
            _focus={{
              border: 'none',
            }}
            outline={'none'}
            value={format(props.value || '')}
            onKeyDown={() => {
              if (+(props.value || 0) === 0) {
                props.onChange?.('')
              }
            }}
            onBlur={() => {
              if (!props.value) {
                props.onChange?.('0')
              }
            }}
            onChange={(val) => {
              props.onChange?.(parse(val))
            }}
          >
            <NumberInputField border={'none'} outline={'none'} fontSize={'1rem'}></NumberInputField>
          </NumberInput>
          <PopoverAnchor>
            <InputRightElement width={'7.5rem'} bg='brand.black.200'>
              <PopoverTrigger>
                <Flex alignItems={'center'} cursor={'pointer'} gap={'0.28rem'}>
                  <Box>
                    <Flex gap='0.28rem' alignItems={'center'}>
                      {React.createElement(get(CurrencyIcons, currency?.slug) || React.Fragment)}
                      <chakra.span fontSize={'1rem'} fontWeight='500'>
                        {currency?.slug}
                      </chakra.span>
                    </Flex>
                  </Box>
                  <Box fontSize={'0.85rem'}>
                    <TriangleDownIcon></TriangleDownIcon>
                  </Box>
                </Flex>
              </PopoverTrigger>
            </InputRightElement>
          </PopoverAnchor>
        </InputGroup>
      </FormControl>
      <PopoverContent width='full'>
        <PopoverBody maxH={200} overflow='auto'>
          {currencies?.map((curr) => (
            <Flex
              gap='0.28rem'
              py='0.57rem'
              alignItems={'center'}
              key={curr.id}
              cursor={'pointer'}
              onClick={() => {
                setCurrency(curr)
                onCurrencyChange?.(curr)
              }}
            >
              {React.createElement(get(CurrencyIcons, curr.slug) || React.Fragment)}
              <chakra.span fontSize={'1rem'} fontWeight='500'>
                {curr.slug}
              </chakra.span>
            </Flex>
          ))}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
