export enum OTPType {
  SMS = 'sms',
  EMAIL = 'email',
  TOTP = 'totp',
}

export enum PurposeType {
  SIGNUP = 'signup',
  FORGOT_PASSWORD = 'forgot_password',
  LOGIN = 'login',
  WALLET_WITHDRAWAL = 'wallet_withdrawal',
  UPDATE_PAYOUT_ACCOUNT = 'update_payout_account',
  VERIFY_EMAIL = 'verify_email',
  VERIFY_PHONE = 'verify_phone_number',
}

export interface OTPResponse {
  code: string
  otpType: OTPType
  purpose: PurposeType
  userId: string
}

export interface VerifyUserAction {
  userId: string
  verificationCode: string
  purpose: PurposeType
}
