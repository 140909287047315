import { Box, Button, Flex, Grid, Image, Stack } from '@chakra-ui/react'
import { FSGModalWrapper } from 'components'
import React from 'react'
import { Account, ModalProps } from 'types'
import trashIcon from 'assets/images/trash.svg'
import { useDeleteAccountMutation } from 'store/services/user.api'

interface Props extends ModalProps {
  onConfirm: () => void
  selectedAccount: Account | null
}

export const DeleteAccountModal: React.FC<Props> = ({ onConfirm, selectedAccount, ...props }) => {
  const [deleteAccount] = useDeleteAccountMutation()

  return (
    <FSGModalWrapper {...props}>
      <Stack spacing={'1.14rem'} mb='1.71rem'>
        <Flex justifyContent={'center'}>
          <Image src={trashIcon}></Image>
        </Flex>
        <Box fontSize={'1.42rem'} fontWeight={800} color='brand.black.900' textAlign={'center'}>
          Delete account
        </Box>
        <Box fontSize={'1.14rem'} color='brand.black.900' textAlign={'center'} lineHeight={'150%'}>
          Are you sure you want to delete account?
        </Box>
      </Stack>
      <Grid templateColumns={'1fr 1fr'} gap={'1.28rem'}>
        <Button variant={'outline'} onClick={props.onClose}>
          Cancel
        </Button>
        <Button
          onClick={async () => {
            if (selectedAccount) {
              const resp = await deleteAccount({ accountId: selectedAccount.id })
              if ('data' in resp) {
                props.onClose()
              }
            }
          }}
        >
          Delete account
        </Button>
      </Grid>
    </FSGModalWrapper>
  )
}
