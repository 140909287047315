import { Box, Button, Flex, Stack } from '@chakra-ui/react'
import { BankIcon, HashIcon, NGNIcon } from 'assets/icons'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { banksSelector } from 'store/selectors/currencies'
import { Account } from 'types'

interface Props extends Account {
  onEdit: (id: Account) => void
  onDelete: () => void
}

export const NGNAccount: FC<Props> = ({ onEdit, onDelete, ...props }) => {
  const { t } = useTranslation()
  const banks = useSelector(banksSelector)

  return (
    <Box>
      <Box mb='1.14rem'>
        <NGNIcon width={'2.5rem'} height='1.78rem'></NGNIcon>
      </Box>
      <Flex justifyContent={'space-between'} mb='0.57rem'>
        <Box as='h3' textStyle={'cardTitle'}>
          {t('settings.payouts.ngn.title')}
        </Box>
        <Flex gap='0.85rem'>
          {props && (
            <>
              <Button
                variant='link'
                fontWeight={800}
                color='brand.primary.purple'
                fontSize={'1.14rem'}
                cursor='pointer'
                onClick={() => onEdit(props)}
              >
                {t('buttons.edit')}
              </Button>
              <Button
                variant='link'
                fontWeight={800}
                color='brand.secondary.red'
                fontSize={'1.14rem'}
                cursor='pointer'
                onClick={() => onDelete()}
              >
                {t('buttons.delete')}
              </Button>
            </>
          )}
        </Flex>
      </Flex>
      <Box textStyle={'cardSubtitle'} mb='1.14rem'>
        {t('settings.payouts.ngn.description')}
      </Box>
      <Stack spacing={'1.14rem'}>
        <Flex
          justifyContent={'flex-start'}
          alignItems='center'
          gap='0.57rem'
          borderBottom={'solid 0.07rem #F1F0F1'}
          pb={'1.14rem'}
        >
          <Flex padding='0.57rem' borderRadius={'0.57rem'} bg='brand.black.100'>
            <HashIcon fill='#524A5D99' width='1.14rem' height='1.14rem' opacity={0.6} />
          </Flex>
          <Box fontSize='1.14rem' color='brand.black.700'>
            {t('fields.bankAccount.label')}: {props.account.destinationBankAccountNumber}
          </Box>
        </Flex>
        <Flex justifyContent={'flex-start'} alignItems='center' gap='0.57rem'>
          <Flex padding='0.57rem' borderRadius={'0.57rem'} bg='brand.black.100'>
            <BankIcon fill='#524A5D99' width='1.14rem' height='1.14rem' opacity={0.6} />
          </Flex>
          <Box fontSize='1.14rem' color='brand.black.700'>
            {banks.find((bank) => bank.uuid === props.account.destinationBankUUID)?.name}
          </Box>
        </Flex>
      </Stack>
    </Box>
  )
}
