import { ComponentStyleConfig } from '@chakra-ui/react'

export const Button: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: '0.57rem',
    fontSize: 'lg',
    fontWeight: 800,
  },
  sizes: {
    sm: {
      h: '1.71rem',
    },
    lg: {
      h: '2.85rem',
    },
  },
  variants: {
    solid: {
      bg: 'brand.black.900',
      color: 'white',
      _hover: {
        bg: 'brand.secondary.purpleDark',
      },
      _focus: {
        bg: 'brand.secondary.purpleDark',
      },
      _active: {
        bg: 'brand.secondary.purpleDark',
      },
    },
    outline: {
      bg: 'transparent',
      color: 'brand.black.900',
      border: '0.07rem solid',
      borderColor: 'brand.black.200',
      _hover: {
        borderColor: 'brand.black.900',
        color: 'brand.black.900',
        bg: 'transparent',
      },
      _focus: {
        borderColor: 'brand.black.900',
        color: 'brand.black.900',
        bg: 'transparent',
      },
      _active: {
        borderColor: 'brand.black.900',
        color: 'brand.black.900',
        bg: 'transparent',
      },
    },
    pageButton: {
      width: '2.28rem',
      height: '2.28rem',
      borderRadius: '0.57rem',
      fontSize: '1rem',
      lineHeight: '2.28rem',
      minW: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: 400,
      p: 0,
      _activeStep: {
        bg: 'brand.primary.purple',
        fontWeight: 800,
        color: 'white',
      },
    },
    filterButton: {
      bg: 'transparent',
      borderColor: 'brand.black.200',
      border: '0.07rem solid',
      color: 'brand.black.900',
      px: '1.14rem',
    },
  },
}
