import { InputProps } from '@chakra-ui/react'
import { FieldInputProps, useField, useFormikContext } from 'formik'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { PhoneNumber } from 'types'
import phoneNumberCodes from 'assets/phoneCode.json'
import { FSGPhoneInput } from './FSGPhoneInput'

type PhoneNumberCode = {
  name: string
  dial_code: string
  code: string
}
type ExtendedProps = InputProps & FieldInputProps<PhoneNumber>
interface Props extends ExtendedProps {
  label?: string
  error?: string
}

export const FSGPhoneInputFormikField: FC<Props> = ({ label, error, marginBottom, ...props }) => {
  const [field] = useField<PhoneNumber>(props.name)
  const context = useFormikContext()
  const [code, setCode] = useState<PhoneNumberCode>(phoneNumberCodes[0])

  useEffect(() => {
    if (!field.value.countryCode) {
      handleChangeCode(phoneNumberCodes.find((code) => code.code === 'CA') || phoneNumberCodes[0])
    }
  }, [])
  const phoneCode = useMemo(() => {
    return phoneNumberCodes.find((phoneCode) => phoneCode.dial_code === field.value.countryCode)
  }, [field])

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: 'countryCode' | 'number',
  ) => {
    context.setFieldValue(props.name, {
      ...field.value,
      [fieldName]: e.target.value,
    })
  }

  const handleChangeCode = (e: PhoneNumberCode) => {
    context.setFieldValue(props.name, {
      ...field.value,
      countryCode: e.dial_code,
    })
    setCode(e)
  }

  return (
    <FSGPhoneInput
      {...props}
      code={phoneCode || code}
      label={label}
      error={error}
      marginBottom={marginBottom}
      onCodeChange={handleChangeCode}
      onChange={(e) => {
        handleChange(e, 'number')
      }}
    />
  )
}
