import { InfoIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  chakra,
  Grid,
  GridItem,
  Popover,
  Stack,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'
import { BackLink, FSGInput, MoneyInput } from 'components'
import { SuccessModal } from 'components/modals/SuccessModal'
import { ViewDealModal } from 'pages/HomePage/ViewDealModal'
import MainLayout from 'pages/MainLayout'
import React, { FC, useState, useMemo, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { supportedCurrenciesSelector } from 'store/selectors/currencies'
import { getWalletsDictsSelector, userAccountIsSetup } from 'store/selectors/user'
import { useGetCurrencyPairMutation } from 'store/services/currency.api'
import { useCreateDealMutation, useVerifyDealMutation } from 'store/services/user.api'
import { BaseError, Currency, VerificationDeal } from 'types'
import { MAIN_DEAL_SLUG, TOAST_ERROR_TIMEOUT } from 'utils/constants'
import { CreateDealConfirm } from './CreateDealConfirm'
import { InfoPanel } from './InfoPannel'
import { PaymentInfo } from './PaymentInfo'
import { RateInput } from './RateInput'
import { VerifiedDealsModal } from './VerifiedDealsModal'
import { useTrackAction, AmplitudeEvents } from 'pages/MainLayout/AmplitudeProvider'
import { useDebounce } from 'usehooks-ts'
import { toast } from 'components/FSGToast'

export const CreateDeal: FC = () => {
  const { t } = useTranslation()
  const track = useTrackAction()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { isOpen: isSuccessOpen, onClose: onSuccessClose, onOpen: onSuccessOpen } = useDisclosure()
  const {
    isOpen: isVerifyModalOpen,
    onClose: onVerifyModalClose,
    onOpen: onVerifyModalOpen,
  } = useDisclosure()

  const currencies = useSelector(supportedCurrenciesSelector)
  const wallets = useSelector(getWalletsDictsSelector)
  const isAccountSetup = useSelector(userAccountIsSetup)

  const [getPair, { data: pair }] = useGetCurrencyPairMutation()
  const [createDeal, { isLoading: isCreating }] = useCreateDealMutation()
  const [verifyDeal, { isLoading: isVerifyingDeal }] = useVerifyDealMutation()

  const [currency, setCurrency] = useState<Currency | null>(null)
  const [commission, setCommission] = useState<number>(0)
  const [discount, setDiscount] = useState<number>(0)
  const [amount, setAmount] = useState<string>('0')
  const numberAmount = useMemo(() => parseFloat(amount), [amount])
  const debouncedAmount = useDebounce(numberAmount, 500)

  const [rate, setRate] = useState<number>(0)
  const [promoCode, setPromoCode] = useState<string>('')
  const debouncedPromoCode = useDebounce(promoCode, 500)
  const [dealsFromVerification, setDealsFromVerification] = useState<VerificationDeal[]>()

  // Temp solution when we have 2 currencies
  const calculatedRate = useMemo(() => {
    if (currency?.slug === MAIN_DEAL_SLUG) {
      return rate
    }
    return rate ? 1 / rate : 0
  }, [rate, currency, amount])

  const debouncedCalculatedRate = useDebounce(calculatedRate, 500)

  const activeWallet = useMemo(() => {
    if (currency?.slug && wallets?.[currency?.slug]) {
      return wallets[currency.slug][0]
    }
    return null
  }, [wallets, currency])

  const toCurrency = useMemo(() => {
    return currency && currencies.filter((curr) => curr.id !== currency.id)[0]
  }, [currencies, currency])

  const verifyDealCreation = useCallback(async () => {
    if (currency && toCurrency && calculatedRate && debouncedAmount) {
      const response = await verifyDeal({
        amount: debouncedAmount,
        rate: debouncedCalculatedRate,
        fromCurrencyId: currency?.id,
        toCurrencyId: toCurrency.id,
        promoCode: debouncedPromoCode,
      })
      if ('data' in response) {
        if (response.data.deals.length > 0) {
          setDealsFromVerification(response.data.deals)
        }
        setCommission(response.data.commissionAmount)
        setDiscount(response.data.discount)
      } else {
        setDealsFromVerification([])
        setCommission(0)
        setDiscount(0)
        toast({
          title: t('createDeal.title'),
          description: (response as BaseError).error.data?.message,
          status: 'error',
          duration: TOAST_ERROR_TIMEOUT,
          isClosable: true,
          position: 'top',
        })
      }
    } else {
      setDealsFromVerification([])
      setCommission(0)
      setDiscount(0)
    }
  }, [
    verifyDeal,
    currency,
    toCurrency,
    debouncedCalculatedRate,
    debouncedPromoCode,
    debouncedAmount,
  ])

  useEffect(() => {
    if (isAccountSetup) {
      verifyDealCreation()
    }
  }, [debouncedAmount, debouncedCalculatedRate, debouncedPromoCode])

  const handleClick = useCallback(async () => {
    if (dealsFromVerification?.length) {
      onVerifyModalOpen()
    } else {
      onOpen()
    }
  }, [dealsFromVerification])

  const createDealHandler = useCallback(async () => {
    if (currency && toCurrency) {
      const resp = await createDeal({
        amount: debouncedAmount,
        rate: calculatedRate,
        fromCurrencyId: currency?.id,
        toCurrencyId: toCurrency.id,
      })
      track?.(AmplitudeEvents.CREATE_DEAL, { amount })
      if ('data' in resp) {
        setAmount('0')
        setRate(0)
        onClose()
        onSuccessOpen()
      }
    }
  }, [calculatedRate, currency, toCurrency, createDeal, debouncedAmount])

  useEffect(() => {
    if (currency && toCurrency && currency.id !== toCurrency.id) {
      getPair({
        fromCurrencyId: currency.id,
        toCurrencyId: toCurrency.id,
      })
    }
  }, [currency, toCurrency])

  return (
    <Box>
      <BackLink></BackLink>
      <chakra.h2 textStyle='pageTitle' my='2.28rem'>
        {t('createDeal.title')}
      </chakra.h2>
      <chakra.p fontSize={'1.28rem'} color='brand.black.700' mb='2.28rem'>
        {t('createDeal.description')}
      </chakra.p>
      <Grid
        templateColumns={{ base: '1fr', md: '1fr 1fr' }}
        rowGap={'1.14rem'}
        columnGap={{ base: '5rem' }}
      >
        <GridItem>
          <Stack spacing={'1.14rem'}>
            <MoneyInput
              label={'Amount to sell'}
              isRequired
              value={amount}
              onCurrencyChange={(currency) => {
                setCurrency(currency)
              }}
              onChange={(value) => {
                setAmount(value as string)
              }}
            />
            {currency && (
              <RateInput
                currency={currency}
                onRateChange={(rate) => setRate(rate)}
                rate={rate}
              ></RateInput>
            )}
            <Tooltip label={'Enter promo code if you have one'}>
              <FSGInput
                label='Promo code'
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
              ></FSGInput>
            </Tooltip>
            <InfoPanel></InfoPanel>
          </Stack>
        </GridItem>
        <GridItem>
          <Box mb='2.28rem'>
            {currency && toCurrency && pair && (
              <>
                <PaymentInfo
                  fromCurrency={currency}
                  toCurrency={toCurrency}
                  amount={numberAmount}
                  commission={commission}
                  discount={discount}
                  rate={rate}
                  realRate={calculatedRate}
                  pair={pair}
                ></PaymentInfo>
                <CreateDealConfirm
                  isOpen={isOpen}
                  isLoading={isCreating || isVerifyingDeal}
                  onClose={onClose}
                  onConfirm={createDealHandler}
                ></CreateDealConfirm>
              </>
            )}
          </Box>
          <Button
            w='full'
            onClick={handleClick}
            disabled={
              !rate ||
              !amount ||
              numberAmount > (activeWallet?.availableBalance || 0) ||
              !isAccountSetup
            }
            isLoading={isCreating || isVerifyingDeal}
          >
            {t('createDeal.createDeal')}
          </Button>
          {numberAmount > (activeWallet?.availableBalance || 0) && (
            <Box color={'brand.secondary.red'} fontSize='1rem' mt='0.57rem'>
              Insufficient funds to complete this transaction. Increase funds in wallet or reduce
              deal amount.
            </Box>
          )}
        </GridItem>
      </Grid>

      <SuccessModal
        isOpen={isSuccessOpen}
        onClose={onSuccessClose}
        onConfirm={onSuccessClose}
        title={t('createDeal.dealCreated')}
        closeButtonText='Close'
      ></SuccessModal>
      <VerifiedDealsModal
        isOpen={isVerifyModalOpen}
        onClose={onVerifyModalClose}
        deals={dealsFromVerification}
        onCreateDeal={async () => {
          await createDealHandler()
          onVerifyModalClose()
        }}
        isCreating={isCreating}
      ></VerifiedDealsModal>
    </Box>
  )
}
