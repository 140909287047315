import { Box, Flex, Image } from '@chakra-ui/react'
import React, { FC } from 'react'
import { AvatarMenu } from './AvatarMenu'
import logoMobile from 'assets/images/logo-mobile.png'

export const Header: FC<{ noLogin?: boolean }> = ({ noLogin = false }) => {
  return (
    <Box as='header' pt='1.42rem' bg='white' h='full'>
      <Flex alignItems={'center'} justifyContent='center'>
        <Image src={logoMobile} alt={'FSGX Logo'} h='2.14rem'></Image>
      </Flex>
      {/* <FSGLogoText
        logoVariant='mobileHeader'
        justifyContent={'center'}
        display='flex'
      ></FSGLogoText> */}
      {!noLogin && (
        <Box position={'absolute'} right='1.14rem' top='1.42rem'>
          <AvatarMenu></AvatarMenu>
        </Box>
      )}
    </Box>
  )
}
