import { Button, InputRightElement } from '@chakra-ui/react'
import { EyeIcon } from 'assets/icons'
import { useField } from 'formik'
import React, { FC, useState } from 'react'
import { FSGInput, Props as FSGInputProps } from './FSGInput'

interface Props extends FSGInputProps {
  label: string
  showPassword: boolean
  name: string
}

export const FSGPasswordInput: FC<Props> = ({ children, showPassword, ...rest }) => {
  const [field] = useField(rest.name)
  const [showText, setShowText] = useState<boolean>(false)

  return (
    <FSGInput {...field} {...rest} type={showText ? 'text' : 'password'} isRequired>
      {showPassword && (
        <InputRightElement>
          <Button variant='link' onClick={() => setShowText(!showText)}>
            <EyeIcon></EyeIcon>
          </Button>
        </InputRightElement>
      )}
    </FSGInput>
  )
}
