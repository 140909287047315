import { Box, Button, Flex } from '@chakra-ui/react'
import { useCopyToClipboard } from 'hooks/useCopyData'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getPayinAccountsByCurrency } from 'store/selectors/user'

export const NGNAddFunds: FC = () => {
  const { t } = useTranslation()
  const currency = { slug: 'NGN', name: 'Nigerian Naira' }
  const payinAccounts = useSelector(getPayinAccountsByCurrency)
  const copyData = useCopyToClipboard()

  return (
    <>
      <Box>
        <Box fontWeight={600}>{t('wallet.addingInstructions.bank')}:</Box>
        <Flex gap={'1.14rem'}>
          <Box>{payinAccounts[currency?.slug][0].bankName}</Box>
          <Button variant='link' onClick={() => copyData(payinAccounts['NGN'][0].bankName)}>
            Copy
          </Button>
        </Flex>
      </Box>
      <Box>
        <Box fontWeight={600}>{t('wallet.addingInstructions.account')}:</Box>
        <Flex gap={'1.14rem'}>
          <Box>{payinAccounts[currency?.slug][0].accountNumber}</Box>
          <Button
            variant='link'
            onClick={() => copyData(payinAccounts[currency?.slug][0].accountNumber)}
          >
            Copy
          </Button>
        </Flex>
      </Box>
    </>
  )
}
