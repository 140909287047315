import { tabsAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tabsAnatomy.keys)

const homeScreen = definePartsStyle({
  tablist: {
    borderBottom: 'none',
  },
  tab: {
    borderBottom: '0.14rem solid #F1F0F1',
    pb: '0.85rem',
    fontSize: '1.28rem',
    color: 'brand.black.700',
    position: 'relative',
    _after: {
      display: 'none',
      position: 'absolute',
      content: '""',
      bottom: '-0.21rem',
      left: 0,
      width: 'full',
      height: '0.28rem',
      bg: 'brand.primary.purple',
      borderRadius: '0.28rem',
    },
    _selected: {
      color: 'brand.black.900',
      fontWeight: 800,
      _after: {
        display: 'block',
      },
    },
  },
})

const variants = {
  homeScreen,
}

export const Tabs = defineMultiStyleConfig({ variants })
