import { InfoIcon } from '@chakra-ui/icons'
import { Grid, GridItem } from '@chakra-ui/react'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const InfoPanel: FC = () => {
  const { t } = useTranslation()

  return (
    <Grid
      templateColumns={'1.14rem auto'}
      gap={'0.57rem'}
      padding={'1.14rem'}
      borderRadius='1.14rem'
      bg='brand.secondary.blueLight'
    >
      <GridItem>
        <InfoIcon fontSize={'1.14rem'} color={'brand.secondary.blue'} opacity={0.2}></InfoIcon>
      </GridItem>
      <GridItem fontSize={'1.14rem'} fontWeight={600} color={'brand.secondary.blue'}>
        {t('createDeal.infoText')}
      </GridItem>
    </Grid>
  )
}
