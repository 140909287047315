import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { setToken, logOut } from '../slices/auth'
import { Mutex } from 'async-mutex'
import { toast } from 'components/FSGToast'
import { TOAST_ERROR_TIMEOUT } from 'utils/constants'

export let hasCalledToast = false
export const updateHasCalledToast = (value: boolean) => {
  hasCalledToast = value
}
// create a new mutex
const mutex = new Mutex()
const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_BASE_URL}/`,
  prepareHeaders: (headers, api) => {
    const token = localStorage.getItem('token')
    // If we have a token set in state, let's assume that we should be passing it.
    if (token && api.endpoint !== null) {
      headers.set('authorization', `Bearer ${token}`)
    }
    return headers
  },
})
export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock()
  let result = await baseQuery(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire()
      try {
        // eslint-disable-next-line no-debugger
        const refreshToken = localStorage.getItem('refreshToken')
        const headers = new Headers()
        headers.append('Authorization', `Bearer ${refreshToken}`)
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/auth/users/refresh-token`,
          {
            method: 'POST',
            headers,
          },
        )
        const refreshResult = await response.json()
        if (refreshResult.accessToken && refreshResult.refreshToken) {
          api.dispatch(setToken(refreshResult.accessToken as string))
          localStorage.setItem('token', refreshResult.accessToken)
          localStorage.setItem('refreshToken', refreshResult.refreshToken)

          // retry the initial query
          result = await baseQuery(args, api, extraOptions)
        } else {
          if (localStorage.getItem('token') && localStorage.getItem('refreshToken')) {
            localStorage.removeItem('token')
            localStorage.removeItem('refreshToken')

            if (!hasCalledToast) {
              toast({
                title: 'Auth error',
                description: 'You have been logged out, please login again',
                status: 'error',
                duration: TOAST_ERROR_TIMEOUT,
                isClosable: true,
                position: 'top',
              })
              updateHasCalledToast(true)
            }
          }
          api.dispatch(logOut())
          api.dispatch({ type: 'store/reset' })
        }
      } finally {
        // release must be called once the mutex should be released again.
        release()
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock()
      result = await baseQuery(args, api, extraOptions)
    }
  }
  return result
}
