import { Box, Flex } from '@chakra-ui/react'
import React, { FC } from 'react'
import bg from 'assets/images/sign-up-bg.png'
import { SignUpForm } from './SignUpForm'
import { AuthLayout, FSGLink, FSGLogoText } from 'components'
import { useTranslation } from 'react-i18next'

const SignUp: FC = () => {
  const { t } = useTranslation()
  return (
    <AuthLayout bg={bg}>
      <Box textStyle='h1' color='brand.black.900' fontWeight={800} mb='1.71rem'>
        {t('signUp.title')}
      </Box>
      <Box fontSize='lg' mb='1.71rem'>
        {t('signUp.description')}
      </Box>
      <SignUpForm></SignUpForm>
      <Box fontSize='md' fontWeight={400} fontStyle='normal' color={'brand.black.700'}>
        {t('signUp.agreement')}{' '}
        <FSGLink to='/terms-and-conditions'>{t('signUp.termsOfService')}</FSGLink>{' '}
        {t('general.and')} <FSGLink to='/privacy-policy'>{t('signUp.privacyPolicy')}</FSGLink>
      </Box>
    </AuthLayout>
  )
}

export default SignUp
