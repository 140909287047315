import { Box, chakra, Skeleton, Stack, useDisclosure, Spinner, Image } from '@chakra-ui/react'
import { createColumnHelper, SortingState } from '@tanstack/react-table'
import { DataTable } from 'components/DataTable'
import i18n from 'i18n'
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { MarketplaceDeal } from 'types'
import { currencyFormat } from 'utils/currencyFormat'
import { FilterButton } from '../../../components/FilterButton'
import { UserRenderer } from './UserRenderer'
import { ViewDetails } from './ViewDetails'
import { useDispatch, useSelector } from 'react-redux'
import { dealsFlatSelector } from 'store/selectors/deals'
import { FilterPanel } from './FilterPanel'
import { LabelRenderer } from './LabelRenderer'
import { pairsBySlugSelector, supportedCurrenciesSelector } from 'store/selectors/currencies'
import { useGetMarketplaceDealsMutation } from 'store/services/user.api'
import { ExchangeRateRenderer } from './ExchangeRateRenderer'
import { ViewDealModal } from '../ViewDealModal'
import { resetDealsState } from 'store/slices/deals'
import noDataImage from 'assets/images/no-records.svg'
import { useInfiniteScroll } from 'hooks/useInfiniteScroll'
import { MobileRenderer } from './MobileRenderer'
import { ScrollContext } from 'pages/MainLayout/ScrollContext'
import { userAccountIsSetup } from 'store/selectors/user'

const columnHelper = createColumnHelper<MarketplaceDeal>()

const columns = (onViewClick: (deal: MarketplaceDeal) => void) => {
  return [
    columnHelper.accessor(
      (props) => {
        if (!props) return null
        return {
          rate: props.rate,
          toCurrency: props.toCurrencySlug,
          fromCurrency: props.fromCurrencySlug,
        }
      },
      {
        id: 'rate',
        header: i18n.t('tables.mainDeals.headers.exchangeRate') as string,
        cell: (props) => {
          const value = props.getValue()
          return value ? (
            <ExchangeRateRenderer {...value} />
          ) : (
            <Skeleton height={'1.42rem'}></Skeleton>
          )
        },
        enableSorting: false,
        enableMultiSort: false,
      },
    ),
    columnHelper.accessor(
      (props) => {
        if (!props) return null
        return { amount: props.amount, fromCurrency: props.fromCurrencySlug, sold: props.totalSold }
      },
      {
        cell: (props) => {
          const value = props.getValue()

          return value ? (
            `${currencyFormat(value.amount - +value.sold)} ${value.fromCurrency}`
          ) : (
            <Skeleton height={'1.42rem'}></Skeleton>
          )
        },
        id: 'amount',
        header: i18n.t('tables.mainDeals.headers.amount') as string,
        enableSorting: false,
        enableMultiSort: false,
      },
    ),
    columnHelper.accessor(
      (props) => {
        return props ? props.rank : null
      },
      {
        cell: (props) => {
          const value = props.getValue()

          return value ? (
            <LabelRenderer label={value}></LabelRenderer>
          ) : (
            <Skeleton height={'1.42rem'}></Skeleton>
          )
        },
        id: 'rank',
        header: i18n.t('tables.mainDeals.headers.rank') as string,
        enableSorting: false,
        enableMultiSort: false,
        size: 190,
      },
    ),
    columnHelper.accessor(
      (props) => {
        return props
          ? {
              username: props.displayName,
              creatorId: props.creatorId,
            }
          : null
      },
      {
        cell: (props) => {
          const value = props.getValue()

          return value ? (
            <UserRenderer username={value.username} creatorId={value.creatorId}></UserRenderer>
          ) : (
            <Skeleton height={'1.42rem'}></Skeleton>
          )
        },
        id: 'offered',
        header: i18n.t('tables.mainDeals.headers.offeredBy') as string,
        enableSorting: false,
      },
    ),

    columnHelper.accessor((props) => props, {
      id: 'action',
      cell: (props) => {
        return props.getValue() ? (
          <ViewDetails
            onClick={() => onViewClick(props.getValue())}
            deal={props.getValue()}
          ></ViewDetails>
        ) : (
          <Skeleton height={'1.42rem'}></Skeleton>
        )
      },
      header: '',
      enableSorting: false,
    }),
  ]
}

export const DealsTable: FC = () => {
  const scrollContext = React.useContext(ScrollContext)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const dealsList = useSelector(dealsFlatSelector)
  const isAccountSetup = useSelector(userAccountIsSetup)
  const params = useParams<{ currency: string }>()

  const [filters, setFilters] = useState<
    | {
        amountRange?: number[]
        exchangeRate?: number
      }
    | undefined
  >()
  const appliedFiltersCount = useMemo(() => {
    if (!filters) return 0
    return Object.values(filters).filter((val) => !!val).length
  }, [filters])

  const pageRef = useRef<number>(1)
  const [sorting, setSorting] = useState<SortingState>()
  const [activeDeal, setActiveDeal] = useState<MarketplaceDeal | null>(null)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const pairsBySlug = useSelector(pairsBySlugSelector)
  const pair = useMemo(() => {
    if (!pairsBySlug[params.currency || 'CAD']) return null
    return pairsBySlug[params.currency || 'CAD'][0]
  }, [pairsBySlug, params.currency])
  if (!pair) {
    return <></>
  }

  const [fetchMarketPlaceDeals, { isLoading, data }] = useGetMarketplaceDealsMutation()

  const handleScroll = useInfiniteScroll<{ deals: MarketplaceDeal[] }>(
    pageRef,
    isLoading,
    data,
    () => {
      fetchMarketPlaceDeals({
        page: pageRef.current,
        sorting,
        pairId: pair.id,
      })
    },
  )

  useEffect(() => {
    //resetDealsData
    dispatch(resetDealsState())
    pageRef.current = 1
    fetchMarketPlaceDeals({
      page: pageRef.current,
      sorting,
      pairId: pair.id,
      filters,
    })
  }, [params, sorting, filters])

  const handleViewClick = useCallback((deal: MarketplaceDeal) => {
    setActiveDeal(deal)
    onOpen()
  }, [])

  useEffect(() => {
    setFilters(undefined)
  }, [params.currency])

  useEffect(() => {
    scrollContext.addHandler?.('mainPage', handleScroll)
    return () => scrollContext.removeHandler?.('mainPage')
  }, [handleScroll])

  return (
    <>
      <Box>
        <chakra.p
          display={{ base: 'none', md: 'block' }}
          fontSize={'1.28rem'}
          color='brand.black.700'
          mb='2.28rem'
        >
          {t('mainPage.tableTitle', { currency: params.currency })}
        </chakra.p>
        <Box mb='2.28rem'>
          <FilterButton appliedFiltersCount={appliedFiltersCount}>
            <FilterPanel onFilter={(filter) => setFilters(filter)}></FilterPanel>
          </FilterButton>
        </Box>

        <DataTable
          height={{
            base: 'none',
            lg: `calc(100vh - ${21.2 + (isAccountSetup ? 0 : 6.28)}rem)`,
          }}
          columns={columns(handleViewClick)}
          data={dealsList}
          onScroll={handleScroll}
          isFetching={isLoading}
          firstDealClass='marketPlaceDeal'
          noDataComponent={
            <Stack spacing={'1.14rem'}>
              <Image src={noDataImage}></Image>
              <Box color='brand.primary.purple' fontSize={'1.14rem'}>
                No deals found
              </Box>
            </Stack>
          }
          mobileRenderer={(data: MarketplaceDeal) => (
            <MobileRenderer data={data} onViewClick={handleViewClick}></MobileRenderer>
          )}
          onSorting={(sorting) => {
            setSorting(sorting)
          }}
        />
      </Box>
      {activeDeal && (
        <ViewDealModal
          isOpen={isOpen}
          onClose={() => {
            onClose()
            setActiveDeal(null)
          }}
          deal={activeDeal}
        ></ViewDealModal>
      )}
    </>
  )
}
