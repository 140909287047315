import { Box, Flex, SystemStyleObject } from '@chakra-ui/react'
import React, { FC, useCallback } from 'react'
import { MailIcon, PhoneIcon } from 'assets/icons'
import { FSGSwitch } from 'components'
import { useSelector } from 'react-redux'
import { notificationSettingsSelector } from 'store/selectors/user'
import { useUpdateNotificationSettingsMutation } from 'store/services/user.api'
import { SettingsLayout } from '../common/SettingsLayout'
import { useTranslation } from 'react-i18next'

const panelSx: SystemStyleObject = {
  '.setting': {
    borderTop: 'solid 0.07rem',
    borderColor: 'brand.black.100',
  },
  '.setting:first-of-type': {
    borderTop: 'none',
  },
  '.setting:last-child': {
    borderBottom: 'none',
  },
  position: 'relative',
}

export const Notifications: FC = () => {
  const { t } = useTranslation()
  const [updateNotificationSettings, { isLoading }] = useUpdateNotificationSettingsMutation()
  const notificationSettings = useSelector(notificationSettingsSelector)
  const handleSwitchChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      await updateNotificationSettings({
        ...notificationSettings,
        [e.target.name]: e.target.checked,
      })
    },
    [notificationSettings],
  )

  return (
    <SettingsLayout
      title={t('settings.notifications.title')}
      subTitle={t('settings.notifications.description')}
    >
      <div>
        <Box
          overflow={'hidden'}
          boxShadow='0 0 5.14rem rgba(15, 3, 30, 0.08)'
          borderRadius={'1.14rem'}
          className='settingsContainer'
          sx={panelSx}
        >
          <Flex
            height={'5.14rem'}
            justifyContent='space-between'
            alignItems={'center'}
            px='1.71rem'
            className='setting'
          >
            <Flex alignItems={'center'} gap='0.57rem'>
              <Flex padding='0.57rem' borderRadius={'0.57rem'} bg='brand.black.100'>
                <MailIcon fill='#524A5D' width='1.14rem' height='1.14rem' />
              </Flex>
              <Box fontSize={'1.14rem'} color='brand.black.900' fontWeight={500}>
                {t('settings.notifications.email')}
              </Box>
            </Flex>
            <FSGSwitch
              withText
              isDisabled={isLoading}
              isChecked={notificationSettings.emailEnabled}
              name='emailEnabled'
              onChange={handleSwitchChange}
            />
          </Flex>
          <Flex
            height={'5.14rem'}
            justifyContent='space-between'
            alignItems={'center'}
            px='1.71rem'
            className='setting'
          >
            <Flex alignItems={'center'} gap='0.57rem'>
              <Flex padding='0.57rem' borderRadius={'0.57rem'} bg='brand.black.100'>
                <PhoneIcon fill='#524A5D' width='1.14rem' height='1.14rem' />
              </Flex>
              <Box fontSize={'1.14rem'} color='brand.black.900' fontWeight={500}>
                {t('settings.notifications.sms')}
              </Box>
            </Flex>
            <FSGSwitch
              withText
              isDisabled={isLoading}
              name='smsEnabled'
              isChecked={notificationSettings.smsEnabled}
              onChange={handleSwitchChange}
            />
          </Flex>
        </Box>
      </div>
    </SettingsLayout>
  )
}
