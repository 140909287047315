import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Tab,
  TabList,
  Tabs,
  useDisclosure,
  useBreakpointValue,
} from '@chakra-ui/react'
import { CurrencyIcons } from 'assets/icons/currencyIcons'
import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link, Outlet, useLocation } from 'react-router-dom'
import { getWalletsDictsSelector, userAccountIsSetup } from 'store/selectors/user'
import { useGetPayinAccountsQuery } from 'store/services/user.api'
import { AddFunds } from './AddFunds'
import { CurrencyCard } from './CurrencyCard'
import { WithdrawFundsModal } from './WithdrawFundsModal'

const links = [
  { title: 'Wallet transactions', link: 'wallet-transactions', shortName: 'Wallet' },
  { title: 'Deals transactions', link: 'deals-transactions', shortName: 'Deals' },
]

export const Wallet: FC = () => {
  const { t } = useTranslation()
  const {
    isOpen: isWithdrawOpen,
    onClose: onWithdrawClose,
    onOpen: onWithdrawOpen,
  } = useDisclosure()
  const {
    isOpen: isAddFundsOpen,
    onClose: onAddFundsClose,
    onOpen: onAddFundsOpen,
  } = useDisclosure()

  const route = useLocation()

  const tabIndex = useMemo(() => {
    return links.findIndex((link) => route.pathname.includes(link.link))
  }, [route])

  useGetPayinAccountsQuery()

  const wallets = useSelector(getWalletsDictsSelector)
  const isAccountSetup = useSelector(userAccountIsSetup)
  const size = useBreakpointValue({ base: 'mobile', lg: 'desktop' }, { ssr: false })

  return (
    <Grid templateRows={'min-content min-content max-content min-content'} maxH='full' maxW='full'>
      <Flex justifyContent={'space-between'} mb='2.28rem' direction={['column', 'row']}>
        <Box textStyle={'h1'} fontWeight={800} mb={['1.71rem', 0]}>
          {t('wallet.title')}
        </Box>
        <Grid gap={['1.28rem']} templateColumns={['auto auto']}>
          <Button onClick={onWithdrawOpen} variant={'outline'} isDisabled={!isAccountSetup}>
            {t('wallet.withdrawFunds')}
          </Button>
          <Button onClick={onAddFundsOpen} isDisabled={!isAccountSetup}>
            {t('wallet.addFunds')}
          </Button>
        </Grid>
      </Flex>
      <Grid templateColumns={{ base: '1fr', md: '1fr 1fr' }} gap='2.28rem' rowGap={'1.14rem'}>
        {Object.keys(wallets).map((key) => (
          <GridItem key={wallets[key][0].id}>
            <CurrencyCard
              amount={+wallets[key][0].bookBalance}
              availableBalance={+wallets[key][0].availableBalance}
              currency={wallets[key][0].currency}
            >
              {React.createElement(CurrencyIcons[wallets[key][0].currency.slug as 'NGN' | 'CAD'], {
                width: '3.5rem',
                height: '2.28rem',
              })}
            </CurrencyCard>
          </GridItem>
        ))}
      </Grid>
      <Tabs
        index={tabIndex}
        variant='homeScreen'
        isFitted={size !== 'desktop'}
        mt={{ base: '2.28rem', md: '1.42rem' }}
      >
        <TabList width={'full'}>
          {links.map((type, index) => (
            <Tab key={index}>
              <Box as={Link} to={type.link} whiteSpace='nowrap'>
                {size === 'desktop' ? type.title : type.shortName}
              </Box>
            </Tab>
          ))}
        </TabList>
      </Tabs>
      <Box pt={'2.28rem'} h='full'>
        <Outlet></Outlet>
      </Box>
      {isWithdrawOpen && (
        <WithdrawFundsModal isOpen={isWithdrawOpen} onClose={onWithdrawClose}></WithdrawFundsModal>
      )}
      <AddFunds isOpen={isAddFundsOpen} onClose={onAddFundsClose}></AddFunds>
    </Grid>
  )
}
