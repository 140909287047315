import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import { AccordionButton, AccordionItem, Flex, Box, AccordionPanel } from '@chakra-ui/react'
import React from 'react'

export const FSGFilterAccordionItem: React.FC<{
  title: string
  children: React.ReactElement
  noOverflow?: boolean
}> = (props) => {
  return (
    <AccordionItem
      sx={{
        ...(props.noOverflow
          ? {
              '.chakra-collapse': {
                overflow: 'unset !important',
              },
            }
          : {}),
      }}
    >
      {({ isExpanded }) => (
        <>
          <AccordionButton w='full' p='0'>
            <Flex alignItems={'center'} justifyContent='space-between' w='full' py='1.14rem'>
              <Box color='brand.black.900' fontWeight={600} fontSize='1.14rem'>
                {props.title}
              </Box>
              <ChevronDownIcon
                fontSize='1.71rem'
                color={'brand.primary.purple'}
                transform={`rotate(${isExpanded ? '180deg' : '0deg'})`}
                transition='all 0.2s cubic-bezier(0.42, 0, 0.08, 1.02)'
              />
            </Flex>
          </AccordionButton>
          <AccordionPanel px='0 !important'>
            <Box>{props.children}</Box>
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  )
}
