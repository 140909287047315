import { Box, Button, Stack, Flex, Image } from '@chakra-ui/react'
import React, { FC } from 'react'
import { TooltipRenderProps } from 'react-joyride'
import image from 'assets/images/bullseye.svg'
import { useUpdateUserPersonalInfoMutation } from 'store/services/user.api'
import { useSelector } from 'react-redux'
import { userInfoSelector } from 'store/selectors/user'
import { AmplitudeEvents, useTrackAction } from '../AmplitudeProvider'

export const JoyrideTooltip: FC<TooltipRenderProps> = ({
  step,
  skipProps,
  backProps,
  index,
  primaryProps,
  continuous,
  isLastStep,
  tooltipProps,
}) => {
  const track = useTrackAction()
  const [updateUserProfile] = useUpdateUserPersonalInfoMutation()
  const userProfile = useSelector(userInfoSelector)

  return (
    <div {...tooltipProps}>
      <Stack p={'1.14rem'} bg='white' width={'20rem'} borderRadius='1.14rem'>
        {JSON.stringify(step)}
        <Flex justifyContent={'space-between'} alignItems='center'>
          <Image src={image} alt='Tips icon'></Image>
          <Button variant={'link'} color={'brand.primary.purple'} {...skipProps}>
            Skip
          </Button>
        </Flex>
        <Box fontSize='1.28rem' color={'brand.black.900'} fontWeight='800'>
          Tips
        </Box>
        <Stack>
          <Box fontSize='1.28rem' color={'brand.black.900'} fontWeight='800'>
            {step.title}
          </Box>

          <Box>{step.content}</Box>
        </Stack>
        <Flex justifyContent={'flex-end'} gap='1.28rem' pt='1.14rem'>
          {index > 0 && (
            <Button variant={'outline'} {...backProps}>
              Back
            </Button>
          )}
          {continuous && (
            <Button
              variant={'solid'}
              {...primaryProps}
              onClick={async (e) => {
                primaryProps.onClick(e)
                if (isLastStep) {
                  track?.(AmplitudeEvents.FINISH_TUTORIAL)
                  if (!userProfile?.hasFinishedTour) {
                    await updateUserProfile({
                      ...userProfile,
                      hasFinishedTour: true,
                    })
                  }
                }
              }}
            >
              {isLastStep ? 'Finish' : 'Next'}
            </Button>
          )}
        </Flex>
      </Stack>
    </div>
  )
}
