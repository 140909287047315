import { chakra } from '@chakra-ui/react'
import { useDealExchangeRate } from 'hooks'
import React, { FC } from 'react'
import { DealCurrency } from 'types'
import { exchangeFormat } from 'utils/currencyFormat'

interface Props {
  toCurrency: DealCurrency
  fromCurrency: DealCurrency
  rate: number
}

export const ExchangeRate: FC<Props> = ({ toCurrency, fromCurrency, rate }) => {
  const resultRate = useDealExchangeRate(toCurrency.slug, fromCurrency.slug, rate)

  return <chakra.span>{resultRate}</chakra.span>
}
