import { Avatar, Box, Flex } from '@chakra-ui/react'
import { FSGAvatar } from 'components/FSGAvatar'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { userInfoSelector } from 'store/selectors/user'

export const UserBlock: FC = () => {
  const userInfo = useSelector(userInfoSelector)

  return (
    <Flex
      p='0.85rem 1.14rem'
      w='full'
      alignItems={'center'}
      justifyContent='flex-start'
      gap={'0.85rem'}
    >
      <FSGAvatar text={userInfo?.name || ''} />
      <Box color='white' fontSize='1.14rem' fontWeight={800}>
        {userInfo?.name}
      </Box>
    </Flex>
  )
}
