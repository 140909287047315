import { Stack } from '@chakra-ui/react'
import React, { FC } from 'react'
import { links } from '../common/links'
import { NavigationLink } from '../common/NavigationLink'
import { HowToUseLink } from './HowToUseLink'

export const Navigation: FC = () => {
  return (
    <Stack as='nav'>
      {links.map((link, index) =>
        link.to ? (
          <React.Fragment key={index}>
            <NavigationLink {...link} />
          </React.Fragment>
        ) : (
          <React.Fragment key={index}>
            <HowToUseLink {...link}></HowToUseLink>
          </React.Fragment>
        ),
      )}
    </Stack>
  )
}
