import { useDisclosure } from '@chakra-ui/react'
import { toast } from 'components/FSGToast'
import { useRefetchKYC } from 'hooks'
import { KYCModal } from 'pages/Settings/PersonalInfo/KYCModal'
import React, { FC, PropsWithChildren, useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootStore } from 'store'
import { kycStatusSelector } from 'store/selectors/user'

interface ModalsContext {
  kycOnOpen?: () => void
  kycOnClose?: () => void

  logoutOnOpen?: () => void
  logoutOnClose?: () => void
}

export const ModalsContext = React.createContext<ModalsContext>({})

export const ModalsProvider: FC<PropsWithChildren> = ({ children }) => {
  const interval = React.useRef<any>(null)
  const refetchData = useRefetchKYC()
  const hasCompletedKyc = useSelector((state: RootStore) => state.user.user?.hasCompletedKyc)
  const kycStatus = useSelector(kycStatusSelector)

  const { onOpen: kycOnOpen, isOpen: isKycOpen, onClose: kycOnClose } = useDisclosure()
  const { onOpen: logoutOnOpen, isOpen: isLogoutOpen, onClose: logoutOnClose } = useDisclosure()

  useEffect(() => {
    if (interval.current) clearInterval(interval.current)
    if (kycStatus && 'code' in kycStatus && kycStatus.code !== 'KYC_REQUIRED' && !hasCompletedKyc) {
      interval.current = setInterval(() => {
        refetchData?.()
      }, 5000)
    }
    if (
      kycStatus &&
      'code' in kycStatus &&
      (kycStatus.code === 'KYC_REQUIRED' || hasCompletedKyc)
    ) {
      clearInterval(interval.current)
    }
  }, [kycStatus])

  return (
    <ModalsContext.Provider
      value={{
        kycOnOpen,
        kycOnClose,

        logoutOnOpen,
        logoutOnClose,
      }}
    >
      {children}
      <KYCModal
        isOpen={isKycOpen}
        onSuccess={async () => {
          toast({
            status: 'success',
            title: 'KYC verification',
            description: 'KYC verification process has been started',
            duration: 2000,
            isClosable: true,
            position: 'top',
          })
          await refetchData?.()
          kycOnClose()
        }}
        onClose={kycOnClose}
        refetchData={refetchData}
      ></KYCModal>
    </ModalsContext.Provider>
  )
}

export const useKYCContext = () => {
  return useContext(ModalsContext)
}

export const useOpenKycModal = () => {
  const { kycOnOpen } = useContext(ModalsContext)
  return kycOnOpen
}
