import { AddIcon, InfoIcon, WarningIcon } from '@chakra-ui/icons'
import { Box, Button, Flex, Grid, GridItem, useDisclosure } from '@chakra-ui/react'
import { SettingsLayout } from 'pages/Settings/common/SettingsLayout'
import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { hasCompletedKyc, userAccountsSelector } from 'store/selectors/user'
import { Account } from 'types'
import { NGNAccount } from './NGNAccount'
import { PayoutsList } from './PayoutsList'
import { EditCreateModal } from './EditCreateModal'
import { DeleteAccountModal } from './DeleteAccountModal'
import { CADAccount } from './CADAccount'
import { Link } from 'react-router-dom'
import { useOpenKycModal } from 'pages/MainLayout/ModalsProvider'

const AccountRenderers = {
  NGN: NGNAccount,
  CAD: CADAccount,
}

export const PayoutAccounts = () => {
  const { t } = useTranslation()
  const accounts = useSelector(userAccountsSelector)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure()
  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null)
  const [createNew, setCreateNew] = useState<boolean>(false)
  const completedKyc = useSelector(hasCompletedKyc)
  const openKyc = useOpenKycModal()

  useEffect(() => {
    if (accounts.length) {
      setSelectedAccount(accounts[0])
    }
  }, [accounts])

  return (
    <SettingsLayout title='Accounts' subTitle='Manage your payout accounts'>
      <Flex
        borderRadius={'0.57rem'}
        bg={'brand.secondary.blueLight'}
        p={'1.14rem'}
        alignItems={'flex-start'}
      >
        <InfoIcon
          fontSize={'1.14rem'}
          color={'brand.secondary.blue'}
          opacity={0.8}
          mt={'0.35rem'}
        ></InfoIcon>
        <Box fontSize={'1.14rem'} fontWeight={600} color={'brand.secondary.blue'} ml={'0.57rem'}>
          {t('settings.payouts.infoText')}
        </Box>
      </Flex>
      {!completedKyc && (
        <Flex
          borderRadius={'0.57rem'}
          bg={'brand.secondary.yellowLight'}
          p={'1.14rem'}
          alignItems={'flex-start'}
        >
          <WarningIcon
            fontSize={'1.14rem'}
            color={'brand.secondary.yellow'}
            opacity={0.8}
            mt={'0.35rem'}
          ></WarningIcon>
          <Box
            fontSize={'1.14rem'}
            fontWeight={600}
            color={'brand.secondary.yellow'}
            ml={'0.57rem'}
          >
            <Trans
              i18nKey='settings.payouts.completeKYCInfoText'
              values={{
                kycLink: t('settings.payouts.kyc'),
              }}
              components={{
                1: (
                  <Box as={Link} to={'#'} onClick={openKyc} textDecoration={'underline'}>
                    KYC
                  </Box>
                ),
                2: <strong />,
              }}
            ></Trans>
          </Box>
        </Flex>
      )}
      <Grid templateColumns={{ base: '1fr', md: 'auto auto' }} gap={'1.14rem'}>
        <GridItem>
          <Grid
            templateColumns={'1fr'}
            templateAreas={{
              base: `"addButton"
                    "list"`,
              md: `"list"
                  "addButton"`,
            }}
            gap={{ base: '1.14rem', md: 0 }}
          >
            <GridItem area='list'>
              <PayoutsList
                onSelect={(account: Account | null) => {
                  setSelectedAccount(account)
                }}
                selectedAccount={selectedAccount}
                onEdit={() => {
                  setCreateNew(false)
                  onOpen()
                }}
                onDelete={() => {
                  onDeleteOpen()
                }}
              ></PayoutsList>
            </GridItem>
            <GridItem
              area='addButton'
              position={{ base: 'sticky', md: 'static' }}
              top={{ base: '-2.5rem', md: 0 }}
              px={{ base: '1.42rem', md: 0 }}
              pb={'1.14rem'}
              mb={'-1.14rem'}
              mx={{
                base: '-1.42rem',
                md: 0,
              }}
              bg={{
                base: 'white',
                md: 'transparent',
              }}
            >
              <Button
                variant={'addNewAccount'}
                h='5.71rem'
                w='full'
                borderRadius={'1.14rem'}
                border='solid 0.07rem'
                borderColor={'brand.primary.purple'}
                color={'brand.primary.purple'}
                mt='0.85rem'
                onClick={() => {
                  setCreateNew(true)
                  onOpen()
                }}
                disabled={!completedKyc}
              >
                <Flex gap='0.85rem'>
                  <AddIcon></AddIcon>
                  {t('buttons.addNewAccount')}
                </Flex>
              </Button>
            </GridItem>
          </Grid>
        </GridItem>
        {selectedAccount && (
          <GridItem display={{ base: 'none', md: 'block' }}>
            <Box boxShadow='0 0 5.14rem rgba(15, 3, 30, 0.08)' borderRadius={'1.14rem'} p='1.71rem'>
              {React.createElement(AccountRenderers[selectedAccount.currency.slug], {
                ...selectedAccount,
                onEdit: () => {
                  setCreateNew(false)
                  onOpen()
                },
                onDelete: () => {
                  onDeleteOpen()
                },
              })}
            </Box>
          </GridItem>
        )}
      </Grid>
      <EditCreateModal
        isOpen={isOpen}
        onClose={onClose}
        account={selectedAccount}
        createNew={createNew}
      ></EditCreateModal>
      <DeleteAccountModal
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        onConfirm={() => {
          onDeleteClose()
        }}
        selectedAccount={selectedAccount}
      ></DeleteAccountModal>
    </SettingsLayout>
  )
}
