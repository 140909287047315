import { Box, Flex, Modal, ModalContent, ModalOverlay, Image, Button, Grid } from '@chakra-ui/react'
import React, { FC, PropsWithChildren } from 'react'
import check from 'assets/images/checkGreen.png'

interface Props extends PropsWithChildren {
  isOpen: boolean
  title: string
  subTitle?: string
  closeButtonText?: string
  onClose: () => void
  onConfirm?: () => void
  hideButton?: boolean
}

export const ConfirmModal: FC<Props> = ({
  isOpen,
  onClose,
  title,
  onConfirm,
  hideButton,
  children,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered variant={'auto'}>
      <ModalOverlay />
      <ModalContent>
        <Flex alignItems='center' flexDirection={'column'}>
          <Image h='3.42rem' w='3.42rem' src={check}></Image>
          <Box textStyle='h2' fontWeight='800' mt='1.14rem'>
            {title}
          </Box>
          {children}

          <Grid templateColumns={'1fr 1fr'} gap={'1.14rem'} w='full'>
            <Button mt='1.14rem' variant={'outline'} onClick={onClose}>
              Cancel
            </Button>
            <Button mt='1.14rem' onClick={() => onConfirm && onConfirm()}>
              Confirm
            </Button>
          </Grid>
        </Flex>
      </ModalContent>
    </Modal>
  )
}
