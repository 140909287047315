import { WarningTwoIcon } from '@chakra-ui/icons'
import { Box, Flex } from '@chakra-ui/react'
import React from 'react'

export const WarningMessage: React.FC<{
  text?: string
  children?: JSX.Element[] | JSX.Element
}> = ({ text, children }) => {
  return (
    <Flex
      borderRadius={'1.14rem'}
      p='1.14rem'
      bg={'brand.secondary.yellowLight'}
      gap='0.85rem'
      alignItems='flex-start'
    >
      <WarningTwoIcon color={'brand.secondary.yellow'}></WarningTwoIcon>
      <Box color={'brand.secondary.yellow'}>
        {text} {children}
      </Box>
    </Flex>
  )
}
