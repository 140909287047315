// terms and conditions page
import { Box, Container } from '@chakra-ui/react'
import { BackLink } from 'components'
import { Header } from 'pages/MainLayout/Header'
import React from 'react'
import {
  LegalHeading,
  LegalLink,
  LegalOrderedList,
  LegalOrderedListItem,
  LegalParagraph,
  LegalSubHeading,
  LegalUnorderedList,
  LegalUnorderedListItem,
} from './Typography'

export const TermsAndConditions = () => {
  return (
    <div>
      <Header noLogin></Header>
      <Container maxW={'1182px'} py={'2rem'} px={'2rem'}>
        <BackLink></BackLink>
        <LegalHeading>FSG XCHANGE TERMS AND CONDITIONS</LegalHeading>
        <LegalSubHeading>1. Introduction</LegalSubHeading>
        <LegalParagraph>
          FSG Xchange Inc (FSGX) is a Canadian Money Services Business (MSB) registered and
          regulated by the Financial Transactions and Reports Analysis Centre of Canada (FINTRAC).
          In furtherance of our businesses and services, we own and operate internet websites and
          application software for the use of our customers. These terms and conditions govern the
          use of the FSGX website and the application software. By visiting our website and
          accessing the information, resources, services, products, and tools we provide, you
          understand and agree to accept and adhere to the following our terms and conditions, along
          with the terms and conditions stated in our Privacy Policy.
        </LegalParagraph>
        <LegalUnorderedList>
          <LegalUnorderedListItem>
            All visitors to our Company&apos;s website located at{' '}
            <LegalLink href='https://www.fsgxchange.com'>https://www.fsgxchange.com</LegalLink>; and
          </LegalUnorderedListItem>
          <LegalUnorderedListItem>
            Anyone who downloads or uses our Company&apos;s mobile application; and
          </LegalUnorderedListItem>
          <LegalUnorderedListItem>
            Anyone who enrolls for the Services of our Company.
          </LegalUnorderedListItem>
        </LegalUnorderedList>
        <LegalParagraph>
          Site Visitors, Application Users, and Customers are referred to collectively as “Users”.
          We reserve the right to change this User Agreement from time to time without notice by
          posting an updated copy of this User Agreement to the Site. You acknowledge and agree that
          it is your responsibility to review this User Agreement periodically to familiarize
          yourself with any modifications. Your continued use of this site after such modifications
          will constitute acknowledgment and agreement of the modified terms and conditions.
        </LegalParagraph>
        <LegalParagraph>
          By using this Website and the Application, you expressly indicate that you have read and
          understand these Terms and Conditions, and unconditionally agree to always abide by them.
        </LegalParagraph>

        <LegalSubHeading>2. Intellectual Property</LegalSubHeading>
        <LegalParagraph>
          All contents published and made available on our application are the properties of FSG
          Xchange and other third-party application&apos;s creators. This includes, but is not
          limited to images, text, logos, documents, downloadable files and anything that
          contributes to the composition of our application software.
        </LegalParagraph>

        <LegalSubHeading>3. Age Restrictions</LegalSubHeading>
        <LegalParagraph>
          The minimum age to use our application is 16 years old or the age of adulthood set by
          jurisdiction of the customer&apos;s residence. By using this Application users agree that
          they are over the prescribed age. FSGX do not assume any legal responsibility for false
          statements about age of any user.
        </LegalParagraph>

        <LegalSubHeading>4. User Contributions</LegalSubHeading>
        <LegalParagraph>
          Users may post the following information on our application:
        </LegalParagraph>
        <LegalUnorderedList>
          <LegalUnorderedListItem>Values for sale/buy.</LegalUnorderedListItem>
        </LegalUnorderedList>
        <LegalParagraph>
          By posting publicly on our application, you agree not to act illegally or violate these
          Terms and Conditions.
        </LegalParagraph>

        <LegalSubHeading>5. Accounts</LegalSubHeading>
        <LegalParagraph>
          When you create an account on our application, you agree to the following:
        </LegalParagraph>
        <LegalOrderedList>
          <LegalOrderedListItem>
            You are solely responsible for your account and the security and privacy of your
            account, including passwords or sensitive information attached to that account; and
          </LegalOrderedListItem>
          <LegalOrderedListItem>
            All personal information you provide to us through your account is up to date, accurate,
            and truthful and that you will update your personal information if it changes.
          </LegalOrderedListItem>
        </LegalOrderedList>
        <LegalParagraph>
          We reserve the right to suspend or terminate your account if you are using our application
          illegally or if you violate these Terms and Conditions.
        </LegalParagraph>

        <LegalSubHeading>6. Sale of Services</LegalSubHeading>
        <LegalParagraph>
          These Terms and Conditions govern the sale of services available on our application. The
          following services are available on our application:
        </LegalParagraph>
        <LegalUnorderedList>
          <LegalUnorderedListItem>
            Facilitation of Peer-to-Peer Canadian and Nigerian Currency Swaps.
          </LegalUnorderedListItem>
        </LegalUnorderedList>
        <LegalParagraph>
          These Terms and Conditions apply to all the services that are displayed on our application
          at the time you access it. All information, descriptions, or images that we provide about
          our services are as accurate as possible. However, we are not legally bound by such
          information, descriptions, or images as we cannot guarantee the accuracy of all services
          we provide. You agree to purchase services from our application at your own risk.
        </LegalParagraph>
        <LegalParagraph>
          We reserve the right to modify, reject or cancel your order whenever it becomes necessary.
          If we cancel your order and have already processed your payment, we will give you a refund
          equal to the amount you paid. You agree that it is your responsibility to monitor your
          payment instrument to verify receipt of any refund.
        </LegalParagraph>

        <LegalSubHeading>7. Canadian Dollar and Naira Swap Services</LegalSubHeading>
        <LegalParagraph>
          Our application allows Users to sell and buy Canadian Dollar and Nigerian Naira on
          mutually agreed terms by the Users. We do not assume any responsibility for the money
          users sell/buy on our application. However, if we are made aware that a user is violating
          these Terms and Conditions, we reserve the right to suspend or prohibit the user from
          continuous use of our application.
        </LegalParagraph>

        <LegalSubHeading>8. Funding Services</LegalSubHeading>
        <LegalParagraph>We accept the following payment methods on our application:</LegalParagraph>
        <LegalUnorderedList>
          <LegalUnorderedListItem>
            Direct Bank deposits to and from local banks; and{' '}
          </LegalUnorderedListItem>
          <LegalUnorderedListItem>Interac e-Transfer.</LegalUnorderedListItem>
        </LegalUnorderedList>
        <LegalParagraph>
          When you provide us with your payment information, you authorize our use of and access to
          the payment instrument you have chosen to use. By providing us with your payment
          information, you authorize us to charge the amount due to this payment instrument.
        </LegalParagraph>
        <LegalParagraph>
          If we believe your payment has violated any law or these Terms and Conditions, we reserve
          the right to cancel or reverse your transaction.
        </LegalParagraph>

        <LegalSubHeading>9. Consumer Protection Law</LegalSubHeading>
        <LegalParagraph>
          Where the Consumer Product Warranty and Liability Act, or any other consumer protection
          legislation in your jurisdiction applies and cannot be excluded, these Terms and
          Conditions will not limit your legal rights and remedies under that legislation. These
          Terms and Conditions will be read subject to the mandatory provisions of that legislation.
          If there is a conflict between these Terms and Conditions and that legislation, the
          mandatory provisions of the legislation will apply.
        </LegalParagraph>

        <LegalSubHeading>10. Limitation of Liability</LegalSubHeading>
        <LegalParagraph>
          <Box as='span' fontWeight={'bold'}>
            FSG Xchange Inc
          </Box>{' '}
          and its Owners, Directors, Officers, Agents, Employees, and affiliates will not be liable
          for any actions, claims, losses, damages, liabilities, and expenses including legal fees
          from your use of the Application.
        </LegalParagraph>

        <LegalSubHeading>11. Indemnity</LegalSubHeading>
        <LegalParagraph>
          Except where prohibited by law, by using this Application you expressly indemnify and hold
          harmless{' '}
          <Box as='span' fontWeight={'bold'}>
            FSG Xchange Inc
          </Box>{' '}
          and its Owners, Directors, Officers, Agents, Employees, and affiliates from any actions,
          claims, losses, damages, liabilities, and expenses including legal fees arising out of
          your use of our Application or your violation of these Terms and Conditions.
        </LegalParagraph>

        <LegalSubHeading>12. Applicable Law</LegalSubHeading>
        <LegalParagraph>These Terms and Conditions are governed by Canadian Laws.</LegalParagraph>

        <LegalSubHeading>13. Severability</LegalSubHeading>
        <LegalParagraph>
          If at any time any of the provisions set forth in these Terms and Conditions are found to
          be inconsistent or invalid under applicable laws, those provisions will be deemed void and
          will be removed from these Terms and Conditions. All other provisions will not be affected
          by the removal and the rest of these Terms and Conditions will still be considered valid.
        </LegalParagraph>

        <LegalSubHeading>14. Changes</LegalSubHeading>
        <LegalParagraph>
          These Terms and Conditions may be amended from time to time to maintain compliance with
          the law and to reflect any changes to the way we operate our application and the way we
          expect users to behave. We will notify users by email of changes to these Terms and
          Conditions or post a notice on our application.
        </LegalParagraph>

        <LegalSubHeading>15. Contact Details</LegalSubHeading>
        <LegalParagraph>Please contact us if you have any questions or concerns. </LegalParagraph>
        <LegalParagraph>
          Our contact details are as follows:{' '}
          <LegalLink href='mailto:support@fsgxchange.com'>support@fsgxchange.com</LegalLink>
        </LegalParagraph>
        <LegalParagraph>1st June, 2023</LegalParagraph>
      </Container>
    </div>
  )
}
