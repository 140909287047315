import { TriangleDownIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  InputProps,
  chakra,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverAnchor,
  PopoverBody,
} from '@chakra-ui/react'
import { CurrencyIcons } from 'assets/icons/currencyIcons'
import { get } from 'lodash'
import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { supportedCurrenciesSelector } from 'store/selectors/currencies'
import { Currency } from 'types'

type Props = InputProps & {
  label?: string
  onCurrencyChange?: (e: Currency) => void
}

export const CurrencySelector: FC<Props> = ({ label, onCurrencyChange, ...props }) => {
  const currencies = useSelector(supportedCurrenciesSelector)
  const [currency, setCurrency] = useState(() => currencies?.[0])
  const [isOpen, setOpen] = useState<boolean>(false)

  useEffect(() => {
    if (!currencies) return
    setCurrency(currencies[0])
    onCurrencyChange?.(currencies[0])
  }, [currencies])

  return (
    <Popover
      matchWidth
      isLazy
      isOpen={isOpen}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
    >
      <PopoverAnchor>
        <PopoverTrigger>
          <Flex
            alignItems={'center'}
            cursor={'pointer'}
            gap={'028rem'}
            height={'2.85rem'}
            borderRadius='0.57rem'
            w='14.28rem'
            justifyContent={'space-between'}
            border='solid 1px'
            borderColor='brand.black.200'
            px={4}
          >
            <Box>
              <Flex gap='0.28rem' alignItems={'center'}>
                {React.createElement(get(CurrencyIcons, currency?.slug) || React.Fragment)}
                <chakra.span fontSize='md' fontWeight='500'>
                  {currency?.slug}
                </chakra.span>
              </Flex>
            </Box>
            <Box fontSize={'0.85rem'}>
              <TriangleDownIcon></TriangleDownIcon>
            </Box>
          </Flex>
        </PopoverTrigger>
      </PopoverAnchor>
      <PopoverContent width='full'>
        <PopoverBody maxH={200} overflow='auto'>
          {currencies?.map((curr) => (
            <Flex
              gap='0.28rem'
              py='0.57rem'
              alignItems={'center'}
              key={curr.id}
              cursor={'pointer'}
              onClick={() => {
                setCurrency(curr)
                onCurrencyChange?.(curr)
                setOpen(false)
              }}
            >
              {React.createElement(get(CurrencyIcons, curr.slug) || React.Fragment)}
              <chakra.span fontSize={'md'} fontWeight='500'>
                {curr.slug}
              </chakra.span>
            </Flex>
          ))}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
