import { Box, Flex } from '@chakra-ui/react'
import React, { FC } from 'react'
import { Currency } from 'types'
import { currencyFormat } from 'utils/currencyFormat'

export const CurrencyCard: FC<{
  children: React.ReactElement
  amount: number
  availableBalance: number
  currency: Currency
}> = ({ children, amount, availableBalance, currency }) => {
  return (
    <Flex
      direction={'column'}
      justifyContent='center'
      alignItems={'center'}
      p={['1.14rem', '1.71rem']}
      borderRadius={'1.14rem'}
      boxShadow={'0 0 5.14rem rgba(15, 3, 30, 0.08)'}
    >
      <Box>{children}</Box>
      <Flex fontSize={['1.71rem', '2.28rem']} fontWeight={800} color='brand.black.900'>
        {currencyFormat(amount)}
      </Flex>
      <Box fontSize={{ base: '0.85rem', md: '1rem' }} color='brand.black.700'>
        Available balance: {currencyFormat(availableBalance)}
      </Box>
      <Box fontSize={'1.14rem'} color='brand.black.900'>
        {currency.name}
      </Box>
    </Flex>
  )
}
