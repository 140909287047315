import React, { FC, useEffect, useState, useMemo } from 'react'
import { passwordStrength } from 'check-password-strength'
import { Stack, Box, HStack, Grid, Flex } from '@chakra-ui/react'
import { CheckCircleIcon } from '@chakra-ui/icons'
import { CheckIcon } from 'assets/icons'
import { useTranslation } from 'react-i18next'

const MIN_LENGTH = 10

interface Props {
  password: string
}

export const PasswordStrengthIndicator: FC<Props> = ({ password }) => {
  const { t } = useTranslation()
  const [containsMap, setContainsMap] = useState<{
    number: boolean
    uppercase: boolean
    symbol: boolean
  }>({ number: false, uppercase: false, symbol: false })
  const [lengthMatch, setLengthMatch] = useState<boolean>(false)
  const [strength, setStrength] = useState<string>('Too Weak')
  const [strengthId, setStrengthId] = useState<number>(0)

  useEffect(() => {
    const { contains, value, length, id } = passwordStrength(password)
    setContainsMap({
      number: contains.includes('number'),
      uppercase: contains.includes('uppercase'),
      symbol: contains.includes('symbol'),
    })
    setLengthMatch(MIN_LENGTH <= length)
    setStrength(value)
    setStrengthId(id)
  }, [password])

  const strengthColor: string = useMemo(() => {
    const strengthColors: Record<string, string> = {
      'Too weak': 'brand.black.300',
      Weak: 'brand.secondary.red',
      Medium: 'brand.secondary.yellow',
      Strong: 'brand.secondary.green',
    }
    return strengthColors[strength] || strengthColors['Too weak']
  }, [strength])

  return (
    <Stack>
      <Grid templateColumns={'3fr 1fr'} gap='0.57rem' alignContent={'center'}>
        <Flex w='full' alignItems={'center'}>
          <Grid w='full' h='0.42rem' templateColumns={'1fr 1fr 1fr'} gap='0.28rem'>
            <Box
              borderRadius={'0.71rem'}
              bg={strengthId > 0 ? strengthColor : 'brand.black.300'}
            ></Box>
            <Box
              borderRadius={'0.71rem'}
              bg={strengthId > 1 ? strengthColor : 'brand.black.300'}
            ></Box>
            <Box
              borderRadius={'0.71rem'}
              bg={strengthId > 2 ? strengthColor : 'brand.black.300'}
            ></Box>
          </Grid>
        </Flex>
        <Flex justifyContent={'flex-end'} fontSize='1rem' fontWeight={600}>
          {strength}
        </Flex>
      </Grid>
      {/* <Stack mt='8px' p='16px' bg='brand.black.100' borderRadius={'16px'} spacing='12px'>
        <Box fontWeight={600}>{t('passwordStrength.title')}:</Box>
        <HStack>
          <CheckIcon fill={lengthMatch ? '#00A524' : '#C5C2C9'}></CheckIcon>
          <Box color={lengthMatch ? '#00A524' : '#0F031E'}>{t('passwordStrength.length')}</Box>
        </HStack>
        <HStack>
          <CheckIcon fill={containsMap.uppercase ? '#00A524' : '#C5C2C9'}></CheckIcon>
          <Box color={containsMap.uppercase ? '#00A524' : '#0F031E'}>
            {t('passwordStrength.upperCase')}
          </Box>
        </HStack>
        <HStack>
          <CheckIcon fill={containsMap.number ? '#00A524' : '#C5C2C9'}></CheckIcon>
          <Box color={containsMap.number ? '#00A524' : '#0F031E'}>
            {t('passwordStrength.numbers')}
          </Box>
        </HStack>
        <HStack>
          <CheckIcon fill={containsMap.symbol ? '#00A524' : '#C5C2C9'}></CheckIcon>
          <Box color={containsMap.symbol ? '#00A524' : '#0F031E'}>
            {t('passwordStrength.symbol')}
          </Box>
        </HStack>
      </Stack> */}
    </Stack>
  )
}
