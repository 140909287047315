import React from 'react'
import {
  DealsActiveIcon,
  DealsIcon,
  HomeActiveIcon,
  HomeIcon,
  HowToActiveIcon,
  HowToIcon,
  SettingsActiveIcon,
  SettingsIcon,
  WalletActiveIcon,
  WalletIcon,
} from 'assets/images/linkIcons'

export const links = [
  {
    linkImage: (size = 20) => <HomeIcon height={size} width={size} />,
    linkActiveImage: (size = 20) => <HomeActiveIcon height={size} width={size} />,
    to: '/main',
    linkName: 'links.home',
  },
  {
    linkImage: (size = 20) => <WalletIcon height={size} width={size} />,
    linkActiveImage: (size = 20) => <WalletActiveIcon height={size} width={size} />,
    to: '/wallet',
    linkName: 'links.wallet',
  },
  {
    linkImage: (size = 20) => <DealsIcon height={size} width={size} />,
    linkActiveImage: (size = 20) => <DealsActiveIcon height={size} width={size} />,
    to: '/deals',
    linkName: 'links.deals',
  },
  {
    linkImage: (size = 20) => <SettingsIcon height={size} width={size} />,
    linkActiveImage: (size = 20) => <SettingsActiveIcon height={size} width={size} />,
    to: '/settings',
    linkName: 'links.settings',
  },
  {
    linkImage: (size = 20) => <HowToIcon height={size} width={size} />,
    linkActiveImage: (size = 20) => <HowToActiveIcon height={size} width={size} />,
    linkName: 'links.howToUse',
  },
]
