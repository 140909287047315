import { Box, Button, Stack } from '@chakra-ui/react'
import { FSGLogoText } from 'components'
import { useLogout } from 'hooks'
import React, { FC } from 'react'
import { Balance } from './Balance'
import { LogoutButton } from './LogoutButton'
import { Navigation } from './Naviagation'
import { SetupAccount } from './SetupAccount'
import { UserBlock } from './UserBlock'

export const Drawer: FC = () => {
  const logout = useLogout()

  return (
    <Stack
      justifyContent={'space-between'}
      h='full'
      overflow={'auto'}
      width={'17.14rem'}
      bg='brand.secondary.purpleDark'
      px='1.14rem'
      pt='2.28rem'
      pb='1.7rem'
      borderRadius={'1.14rem'}
    >
      <Stack spacing='2.28rem'>
        <Stack
          position={'sticky'}
          top='0'
          spacing='2.28rem'
          bg='brand.secondary.purpleDark'
          zIndex={2}
        >
          <FSGLogoText
            logoVariant='navigation'
            justifyContent={'center'}
            display='flex'
          ></FSGLogoText>
          <UserBlock></UserBlock>
        </Stack>
        <Navigation></Navigation>
        <SetupAccount></SetupAccount>
        <Balance></Balance>
      </Stack>
      <LogoutButton onClick={logout} />
    </Stack>
  )
}

export default Drawer
