import { Avatar, HStack, Box } from '@chakra-ui/react'
import { FSGAvatar } from 'components/FSGAvatar'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { userInfoSelector } from 'store/selectors/user'

interface Props {
  username: string
  creatorId: string
}

export const UserRenderer: FC<Props> = ({ username, creatorId }) => {
  const userInfo = useSelector(userInfoSelector)
  return (
    <HStack gap={'0.57rem'}>
      <FSGAvatar text={username} />
      <Box>
        {username} {creatorId === userInfo?.id ? `(You)` : ''}
      </Box>
    </HStack>
  )
}
