import { chakra } from '@chakra-ui/react'
import { useDealExchangeRate } from 'hooks'
import React, { FC } from 'react'
import { DealCurrency } from 'types'
import { exchangeFormat } from 'utils/currencyFormat'

interface Props {
  toCurrency: string
  fromCurrency: string
  rate: number
}

export const ExchangeRateRenderer: FC<Props> = ({ toCurrency, fromCurrency, rate }) => {
  const resultRate = useDealExchangeRate(toCurrency, fromCurrency, rate)

  return <chakra.span>{resultRate}</chakra.span>
}
