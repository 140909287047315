import {
  Modal,
  ModalContent,
  ModalOverlay,
  Image,
  Box,
  Stack,
  Button,
  Grid,
} from '@chakra-ui/react'
import infoImage from 'assets/images/info.svg'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  isOpen: boolean
  isLoading: boolean
  onClose: () => void
  onConfirm: () => void
}

export const CreateDealConfirm: FC<Props> = ({ isOpen, isLoading, onClose, onConfirm }) => {
  const { t } = useTranslation()
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent minWidth={{ base: 'none', md: '37.85rem' }}>
        <Stack flexDirection={'column'} alignItems='center' spacing={'1.14rem'}>
          <Image src={infoImage} h='3.42rem' w='3.42rem' />
          <Box textAlign={'center'} textStyle='h2' fontWeight={800}>
            {t('createDeal.modal.confirm.title')}
          </Box>
          <Box textStyle={'cardSubtitle'} textAlign={'center'}>
            {t('createDeal.modal.confirm.text')}
          </Box>
        </Stack>
        <Grid gap={'2.14rem'} templateColumns={'1fr 1fr'} mt='2.28rem'>
          <Button width={'full'} variant={'outline'} onClick={onClose} isDisabled={isLoading}>
            {t('buttons.cancel')}
          </Button>
          <Button width={'full'} variant={'solid'} onClick={onConfirm} isLoading={isLoading}>
            {t('buttons.createDeal')}
          </Button>
        </Grid>
      </ModalContent>
    </Modal>
  )
}
