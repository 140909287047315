import { AddIcon, MinusIcon } from '@chakra-ui/icons'
import { AccordionButton, AccordionItem, AccordionPanel, Box, Flex } from '@chakra-ui/react'
import React, { FC } from 'react'
import { FAQ } from 'types'

interface Props {
  item: FAQ
}

export const FaqAccordionItem: FC<Props> = ({ item }) => {
  return (
    <AccordionItem>
      {({ isExpanded }) => (
        <>
          <AccordionButton w='full'>
            <Flex alignItems={'center'} justifyContent='space-between' w='full' py='1.42rem'>
              <Box color='brand.black.900' fontWeight={800}>
                {item.title}
              </Box>
              {isExpanded ? (
                <MinusIcon fontSize='0.85rem' color={'brand.primary.purple'} />
              ) : (
                <AddIcon fontSize='0.85rem' color={'brand.primary.purple'} />
              )}
            </Flex>
          </AccordionButton>
          <AccordionPanel py='1.14rem' px='0'>
            <Box>{item.content}</Box>
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  )
}
