import {
  Box,
  chakra,
  useBreakpointValue,
  Flex,
  Button,
  Tr,
  Td,
  useDisclosure,
  Stack,
  Image,
} from '@chakra-ui/react'
import { Cell, createColumnHelper, SortingState } from '@tanstack/react-table'
import { DataTable } from 'components/DataTable'
import i18n from 'i18n'
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { Deal, DealStatus, DealTransaction } from 'types'
import { currencyFormat } from 'utils/currencyFormat'
import { FilterButton } from '../../../components/FilterButton'
import {
  useDeleteDealMutation,
  useGetDealsQuery,
  useGetDealTransactionsByIdMutation,
  useUpdateDealMutation,
} from 'store/services/user.api'
import { useDispatch, useSelector } from 'react-redux'
import { Filter } from './components/Filter'
import { myDeals } from 'store/selectors/user'
import { ExchangeRate } from 'pages/HomePage/DealsTable/ExchangeRate'
import moment from 'moment'
import { InfoPopover } from './components/InfoPopover'
import { columns } from './components/columns'
import { MobileRenderer } from './components/MobileRenderer'
import { DeleteDealModal } from './components/DeleteDealModal'
import { EditDealModal } from './components/EditDealModal'
import { TransactionDetailsModal } from 'pages/Wallet/TransactionDetails'
import noDataImage from 'assets/images/no-records.svg'
import { useInfiniteScroll } from 'hooks/useInfiniteScroll'
import { resetMyDeals } from 'store/slices/user'
import { AmplitudeEvents, useTrackAction } from 'pages/MainLayout/AmplitudeProvider'

const NUMBER_OF_ELEMENTS = 20

const innerContentRenderers: Record<string, (data: DealTransaction) => JSX.Element> = {
  amount: (data: DealTransaction) =>
    data.reference.includes('SOL') ? (
      <Flex gap={'0.57rem'}>
        <chakra.p color={'brand.secondary.blue'} fontSize='1.14rem' fontWeight={500}>
          {currencyFormat(data.amount)} {data.deal.toCurrency.slug}
        </chakra.p>
        {/* <InfoPopover deal={data.deal}></InfoPopover> */}
      </Flex>
    ) : (
      <Flex gap={'0.57rem'}>
        <chakra.p color={'brand.secondary.blue'} fontSize='1.14rem' fontWeight={500}>
          {currencyFormat(data.amount)} {data.deal.fromCurrency.slug}
        </chakra.p>
        {/* <InfoPopover deal={data.deal}></InfoPopover> */}
      </Flex>
    ),
  sold: (data: DealTransaction) => (
    <chakra.p fontSize={'1.14rem'} color='brand.black.900'>
      {data.reference}
    </chakra.p>
  ),
  rate: (data: DealTransaction) => (
    <ExchangeRate
      fromCurrency={data.deal.fromCurrency}
      toCurrency={data.deal.toCurrency}
      rate={data.rate}
    ></ExchangeRate>
  ),
  date: (data: DealTransaction) => (
    <chakra.p fontSize={'1.14rem'} color='brand.black.900'>
      {moment(data.createdAt).format('MMM DD, YYYY')}
    </chakra.p>
  ),
  action: (data: DealTransaction) => (
    <Flex w={'full'} justifyContent='flex-end'>
      <Button variant={'link'} color='brand.primary.purple'>
        Details
      </Button>
    </Flex>
  ),
}

export const MyDealsTable: FC = () => {
  const { t } = useTranslation()
  const track = useTrackAction()
  const dispatch = useDispatch()
  const params = useParams<{ dealsStatus: string }>()

  const pageRef = useRef<number>(1)
  const [sorting, setSorting] = useState<SortingState>()
  const { data, isFetching, refetch } = useGetDealsQuery({
    page: pageRef.current,
    sorting,
    completed: params.dealsStatus !== 'in-progress',
  })
  const [getDealTransactions] = useGetDealTransactionsByIdMutation()
  const [deleteDeal] = useDeleteDealMutation()

  const dealsList = useSelector(myDeals)
  const totalItems = dealsList.length
  const scrollContainer = useRef<HTMLDivElement>(null)

  const [activeDeal, setActive] = useState<Deal | null>(null)
  const [activeTransaction, setActiveTransaction] = useState<DealTransaction | null>(null)

  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure()
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure()
  const {
    isOpen: isTransactionDetailsOpen,
    onOpen: onTransactionDetailsOpen,
    onClose: onTransactionDetailsClose,
  } = useDisclosure()

  const handleDelete = useCallback((deal: Deal) => {
    setActive(deal)
    onDeleteOpen()
  }, [])

  const handleEdit = useCallback((deal: Deal) => {
    setActive(deal)
    onEditOpen()
  }, [])

  const handleScroll = useInfiniteScroll<{ deals: Deal[] }>(pageRef, isFetching, data)

  useEffect(() => {
    dispatch(resetMyDeals())
    pageRef.current = 1
    refetch()
  }, [params.dealsStatus, sorting])

  useEffect(() => {
    handleScroll(scrollContainer.current)
  }, [handleScroll])

  return (
    <Box>
      <chakra.p
        display={'block'}
        fontSize={'1.28rem'}
        color='brand.black.700'
        my={['1.71rem', '2.28rem']}
      >
        {t(
          params.dealsStatus === 'in-progress' ? 'myDeals.progressTitle' : 'myDeals.completedTitle',
        )}
      </chakra.p>
      <Box
        sx={{
          pt: '2.28rem',
        }}
      >
        <DataTable
          height={{
            base: 'none',
            md: 'calc(100vh - 23.5rem)',
          }}
          boxSx={{
            overflow: {
              base: 'hidden',
              md: 'auto',
            },
          }}
          columns={columns(
            handleDelete,
            handleEdit,
            async (dealId: string) => await getDealTransactions({ dealId }),
          )}
          data={dealsList}
          onScroll={handleScroll}
          onSorting={setSorting}
          totalItemsCount={totalItems}
          isFetching={isFetching}
          noDataComponent={
            <Stack spacing={'1.14rem'}>
              <Image src={noDataImage} h='8rem'></Image>
              <Box color='brand.primary.purple' fontSize={'1.14rem'}>
                No deals found
              </Box>
            </Stack>
          }
          innerRowRenderer={(data: Deal, cells: Cell<Deal, unknown>[]) => {
            return (
              <>
                {data.transactions?.map((transaction, index) => (
                  <Tr className='inner-content' key={transaction.id || index}>
                    {cells.map((cell) => {
                      return (
                        <Td key={cell.id}>
                          <Box
                            className='content-box'
                            onClick={() => {
                              if (cell.column.id === 'action') {
                                setActiveTransaction(transaction)
                                onTransactionDetailsOpen()
                              }
                            }}
                          >
                            {innerContentRenderers[cell.column.id]?.(transaction) || ''}
                          </Box>
                        </Td>
                      )
                    })}
                  </Tr>
                ))}
                <Tr className='inner-content last'>
                  <Td colSpan={cells.length}>
                    <Box className='content-box'>
                      <chakra.p fontSize={['1rem']} color='brand.black.700' mb={'0.57rem'}>
                        Total remaining
                      </chakra.p>
                      <chakra.p fontSize={'1.42rem'} fontWeight={800}>
                        {currencyFormat(data.amount - data.totalSold)} {data.fromCurrency.slug}
                      </chakra.p>
                    </Box>
                  </Td>
                </Tr>
              </>
            )
          }}
          mobileRenderer={(data: Deal | any) => (
            <MobileRenderer
              data={data}
              onDelete={() => {
                handleDelete(data)
              }}
              onEdit={() => handleEdit(data)}
              onExpand={async (dealId: string) => {
                await getDealTransactions({ dealId })
              }}
              onDetailsClick={(transaction: DealTransaction) => {
                setActiveTransaction(transaction)
                onTransactionDetailsOpen()
              }}
            ></MobileRenderer>
          )}
        />
      </Box>

      <DeleteDealModal
        isOpen={isDeleteOpen}
        onConfirm={() => {
          activeDeal && deleteDeal({ dealId: activeDeal.id })
          track?.(AmplitudeEvents.DELETE_DEAL)
          setActive(null)
          onDeleteClose()
        }}
        onClose={() => {
          setActive(null)
          onDeleteClose()
        }}
      ></DeleteDealModal>
      <EditDealModal
        deal={activeDeal}
        isOpen={isEditOpen}
        onClose={() => {
          setActive(null)
          onEditClose()
        }}
      ></EditDealModal>

      {activeTransaction && (
        <TransactionDetailsModal
          isOpen={isTransactionDetailsOpen}
          onClose={onTransactionDetailsClose}
          transaction={activeTransaction}
        ></TransactionDetailsModal>
      )}
    </Box>
  )
}
