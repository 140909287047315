import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { PaginationResponse } from 'types'

export const useInfiniteScroll = <T>(
  page: MutableRefObject<number>,
  isFetching: boolean,
  data: PaginationResponse<T> | undefined,
  fetchData?: () => void,
): ((refElement: HTMLDivElement | null) => void) => {
  const scrollContainer = useRef<HTMLDivElement>(null)

  const handleScroll = useCallback(
    (refElement: HTMLDivElement | null) => {
      if (refElement) {
        const { scrollHeight, scrollTop, clientHeight } = refElement
        if (
          scrollHeight - scrollTop - clientHeight < 300 &&
          !isFetching &&
          (data?.meta.lastPage || 0) > page.current
        ) {
          page.current = page.current + 1
          fetchData?.()
        }
      }
    },
    [page.current, isFetching, data],
  )

  useEffect(() => {
    handleScroll(scrollContainer.current)
  }, [handleScroll])

  return handleScroll
}
