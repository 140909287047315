import React from 'react'
import { SettingsLayout } from '../common/SettingsLayout'
import howToUse from 'assets/images/how-to-use.svg'
import terms from 'assets/images/terms.png'
import tips from 'assets/images/tips.svg'
import { DocumentCard } from './DocumentCard'
import { useTranslation } from 'react-i18next'

const documentsList = [
  {
    image: howToUse,
    title: 'settings.documents.howToUse.title',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra nunc egestas in.',
    docLink: '#',
  },
  {
    image: terms,
    title: 'settings.documents.terms.title',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra nunc egestas in.',
    docLink: '#',
  },
  {
    image: tips,
    title: 'settings.documents.tips.title',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra nunc egestas in.',
    docLink: '#',
  },
]

export const Documents = () => {
  const { t } = useTranslation()
  return (
    <SettingsLayout
      title={t('settings.documents.title')}
      templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' }}
    >
      {documentsList.map((item, index) => (
        <React.Fragment key={index}>
          <DocumentCard {...item} />
        </React.Fragment>
      ))}
    </SettingsLayout>
  )
}
