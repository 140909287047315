import { Box, Grid, SystemProps } from '@chakra-ui/react'
import { BackLink } from 'components/BackLink'
import React, { FC } from 'react'

type GridTemplateColumns = SystemProps['gridTemplateColumns']
type GridTemplateRows = SystemProps['gridTemplateRows']

interface Props {
  children: React.ReactNode
  title: string
  subTitle?: string
  templateColumns?: GridTemplateColumns
}

export const SettingsLayout: FC<Props> = ({ children, title, subTitle, templateColumns }) => {
  return (
    <Box>
      <BackLink></BackLink>
      <Box as='h2' textStyle='pageTitle' my={{ base: '1.14rem', lg: '2.28rem' }}>
        {title}
      </Box>
      {subTitle && (
        <Box as='p' fontSize={{ base: 'lg', lg: 'xl' }} mb='1.14rem' color='brand.black.700'>
          {subTitle}
        </Box>
      )}
      <Grid
        templateColumns={templateColumns || { base: '1fr', lg: '1fr' }}
        gap={{ base: '1.14rem', lg: '2.28rem' }}
        templateRows={'auto'}
      >
        {children}
      </Grid>
    </Box>
  )
}
