import { Flex, BoxProps } from '@chakra-ui/react'
import React, { FC, useMemo } from 'react'
import { convertHexToRGBA } from 'utils/hexToRgba'
import { newShade } from 'utils/newShade'
import { numberFromText } from 'utils/numberFromText'

type Sizes = 'lg' | 'md' | 'sm'
const colors = [
  '#d4135a',
  '#8e78ff',
  '#f67302',
  '#f8b03b',
  '#ed1e79',
  '#2a9244',
  '#ed1c25',
  '#2e3192',
  '#f77d7b',
  '#facc02',
  '#3aa07e',
  '#4e02bc',
  '#41c9be',
  '#662c8c',
  '#37a8c5',
  '#1e53ae',
]

const sizeDict: Record<Sizes, BoxProps['sx']> = {
  sm: {
    minW: '1.7rem',
    height: '1.7rem',
    fontSize: '0.85rem',
  },
  md: {
    minW: '2.28rem',
    height: '2.28rem',
    fontSize: '1rem',
  },
  lg: {
    minW: '3.42rem',
    height: '3.42rem',
    fontSize: '1.14rem',
  },
}

interface Props {
  text: string
  image?: string
  size?: Sizes
}

export const FSGAvatar: FC<Props> = ({ text, size = 'md', image }) => {
  const sizeStyle = useMemo(() => {
    return sizeDict[size]
  }, [size])
  const name = useMemo(() => {
    const parts = text.split(' ')
    if (parts.length === 1) {
      return parts[0][0]
    } else {
      return `${parts[0][0]}${parts[parts.length - 1][0]}`
    }
  }, [text])

  const color = useMemo(() => {
    const number = numberFromText(name)
    const col = colors[number % colors.length]
    return {
      color: col,
      bg: newShade(col, 100),
    }
  }, [name])

  return (
    <Flex
      sx={{
        ...(sizeStyle || {}),
        ...color,
        fontWeight: '600',
        borderRadius: '50%',
        textTransform: 'uppercase',
        alignItems: 'center',
        justifyContent: 'center',
        textShadow: '1px 2px 7px',
      }}
    >
      {name}
    </Flex>
  )
}
