import { Stack } from '@chakra-ui/react'
import React, { FC } from 'react'
import { Information } from '../common/Information'
import questionImage from 'assets/images/blue-question.svg'
import { SettingsLayout } from '../common/SettingsLayout'
import { useTranslation } from 'react-i18next'
import { PayoutLink } from './components/PageLink'

export const Payouts: FC = () => {
  const { t } = useTranslation()
  return (
    <SettingsLayout
      templateColumns={{ base: '1fr', lg: 'auto 23.57rem' }}
      title={t('settings.payouts.title')}
      subTitle={t('settings.payouts.description')}
    >
      <div>
        <Stack>
          <PayoutLink
            to='accounts'
            title='Accounts management'
            subtitle='Manage your accounts'
          ></PayoutLink>
        </Stack>
      </div>

      <div>
        <Information
          image={questionImage}
          title={t('settings.payouts.infoTitle')}
          description={t('settings.payouts.infoDescription')}
        />
      </div>
    </SettingsLayout>
  )
}
