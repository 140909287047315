import { toast } from 'components/FSGToast'
import React from 'react'
import { TOAST_TIMEOUT } from 'utils/constants'

export const useCopyToClipboard = () => {
  return React.useCallback((text: string) => {
    navigator.clipboard.writeText(text)
    toast({
      title: 'Copied',
      status: 'success',
      duration: TOAST_TIMEOUT,
      isClosable: true,
      position: 'top',
    })
  }, [])
}
