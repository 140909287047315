import { InfoIcon } from '@chakra-ui/icons'
import { Box, Button, Divider, Flex, Grid, GridItem, Stack, useDisclosure } from '@chakra-ui/react'
import { FSGModalWrapper } from 'components'
import moment from 'moment'
import { ExchangeRate } from 'pages/HomePage/DealsTable/ExchangeRate'
import { ViewDealModal } from 'pages/HomePage/ViewDealModal'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetDealByIdMutation } from 'store/services/user.api'
import { MarketplaceDeal, ModalProps, VerificationDeal } from 'types'
import { currencyFormat } from 'utils/currencyFormat'

interface Props extends ModalProps {
  deals?: VerificationDeal[]
  onCreateDeal: () => void
  isCreating: boolean
}

export const VerifiedDealsModal: FC<Props> = ({ deals, onCreateDeal, isCreating, ...props }) => {
  const { t } = useTranslation()
  const [deal, setDeal] = useState<VerificationDeal>()
  const [getDealById, { isLoading, data }] = useGetDealByIdMutation()
  const {
    isOpen: isViewDealOpen,
    onClose: onViewDealClose,
    onOpen: onViewDealOpen,
  } = useDisclosure()

  return (
    <>
      <FSGModalWrapper {...props} size={{ base: 'full', md: 'md' }}>
        <Box
          fontSize={'1.42rem'}
          fontWeight={800}
          color='brand.black.900'
          textAlign={'center'}
          mb='0.57rem'
        >
          Interesting deals
        </Box>
        <Flex fontSize={'1rem'} color='brand.black.500' justifyContent={'center'} mb='1.14rem'>
          Here are deals that might interest you
        </Flex>
        <Stack spacing={'1.14rem'}>
          {deals?.map((deal) => (
            <React.Fragment key={deal.id}>
              <Box
                w='full'
                borderRadius={'1.14rem'}
                boxShadow='0 0 3.42rem rgba(15, 3, 30, 0.08)'
                bg='white'
                position={'relative'}
                p={'1.14rem'}
              >
                <Grid templateColumns={`1fr 1fr`}>
                  <GridItem as={Stack} spacing={'0.57rem'}>
                    <Stack spacing={'0.28rem'}>
                      <Box fontSize={'1.14rem'} color='brand.black.900' opacity={0.4}>
                        Exchange rate
                      </Box>
                      <ExchangeRate
                        toCurrency={deal.toCurrency}
                        fromCurrency={deal.fromCurrency}
                        rate={deal.rate}
                      ></ExchangeRate>
                    </Stack>
                    <Stack spacing={'0.28rem'}>
                      <Box fontSize={'1.14rem'} color='brand.black.900' opacity={0.4}>
                        Date
                      </Box>
                      <Box>{moment(deal.createdAt).format('MMM DD, YYYY')}</Box>
                    </Stack>
                  </GridItem>
                  <GridItem as={Grid} gap={'0.57rem'}>
                    <Stack spacing={'0.28rem'}>
                      <Box
                        fontSize={'1.14rem'}
                        color='brand.black.900'
                        opacity={0.4}
                        fontWeight={500}
                      >
                        {t('tables.mainDeals.headers.amount')}
                      </Box>
                      <Box fontSize={'1.14rem'} color='brand.black.900'>
                        {currencyFormat(deal.amount)} {deal.toCurrency.slug}
                      </Box>
                    </Stack>
                  </GridItem>
                </Grid>
                <Divider my={'0.85rem'}></Divider>
                <Grid templateColumns={'1fr 1fr'} px='1.14rem' alignItems={'center'}>
                  <GridItem></GridItem>
                  <GridItem justifyContent={'flex-end'} display='flex'>
                    <Button
                      p={0}
                      w='auto'
                      justifyContent={'flex-start'}
                      variant={'link'}
                      color='brand.primary.purple'
                      alignSelf={'flex-end'}
                      onClick={() => {
                        onViewDealOpen()
                        setDeal(deal)
                      }}
                    >
                      View
                    </Button>
                  </GridItem>
                </Grid>
              </Box>
            </React.Fragment>
          ))}
          <Flex
            fontSize={'1rem'}
            color='brand.secondary.blue'
            opacity={'0.8'}
            alignItems='center'
            gap={'0.57rem'}
          >
            <InfoIcon opacity={0.8}></InfoIcon>
            <Box>If you do not see a deal that suits you, create your own</Box>
          </Flex>
          <Grid templateColumns={'1fr 1fr'} gap={'1.14rem'}>
            <Button
              p={0}
              w='auto'
              variant={'outline'}
              onClick={() => {
                props.onClose()
              }}
              isDisabled={isCreating}
            >
              Cancel
            </Button>
            <Button isLoading={isCreating} onClick={onCreateDeal}>
              Create deal
            </Button>
          </Grid>
        </Stack>
      </FSGModalWrapper>
      {deal && (
        <ViewDealModal
          isOpen={isViewDealOpen}
          onClose={onViewDealClose}
          deal={deal}
        ></ViewDealModal>
      )}
    </>
  )
}
