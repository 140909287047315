import { Box, Container, Flex, Grid, Image } from '@chakra-ui/react'
import React, { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { FSGLogoText } from './FSGLogoText'
import { useSetUser } from 'pages/MainLayout/ChatProvider'

interface Props {
  bg: string
  children: React.ReactNode
}

export const AuthLayout: FC<Props> = ({ children, bg }) => {
  const setChatUser = useSetUser()
  const navigate = useNavigate()
  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token) {
      navigate('/')
    }
  }, [])
  useEffect(() => {
    setChatUser()
  }, [setChatUser])

  return (
    <Container
      maxW={'103rem'}
      py={{ base: 0, md: '2.85rem' }}
      pb='2.85rem'
      px={{ base: 0, md: 4 }}
      display='flex'
      justifyContent='center'
      alignItems={{ base: 'flex-start', md: 'center' }}
      h={{ base: 'auto', md: '100vh' }}
    >
      <Grid
        w='full'
        templateColumns={{ base: '1fr', md: '1fr 1fr' }}
        templateRows={{ md: '1fr' }}
        h={{ base: 'auto', md: 'full' }}
      >
        <Box
          flex={{ sm: 'auto', md: '1' }}
          borderRadius={{ base: 0, md: 24 }}
          overflow='hidden'
          h={{ base: '16.43rem', md: 'full' }}
        >
          <Image src={bg} maxH='full' maxHeight='full' w='full' objectFit='cover' h='full' />
        </Box>
        <Box flex='1'>
          <Container maxW='none' px={['2rem', '4rem']} h='full'>
            <Flex justifyContent='center' h='full' flexDirection='column'>
              <FSGLogoText
                display='flex'
                justifyContent='center'
                marginBottom={{ base: '1.15rem', md: '4rem' }}
                mt={{ base: '1.15rem', md: 0 }}
              />
              {children}
            </Flex>
          </Container>
        </Box>
      </Grid>
    </Container>
  )
}
