import { VerificationContext } from 'App'
import { useCallback, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { useGetUserQuery } from 'store/services/user.api'
import { setOtpState } from 'store/slices/auth'
import { OTPType, PurposeType } from 'types'

export const useVerifyEmail = (userId?: string) => {
  const dispatch = useDispatch()
  const verificationContext = useContext(VerificationContext)
  const { refetch } = useGetUserQuery()
  verificationContext?.setOnConfirm?.(() => refetch())
  const verifyEmail = useCallback(() => {
    if (userId) {
      dispatch(
        setOtpState({
          code: 'Unverified email',
          purpose: PurposeType.VERIFY_EMAIL,
          otpType: OTPType.EMAIL,
          userId,
        }),
      )
    }
  }, [userId])
  return verifyEmail
}
