import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FAQ } from 'types'

interface FAQState {
  items: FAQ[]
}

const initialState = {
  items: [
    {
      title:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tristique odio morbi accumsan.',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sit eget posuere habitasse feugiat diam dignissim in. Eleifend rhoncus donec vitae convallis diam. At lorem suspendisse posuere urna. Pretium faucibus diam ridiculus sed pellentesque non, ut pretium. Elementum amet, ac fermentum dui. Id posuere turpis interdum tincidunt feugiat dignissim vitae libero. Nibh faucibus sapien ipsum augue quis suspendisse ut. Quis viverra fames sed mus cras quis volutpat est. Nisi, dolor sapien quis eu felis risus a. Nulla facilisis porttitor consectetur faucibus. ',
    },
    {
      title:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tristique odio morbi accumsan.',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sit eget posuere habitasse feugiat diam dignissim in. Eleifend rhoncus donec vitae convallis diam. At lorem suspendisse posuere urna. Pretium faucibus diam ridiculus sed pellentesque non, ut pretium. Elementum amet, ac fermentum dui. Id posuere turpis interdum tincidunt feugiat dignissim vitae libero. Nibh faucibus sapien ipsum augue quis suspendisse ut. Quis viverra fames sed mus cras quis volutpat est. Nisi, dolor sapien quis eu felis risus a. Nulla facilisis porttitor consectetur faucibus. ',
    },
    {
      title:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tristique odio morbi accumsan.',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sit eget posuere habitasse feugiat diam dignissim in. Eleifend rhoncus donec vitae convallis diam. At lorem suspendisse posuere urna. Pretium faucibus diam ridiculus sed pellentesque non, ut pretium. Elementum amet, ac fermentum dui. Id posuere turpis interdum tincidunt feugiat dignissim vitae libero. Nibh faucibus sapien ipsum augue quis suspendisse ut. Quis viverra fames sed mus cras quis volutpat est. Nisi, dolor sapien quis eu felis risus a. Nulla facilisis porttitor consectetur faucibus. ',
    },
    {
      title:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tristique odio morbi accumsan.',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sit eget posuere habitasse feugiat diam dignissim in. Eleifend rhoncus donec vitae convallis diam. At lorem suspendisse posuere urna. Pretium faucibus diam ridiculus sed pellentesque non, ut pretium. Elementum amet, ac fermentum dui. Id posuere turpis interdum tincidunt feugiat dignissim vitae libero. Nibh faucibus sapien ipsum augue quis suspendisse ut. Quis viverra fames sed mus cras quis volutpat est. Nisi, dolor sapien quis eu felis risus a. Nulla facilisis porttitor consectetur faucibus. ',
    },
  ],
} as FAQState

const faqSlice = createSlice({
  name: 'faq',
  initialState,
  reducers: {},
})

// export const {} = faqSlice.actions
export default faqSlice.reducer
