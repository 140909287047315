import { Box, Flex, Spinner } from '@chakra-ui/react'
import React from 'react'

export const LoadingData: React.FC = () => {
  return (
    <Flex justifyContent={'center'} alignItems='center' gap='1.42rem' py='2.84rem'>
      <Spinner
        thickness='0.28rem'
        speed='0.65s'
        emptyColor='gray.200'
        color='brand.primary.purple'
        size='xl'
      />
      <Box>Loading...</Box>
    </Flex>
  )
}
