import { Stack, Box, Flex, useBreakpointValue } from '@chakra-ui/react'
import { CADIcon, NGNIcon } from 'assets/icons'
import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { banksSelector } from 'store/selectors/currencies'
import { userAccountsSelector } from 'store/selectors/user'
import { Account } from 'types'
import { CADAccount } from './CADAccount'
import { NGNAccount } from './NGNAccount'

const AccountRenderers = {
  NGN: NGNAccount,
  CAD: CADAccount,
}

const FlagsIcons = {
  NGN: NGNIcon,
  CAD: CADIcon,
}

interface Props {
  onSelect: (id: Account | null) => void
  onEdit: () => void
  onDelete: () => void
  selectedAccount: Account | null
}

export const PayoutsList: FC<Props> = ({ onSelect, onEdit, onDelete, selectedAccount }) => {
  const size = useBreakpointValue({ base: 'mobile', md: 'tablet' }, { ssr: false })
  const accounts = useSelector(userAccountsSelector)
  const banks = useSelector(banksSelector)
  return (
    <Stack spacing={'0.85rem'}>
      {accounts?.map((account, index) => {
        return (
          <Box
            key={account.id}
            boxShadow='0 0 5.14rem rgba(15, 3, 30, 0.08)'
            borderRadius={'1.14rem'}
            p='1.14rem'
            background={
              account.id === selectedAccount?.id && size === 'tablet'
                ? 'brand.secondary.purpleSelected'
                : 'white'
            }
            cursor='pointer'
            onClick={() => onSelect(account)}
          >
            {size === 'tablet' ? (
              <Flex gap={'1.14rem'}>
                {React.createElement(FlagsIcons[account.currency.slug], {
                  width: '2.5rem',
                  height: '1.78rem',
                })}
                {account.currency.slug === 'NGN' && (
                  <Stack>
                    <Box fontSize='1.14rem' color='brand.black.900' fontWeight={800}>
                      {account.account.destinationBankAccountNumber}
                    </Box>
                    <Box fontSize='1.14rem' color='brand.black.700'>
                      {
                        banks.find((bank) => bank.uuid === account.account.destinationBankUUID)
                          ?.name
                      }
                    </Box>
                  </Stack>
                )}
                {account.currency.slug === 'CAD' && (
                  <Stack>
                    <Box fontSize='1.14rem' color='brand.black.900' fontWeight={800}>
                      {account.account.email}
                    </Box>
                    <Box fontSize='1.14rem' color='brand.black.700'>
                      {account.account.accountName}
                    </Box>
                  </Stack>
                )}
              </Flex>
            ) : (
              React.createElement(AccountRenderers[account.currency.slug], {
                ...account,
                onEdit: () => {
                  onSelect(account)
                  onEdit()
                },
                onDelete: () => {
                  onSelect(account)
                  onDelete()
                },
              })
            )}
          </Box>
        )
      })}
    </Stack>
  )
}
