import { Container, Box, Grid } from '@chakra-ui/react'
import React, { FC } from 'react'
import personalInfo from 'assets/images/settings/personal-info.png'
import security from 'assets/images/settings/security.png'
import payouts from 'assets/images/settings/payouts.png'
import notifications from 'assets/images/settings/notifications.png'
import faq from 'assets/images/settings/faq.png'
import documents from 'assets/images/settings/documents.png'
import { LinkCard } from './LinkCard'
import { useTranslation } from 'react-i18next'
import { AmplitudeEvents, useTrackAction } from 'pages/MainLayout/AmplitudeProvider'

const settingsLinks = [
  {
    iconImage: personalInfo,
    to: 'personal-info',
    title: 'Personal info',
    description: 'Edit and review all your personal details',
  },
  {
    iconImage: security,
    to: 'security',
    title: 'Login and Security',
    description: 'Update your password and secure your account',
  },
  {
    iconImage: payouts,
    to: 'payouts',
    title: 'Payouts',
    description: 'Review payments, payouts, coupons, and taxes',
  },
  {
    iconImage: notifications,
    to: 'notifications',
    title: 'Notifications',
    description: 'Choose notification preferences and how you want to be contacted',
  },
  {
    iconImage: faq,
    to: 'faq',
    title: 'FAQ',
    description: 'View the Frequently Asked Questions at Xchange',
  },
  {
    iconImage: documents,
    to: 'documents',
    title: 'Documents',
    description: 'Review Legal Documents and Help Docs',
  },
]

export const SettingsMainPage: FC = () => {
  const { t } = useTranslation()
  const track = useTrackAction()

  React.useEffect(() => {
    track?.(AmplitudeEvents.VIEW_SETTINGS)
  }, [track])
  return (
    <Box>
      <Box as='h2' textStyle='pageTitle' mb='2.28rem'>
        {t('settings.title')}
      </Box>
      <Grid
        templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' }}
        gap='2.28rem'
      >
        {settingsLinks.map((link, index) => (
          <React.Fragment key={index}>
            <LinkCard {...link} />
          </React.Fragment>
        ))}
      </Grid>
    </Box>
  )
}
