import { Box, HStack, Stack } from '@chakra-ui/react'
import { CADIcon, NGNIcon } from 'assets/icons'
import { useDprWithOs } from 'hooks/useDprWithOs'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { getWalletsDictsSelector } from 'store/selectors/user'
import { Currencies, Wallet } from 'types'
import { currencyFormat } from 'utils/currencyFormat'

export const Balance: FC = () => {
  const wallets = useSelector(getWalletsDictsSelector)
  const dpr = useDprWithOs()
  const iconWidth = 24 / dpr
  const iconHeight = 18 / dpr

  return (
    <Stack
      p='1.14rem'
      bg='rgba(255, 255, 255, 0.08)'
      borderRadius={'0.85rem'}
      spacing={'1.14rem'}
      className='walletInfo'
    >
      <Box color='white' textStyle={'p1'} fontWeight={800}>
        Available balance
      </Box>
      <Stack spacing={'1.42rem'} fontSize={'1rem'}>
        <HStack spacing={'0.57rem'}>
          <CADIcon width={iconWidth} height={iconHeight}></CADIcon>
          <HStack color={'white'}>
            <Box>{currencyFormat(+wallets.CAD?.[0].availableBalance || 0)}</Box>
            <Box fontWeight={800}>{Currencies.CAD}</Box>
          </HStack>
        </HStack>
        <HStack spacing={'0.57rem'}>
          <NGNIcon width={iconWidth} height={iconHeight}></NGNIcon>
          <HStack color={'white'}>
            <Box>{currencyFormat(+wallets.NGN?.[0].availableBalance || 0)}</Box>
            <Box fontWeight={800}>{Currencies.NGN}</Box>
          </HStack>
        </HStack>
      </Stack>
    </Stack>
  )
}
