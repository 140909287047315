import { Box, Button, Modal, ModalContent, ModalOverlay, Image, Grid, Flex } from '@chakra-ui/react'
import React, { FC, useCallback } from 'react'
import shield from 'assets/images/shield.svg'
import { FSGInput } from 'components'
import { useResendVerificationCodeMutation, useVerifyOtpMutation } from 'store/services/auth.api'
import { BaseError, OTPType, PurposeType, VerifyUserAction } from 'types'
import { toast } from 'components/FSGToast'
import { useFormik } from 'formik'
import { TOAST_ERROR_TIMEOUT, TOAST_TIMEOUT } from 'utils/constants'

interface Props {
  isOpen: boolean
  purpose: PurposeType
  loading?: boolean
  email?: string
  userId: string
  type?: OTPType
  onClose: () => void
  onConfirm?: <Data extends object>(code: Data) => void
}

export const VerifyModal: FC<Props> = ({ isOpen, purpose, userId, type, onClose, onConfirm }) => {
  const [verifyToken, { isLoading: isVerifying }] = useVerifyOtpMutation()
  const [resendCode] = useResendVerificationCodeMutation()

  const handleConfirm = useCallback(
    async (value: VerifyUserAction) => {
      const response = await verifyToken(value)
      if ('data' in response) {
        onConfirm?.(response.data)
      } else {
        toast({
          title: 'Verification',
          description: (response as BaseError).error.data?.message,
          status: 'error',
          duration: TOAST_ERROR_TIMEOUT,
          isClosable: true,
          position: 'top',
        })
      }
    },
    [userId, purpose, onConfirm],
  )

  const formik = useFormik<VerifyUserAction>({
    initialValues: {
      verificationCode: '',
      purpose: purpose,
      userId: userId,
    },
    validate: (value) => {
      const errors: { verificationCode?: string } = {}
      if (!value.verificationCode) {
        errors.verificationCode = 'Is required'
      }
      return errors
    },
    onSubmit: async (value) => {
      await handleConfirm(value)
    },
  })

  const handleResendClick = useCallback(
    async (e: React.MouseEvent<HTMLAnchorElement | HTMLDivElement, MouseEvent>) => {
      e.preventDefault()
      await resendCode({
        userId,
        purpose,
      })
    },
    [purpose, userId, resendCode],
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered variant={'auto'}>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={formik.handleSubmit}>
          <Flex alignItems='center' flexDirection={'column'} w='full'>
            <Image h='3.42rem' w='3.42rem' src={shield}></Image>
            <Box textStyle='h2' fontWeight='800' mt='1.14rem'>
              Verification code
            </Box>
            <Box textStyle='p1' mt='1.14rem'>
              Enter the code we&apos;ve sent on your{' '}
              {type === OTPType.EMAIL ? 'email' : 'phone number'}
            </Box>
            <Flex justifyContent={'center'} w='full'>
              <FSGInput
                placeholder='Enter the verification code'
                maxW={['none', 'none', '34.28rem']}
                mt='2.28rem'
                value={formik.values.verificationCode}
                name='verificationCode'
                onChange={formik.handleChange}
                isRequired
              ></FSGInput>
            </Flex>
            <Grid w='full' templateColumns={'1fr 1fr'} gap='2.14rem' mt='2.28rem'>
              <Button variant={'outline'} onClick={onClose}>
                Cancel
              </Button>
              <Button type='submit' isLoading={isVerifying} isDisabled={!formik.isValid}>
                Verify
              </Button>
            </Grid>
            <Box
              as='a'
              mt='1.71rem'
              color={'brand.primary.purple'}
              fontSize='1rem'
              fontWeight={600}
              cursor='pointer'
              onClick={handleResendClick}
            >
              Send {type === OTPType.EMAIL ? 'mail' : 'SMS'} again
            </Box>
          </Flex>
        </form>
      </ModalContent>
    </Modal>
  )
}
