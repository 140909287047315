import {
  Box,
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Grid,
  ExpandedIndex,
} from '@chakra-ui/react'
import { UserIcon } from 'assets/icons'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { userInfoSelector } from 'store/selectors/user'
import { PersonalInfoPanel } from './PersonalInfoPanel'
import questionImage from 'assets/images/blue-question.svg'
import { Information } from '../common/Information'
import { useUpdateUserPersonalInfoMutation } from 'store/services/user.api'
import { UpdateUserProfileRequest } from 'types'
import { AccordionItemContent } from '../common/AccordionItemContent'
import { SettingsLayout } from '../common/SettingsLayout'
import { useTranslation } from 'react-i18next'
import { KYCStatus } from './KYCStatus'
import { AmplitudeEvents, useTrackAction } from 'pages/MainLayout/AmplitudeProvider'

export const PersonalInfo: FC = () => {
  const { t } = useTranslation()
  const track = useTrackAction()
  const [index, setIndex] = useState<ExpandedIndex>([])
  const [updateUserInfo, { isLoading }] = useUpdateUserPersonalInfoMutation()
  const userInfo = useSelector(userInfoSelector)

  const [personalInfoState, setPersonalInfoState] = useState<
    Omit<UpdateUserProfileRequest, 'phoneNumber'>
  >({
    firstName: '',
    middleName: '',
    lastName: '',
    name: '',
  })

  const handleSave = useCallback(async () => {
    if (userInfo) {
      const response = await updateUserInfo({
        ...personalInfoState,
      })
      if ('data' in response) {
        setIndex([])
      }
    }
  }, [userInfo, personalInfoState])

  useEffect(() => {
    if (!userInfo) return
    setPersonalInfoState({
      ...userInfo,
    })
  }, [userInfo])

  return (
    <SettingsLayout
      title={t('settings.personal-info.title')}
      subTitle={t('settings.personal-info.description')}
      templateColumns={'1fr'}
    >
      <KYCStatus></KYCStatus>
      <Grid
        templateColumns={{ base: '1fr', lg: 'auto 23.57rem' }}
        gap={{ base: '1.14rem', lg: '2.28rem' }}
        templateRows={'auto'}
      >
        <div>
          <Box
            overflow={'hidden'}
            boxShadow='0 0 5.14rem rgba(15, 3, 30, 0.08)'
            borderRadius={'1.14rem'}
          >
            <Accordion
              allowToggle
              index={index}
              onChange={(index: ExpandedIndex) => {
                setIndex(index)
              }}
            >
              <AccordionItem id='firstName'>
                {({ isExpanded }) => (
                  <>
                    <AccordionItemContent
                      title={t('fields.firstName.label')}
                      value={userInfo?.firstName}
                      isExpanded={isExpanded}
                      hideButton
                    />
                    <AccordionPanel>
                      <PersonalInfoPanel
                        value={personalInfoState.firstName}
                        label={t('fields.firstName.label')}
                        placeholder={t('fields.firstName.placeholder')}
                        onChange={(value) =>
                          setPersonalInfoState({
                            ...personalInfoState,
                            firstName: value,
                          })
                        }
                        onSubmit={handleSave}
                        disabled={true}
                        isLoading={isLoading}
                      />
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              {/* <AccordionItem id='middleName'>
                {({ isExpanded }) => (
                  <>
                    <AccordionItemContent
                      title={t('fields.middleName.label')}
                      value={userInfo?.middleName}
                      isExpanded={isExpanded}
                      hideButton={personalInfoState.hasCompletedKyc}
                    />
                    <AccordionPanel>
                      <PersonalInfoPanel
                        value={personalInfoState.middleName}
                        label={t('fields.middleName.label')}
                        placeholder={t('fields.middleName.placeholder')}
                        onChange={(value) =>
                          setPersonalInfoState({
                            ...personalInfoState,
                            middleName: value,
                          })
                        }
                        onSubmit={handleSave}
                        disabled={true}
                        isLoading={isLoading}
                      />
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem> */}
              <AccordionItem id='lastName'>
                {({ isExpanded }) => (
                  <>
                    <AccordionItemContent
                      title={t('fields.lastName.label')}
                      value={userInfo?.lastName}
                      isExpanded={isExpanded}
                      hideButton
                    />
                    <AccordionPanel>
                      <PersonalInfoPanel
                        value={personalInfoState.lastName}
                        label={t('fields.lastName.label')}
                        placeholder={t('fields.lastName.placeholder')}
                        onChange={(value) =>
                          setPersonalInfoState({
                            ...personalInfoState,
                            lastName: value,
                          })
                        }
                        onSubmit={handleSave}
                        disabled={true}
                        isLoading={isLoading}
                      />
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem id='displayName'>
                {({ isExpanded }) => (
                  <>
                    <AccordionItemContent
                      title={t('fields.displayName.label')}
                      value={userInfo?.name}
                      isExpanded={isExpanded}
                    />
                    <AccordionPanel>
                      <PersonalInfoPanel
                        value={personalInfoState.name}
                        label={t('fields.displayName.label')}
                        placeholder={t('fields.displayName.placeholder')}
                        onChange={(value) =>
                          setPersonalInfoState({
                            ...personalInfoState,
                            name: value,
                          })
                        }
                        onSubmit={() => {
                          track?.(AmplitudeEvents.UPDATE_USER_DISPLAY_NAME, {
                            name: personalInfoState.name,
                          })
                          handleSave()
                        }}
                        isLoading={isLoading}
                      />
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            </Accordion>
          </Box>
        </div>
        <div>
          <Information
            image={questionImage}
            title={t('settings.personal-info.infoTitle')}
            description={t('settings.personal-info.infoDescription')}
          />
        </div>
      </Grid>
    </SettingsLayout>
  )
}
