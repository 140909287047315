import { Box, Image } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

interface Props {
  iconImage: string
  to: string
  title: string
  description: string
}

export const LinkCard: React.FC<Props> = ({ iconImage, to, title, description }) => {
  const { t } = useTranslation()
  return (
    <Box
      as={Link}
      to={to}
      boxShadow='0 0 5.14rem rgba(15, 3, 30, 0.08)'
      p={{ base: '1.14rem', lg: '1.71rem' }}
      borderRadius='1.14rem'
      minH='12.28rem'
    >
      <Image src={iconImage} mb='1.14rem' width={'1.71rem'} height={'1.71rem'}></Image>
      <Box as='h3' mb='0.85rem' textStyle={'cardTitle'}>
        {t(`settings.${to}.title`)}
      </Box>
      <Box as='p' textStyle={'p1'}>
        {t(`settings.${to}.description`)}
      </Box>
    </Box>
  )
}
