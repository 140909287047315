import { FSGMaskedInput } from 'components/FSGMaskedInput'
import { FormikProvider, useFormik } from 'formik'
import { AmplitudeEvents, useTrackAction } from 'pages/MainLayout/AmplitudeProvider'
import React, { useImperativeHandle, useRef } from 'react'
import { useResolveCADPayinMutation } from 'store/services/user.api'
import { CADPayin } from 'types'
import { amountToNumber } from 'utils/amountToNumber'

type Props = {
  onClose: () => void
  currencyId: string
}

export const CADAddFunds = React.forwardRef(
  ({ onClose, currencyId }: Props, ref: React.ForwardedRef<{ submit: () => void }>) => {
    const track = useTrackAction()
    const [resolveCadPayin] = useResolveCADPayinMutation()

    const formik = useFormik<CADPayin>({
      initialValues: {
        amount: 0,
        currencyId: currencyId,
      },
      onSubmit: async (value) => {
        // call api here
        const response = await resolveCadPayin({
          ...value,
          amount: amountToNumber(`${value.amount}`),
        })
        if ('data' in response) {
          // call onClose here
          track?.(AmplitudeEvents.ADD_FUNDS, { currency: 'CAD' })
          onClose()
        } else {
          // handle error here
          console.error('Oops', response.error)
        }
      },
    })

    useImperativeHandle(ref, () => ({
      submit: () => {
        formik.submitForm()
      },
    }))

    return (
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <FSGMaskedInput
            currencySlug={'CAD'}
            onChange={(e) => {
              formik.setFieldValue('amount', e.target.value)
            }}
            value={formik.values.amount}
            name='amount'
            label='Amount'
            error={formik.errors.amount}
            isRequired
          ></FSGMaskedInput>
        </form>
      </FormikProvider>
    )
  },
)

CADAddFunds.displayName = 'CADAddFunds'
