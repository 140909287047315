import { createSelector } from '@reduxjs/toolkit'
import { flatten } from 'lodash'
import { RootStore } from 'store'

export const dealsState = (state: RootStore) => state.deals

export const dealsListSelector = createSelector(dealsState, (state) => state.deals)
export const dealsTotalSelector = createSelector(dealsState, (state) => state.totalItems)

// new approach for table deals
export const dealsFlatSelector = createSelector(dealsState, (state) => {
  return flatten(Object.values(state.dealsPerPage))
})
