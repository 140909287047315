import { CheckIcon } from '@chakra-ui/icons'
import { Box, Flex, Stack } from '@chakra-ui/react'
import { useVerifyEmail } from 'hooks/useVerifyEmail'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { RootStore } from 'store'
import { hasCompletedKyc, userAccountsSelector } from 'store/selectors/user'
import { useOpenKycModal } from '../ModalsProvider'
import { useVerifyPhoneNumber } from 'hooks/useVerifyPhoneNumber'

const checkboxRenderer = (checked: boolean) => {
  if (!checked)
    return (
      <Box
        border='solid 0.07rem white'
        borderRadius={'0.28rem'}
        width='1.14rem'
        height='1.14rem'
      ></Box>
    )

  return (
    <Flex
      alignItems={'center'}
      justifyContent='center'
      border='solid 0.07rem white'
      bg='white'
      borderRadius={'0.28rem'}
      width='1.14rem'
      height='1.14rem'
    >
      <CheckIcon color={'#221730'} width='0.85rem' height='0.85rem'></CheckIcon>
    </Flex>
  )
}

type Props = {
  variant?: 'desktop' | 'mobile'
}

export const SetupAccount: React.FC<Props> = ({ variant = 'desktop' }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const payoutAccounts = useSelector(userAccountsSelector)
  const onOpen = useOpenKycModal()

  const hasPayoutAccounts = useMemo(() => {
    return payoutAccounts.length > 0
  }, [payoutAccounts])
  const completedKyc = useSelector(hasCompletedKyc)
  const phoneNumberVerified = useSelector(
    (state: RootStore) => state.user.user?.phoneNumberVerified,
  )
  const userId = useSelector((state: RootStore) => state.user.user?.id)

  const verifyEmail = useVerifyEmail(userId)
  const verifyPhoneNumber = useVerifyPhoneNumber(userId)

  if (hasPayoutAccounts && completedKyc) return <></>
  return (
    <Stack
      bg={variant === 'desktop' ? 'rgba(255, 255, 255, 0.18)' : 'brand.secondary.purpleDark'}
      p='1.14rem'
      borderRadius={variant === 'desktop' ? '0.57rem' : '0'}
      color='white'
      spacing={'1.14rem'}
      className='userSettingsFinish'
    >
      <Box
        sx={{
          fontSize: '1.14rem',
          fontWeight: 800,
        }}
      >
        {t('completeRegistration.title')}
      </Box>
      <Flex
        onClick={() => onOpen?.()}
        alignItems={'center'}
        cursor='pointer'
        _hover={{
          textDecoration: 'underline',
        }}
        gap='0.57rem'
        fontSize={'1rem'}
        pointerEvents={completedKyc ? 'none' : 'auto'}
      >
        {checkboxRenderer(!!completedKyc)}
        <Box
          sx={{
            position: 'relative',
            _after: {
              position: 'absolute',
              display: 'block',
              content: '""',
              width: '100%',
              height: '0.07rem',
              bg: completedKyc ? 'white' : 'transparent',
              top: '50%',
            },
          }}
        >
          {t('completeRegistration.completeKYC')}
        </Box>
      </Flex>
      <Flex
        onClick={verifyPhoneNumber}
        alignItems={'center'}
        cursor='pointer'
        _hover={{
          textDecoration: 'underline',
        }}
        gap='0.57rem'
        fontSize={'1rem'}
        pointerEvents={phoneNumberVerified ? 'none' : 'auto'}
      >
        {checkboxRenderer(!!phoneNumberVerified)}
        <Box
          sx={{
            position: 'relative',
            _after: {
              position: 'absolute',
              display: 'block',
              content: '""',
              width: '100%',
              height: '0.07rem',
              bg: phoneNumberVerified ? 'white' : 'transparent',
              top: '50%',
            },
          }}
        >
          {t('completeRegistration.verifyYourPhoneNumber')}
        </Box>
      </Flex>
      <Flex
        as={Link}
        to={'/settings/payouts/accounts'}
        alignItems={'center'}
        _hover={{
          textDecoration: 'underline',
        }}
        gap='0.57rem'
        fontSize={'1rem'}
        opacity={completedKyc ? 1 : 0.5}
        pointerEvents={completedKyc ? 'auto' : 'none'}
      >
        {checkboxRenderer(hasPayoutAccounts)}
        <Box
          sx={{
            position: 'relative',
            _after: {
              position: 'absolute',
              display: 'block',
              content: '""',
              width: '100%',
              height: '0.07rem',
              bg: hasPayoutAccounts ? 'white' : 'transparent',
              top: '50%',
            },
          }}
        >
          {t('completeRegistration.createPayoutAccounts')}
        </Box>
      </Flex>
    </Stack>
  )
}
