import {
  Box,
  chakra,
  Flex,
  Grid,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from '@chakra-ui/react'
import { FSGInput } from 'components'
import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { supportedCurrenciesSelector } from 'store/selectors/currencies'
import { Currency } from 'types'
import { MAIN_DEAL_SLUG, TO_CURRENCY_SLUG } from 'utils/constants'

interface Props {
  currency: Currency
  rate: number
  onRateChange: (rate: number) => void
}

export const RateInput: FC<Props> = ({ currency, rate, onRateChange }) => {
  const { t } = useTranslation()

  // TODO: Discuss to change it to selector
  const toCurrency = useMemo(() => {
    return currency.pairs[0].currency
  }, [currency])

  return (
    <Box p='1.71rem' borderRadius={'1.14rem'} bg='brand.black.100'>
      <chakra.p fontSize={'1rem'} color='brand.black.700' mb='1.14rem'>
        {t('createDeal.rangeInputTitle')}
      </chakra.p>
      <Grid
        mb='1.71rem'
        fontSize={'2.28rem'}
        color='brand.black.900'
        fontWeight={800}
        alignItems='center'
        gap='0.57rem'
        templateColumns={'auto auto'}
      >
        <Flex>
          1 {MAIN_DEAL_SLUG} <Box>=</Box>
        </Flex>

        <Flex alignItems={'center'} gap='1.14rem'>
          <FSGInput
            fontSize={'2.28rem'}
            color='brand.black.900'
            fontWeight={800}
            display='flex'
            value={rate}
            type='numeric'
            onChange={(e) => onRateChange(+e.target.value)}
            isRequired
          ></FSGInput>
          <Box>{TO_CURRENCY_SLUG}</Box>
        </Flex>
      </Grid>
      {/* <Slider
        aria-label='slider-ex-4'
        defaultValue={30}
        value={rate}
        onChange={(e) => onRateChange(e)}
        focusThumbOnChange={false}
        min={1}
        max={400}
      >
        <SliderTrack bg='brand.black.200' height={'8px'}>
          <SliderFilledTrack bg='brand.primary.purple' height={'8px'} />
        </SliderTrack>
        <SliderThumb
          boxSize={6}
          background='brand.primary.purple'
          border='solid 6px'
          borderColor={'white'}
        ></SliderThumb>
      </Slider> */}
    </Box>
  )
}
