import { Accordion, Box, Grid, Button } from '@chakra-ui/react'
import { FSGFilterAccordionItem, FilterContext } from 'components'
import { FSGAmountRange } from 'components/FSGAmountRange'
import { FSGExchangeRate } from 'components/FSGExchangeRate'
import { FormikProvider, useFormik } from 'formik'
import React, { FC, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'

export const FilterPanel: FC<{
  onFilter: (filterValue: { amountRange?: number[]; exchangeRate?: number }) => void
}> = ({ onFilter }) => {
  const { closePopover } = useContext(FilterContext)
  const params = useParams<{ currency: string }>()
  const formik = useFormik({
    initialValues: {
      amountRange: undefined,
      exchangeRate: undefined,
    },
    onSubmit: (value) => {
      onFilter(value)
      closePopover?.()
    },
  })

  useEffect(() => {
    formik.resetForm()
  }, [params.currency])

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormikProvider value={formik}>
        <Box
          boxShadow={'0 0 5.14rem rgba(15, 3, 30, 0.12)'}
          padding='1.71rem'
          borderRadius={'1.14rem'}
          width={'23.35rem'}
        >
          <Box textStyle={'h4'} fontWeight={800}>
            Filters
          </Box>
          <Accordion>
            <Accordion allowToggle>
              <FSGFilterAccordionItem title='Amount range'>
                <FSGAmountRange
                  name='amountRange'
                  onChange={formik.handleChange}
                  value={formik.values.amountRange}
                  currency={params.currency}
                ></FSGAmountRange>
              </FSGFilterAccordionItem>
              <FSGFilterAccordionItem title='Exchange range'>
                <FSGExchangeRate
                  name='exchangeRate'
                  value={formik.values.exchangeRate}
                  onChange={formik.handleChange}
                />
              </FSGFilterAccordionItem>
            </Accordion>
          </Accordion>
          <Grid templateColumns={'1fr 1fr'} gap='1.14rem'>
            <Button
              variant={'outline'}
              onClick={() => {
                formik.resetForm()
                formik.submitForm()
                closePopover?.()
              }}
            >
              Clear all
            </Button>
            <Button type='submit'>Filter</Button>
          </Grid>
        </Box>
      </FormikProvider>
    </form>
  )
}
