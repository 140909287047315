import { ChevronDownIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'
import { Flex, IconButton, Skeleton } from '@chakra-ui/react'
import { createColumnHelper } from '@tanstack/react-table'
import i18n from 'i18n'
import moment from 'moment'
import { ExchangeRate } from 'pages/HomePage/DealsTable/ExchangeRate'
import React from 'react'
import { Deal, DealStatus } from 'types'
import { currencyFormat } from 'utils/currencyFormat'
import { ProgressRenderer } from './ProgressRenderer'

const columnHelper = createColumnHelper<Deal>()

export const columns = (
  onDelete: (deal: Deal) => void,
  onEdit: (deal: Deal) => void,
  onExpand: (dealId: string) => void,
) => [
  columnHelper.accessor(
    (props) => {
      if (!props) return null
      return { amount: props.amount, fromCurrency: props.fromCurrency }
    },
    {
      cell: (props) => {
        const value = props.getValue()

        return value ? (
          `${currencyFormat(value.amount)} ${value.fromCurrency.slug}`
        ) : (
          <Skeleton height={'1.42rem'}></Skeleton>
        )
      },
      header: i18n.t('tables.mainDeals.headers.amount') as string,
      id: 'amount',
    },
  ),
  columnHelper.accessor(
    (props) => {
      if (!props) return null
      return props
    },
    {
      header: i18n.t('tables.mainDeals.headers.sold') as string,
      cell: (props) => {
        const value = props.getValue()
        //return <>Test</>
        return value ? (
          <ProgressRenderer maxWidth='none' minWidth='14.28rem' deal={value} />
        ) : (
          <Skeleton height={'1.42rem'}></Skeleton>
        )
      },
      enableSorting: false,
      id: 'sold',
    },
  ),
  columnHelper.accessor(
    (props) => {
      if (!props) return null
      return {
        rate: props.rate,
        toCurrency: props.toCurrency,
        fromCurrency: props.fromCurrency,
      }
    },
    {
      header: i18n.t('tables.mainDeals.headers.exchangeRate') as string,
      cell: (props) => {
        const value = props.getValue()
        //return <>Test</>
        return value ? <ExchangeRate {...value} /> : <Skeleton height={'1.42rem'}></Skeleton>
      },
      id: 'rate',
    },
  ),
  columnHelper.accessor(
    (props) => {
      if (!props) return null
      return {
        date: props.createdAt,
      }
    },
    {
      header: i18n.t('tables.transactions.headers.date') as string,
      enableSorting: true,
      cell: (props) => {
        const value = props.getValue()
        return value ? <div>{moment(value.date).format('MMM DD, YYYY')}</div> : <></>
      },
      id: 'date',
    },
  ),
  columnHelper.accessor((props) => props, {
    id: 'action',
    enableSorting: false,
    cell: (props) => {
      const { row } = props
      const canExpand = row.getCanExpand()
      const isCompleted =
        props.getValue().status === DealStatus.FINISHED ||
        props.getValue().status === DealStatus.SUSPENDED
      return (
        props.getValue() && (
          <Flex gap='1.14rem'>
            <IconButton
              aria-label='Edit'
              variant={'link'}
              border='0.07rem solid #D9D7DB'
              color={'brand.black.900'}
              maxW='2.28rem'
              minW='2.28rem'
              minH='2.28rem'
              icon={<EditIcon></EditIcon>}
              onClick={() => onEdit(props.getValue())}
              isDisabled={isCompleted}
            ></IconButton>
            <IconButton
              aria-label='Delete'
              variant={'link'}
              border='0.07rem solid #D9D7DB'
              color={'brand.black.900'}
              maxW='2.28rem'
              minW='2.28rem'
              minH='2.28rem'
              icon={<DeleteIcon></DeleteIcon>}
              onClick={() => onDelete(props.getValue())}
              isDisabled={isCompleted}
            ></IconButton>
            {canExpand && (
              <IconButton
                aria-label='Delete'
                variant={'link'}
                color={'brand.primary.purple'}
                maxW='2.28rem'
                minW='2.28rem'
                minH='2.28rem'
                fontSize={'1.71rem'}
                icon={
                  <ChevronDownIcon
                    transform={`rotate(${row.getIsExpanded() ? '180deg' : '0deg'})`}
                  />
                }
                onClick={async () => {
                  if (!row.getIsExpanded()) {
                    await onExpand(props.getValue().id)
                  }
                  props.row.toggleExpanded()
                }}
              ></IconButton>
            )}
          </Flex>
        )
      )
    },
    header: '',
  }),
]
