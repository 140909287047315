import { useMemo } from 'react'
import { DealTransaction } from 'types'

const DealTransactionsTypes = {
  WIT: 'Withdrawal',
  DEP: 'Deposit',
  BOU: 'Bought',
}

export const getDealTransactionSettings = (dealTransaction?: DealTransaction | null) => {
  const getDealTransactionType = () => {
    if (!dealTransaction) return
    for (const key of Object.keys(DealTransactionsTypes)) {
      if (dealTransaction.reference.indexOf(key) > -1) {
        return key as keyof typeof DealTransactionsTypes
      }
    }
  }

  const determineTransactionTypeSettings = (
    transactionType?: keyof typeof DealTransactionsTypes,
  ) => {
    switch (transactionType) {
      case 'DEP':
        return {
          title: 'Deposit',
          icon: 'deposit',
          color: 'brand.green.500',
          slugKey: 'toCurrency',
        }
      case 'BOU':
        return {
          title: 'Bought',
          icon: 'bought',
          color: 'brand.green.500',
          slugKey: 'fromCurrency',
        }
      default:
        return {
          title: 'Withdrawal',
          icon: 'withdrawal',
          color: 'brand.red.500',
          slugKey: 'toCurrency',
        }
    }
  }

  return determineTransactionTypeSettings(getDealTransactionType())
}

export const useDealTransactionSettings = (dealTransaction?: DealTransaction | null) => {
  return useMemo(() => {
    return getDealTransactionSettings(dealTransaction)
  }, [dealTransaction])
}
