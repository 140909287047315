import { Box, Grid, GridItem } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { MarketplaceDeal } from 'types'
import { currencyFormat } from 'utils/currencyFormat'
import { ExchangeRateRenderer } from './ExchangeRateRenderer'
import { LabelRenderer } from './LabelRenderer'
import { ViewDetails } from './ViewDetails'

export const MobileRenderer: React.FC<{
  data: MarketplaceDeal
  onViewClick: (deal: MarketplaceDeal) => void
}> = ({ data, onViewClick }) => {
  const { t } = useTranslation()

  return (
    <Box pb='0.85rem' height={'10.28rem'}>
      <Box borderRadius={'1.14rem'} boxShadow='0 0 3.42rem rgba(15, 3, 30, 0.08)' bg='white'>
        {data ? (
          <>
            <Grid
              templateColumns={'1fr 1fr'}
              p='1.14rem'
              borderBottom={'solid 0.07rem'}
              borderColor={'brand.black.100'}
            >
              <GridItem>
                <Box fontSize={'1.14rem'} color='brand.black.900' opacity={0.4} fontWeight={500}>
                  {t('tables.mainDeals.headers.exchangeRate')}
                </Box>
                <Box fontSize={'1.14rem'} color='brand.black.900'>
                  <ExchangeRateRenderer
                    fromCurrency={data.fromCurrencySlug}
                    toCurrency={data.toCurrencySlug}
                    rate={data.rate}
                  />
                </Box>
              </GridItem>
              <GridItem>
                <Box fontSize={'1.14rem'} color='brand.black.900' opacity={0.4} fontWeight={500}>
                  {t('tables.mainDeals.headers.amount')}
                </Box>
                <Box fontSize={'1.14rem'} color='brand.black.900'>
                  {currencyFormat(data.amount - +data.totalSold)} {data.fromCurrencySlug}
                </Box>
              </GridItem>
            </Grid>
            <Grid
              templateColumns={'1fr 1fr'}
              pt='0.85rem'
              px='1.14rem'
              alignItems={'center'}
              pb='1.14rem'
            >
              <GridItem>
                <LabelRenderer label={data.rank}></LabelRenderer>
              </GridItem>
              <GridItem justifyContent={'flex-end'} display='flex'>
                <ViewDetails onClick={() => onViewClick(data)} deal={data}></ViewDetails>
              </GridItem>
            </Grid>
          </>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  )
}
