import { TriangleDownIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
} from '@chakra-ui/react'
import React, { FC } from 'react'
import ReactCountryFlag from 'react-country-flag'
import phoneNumberCodes from 'assets/phoneCode.json'
import { PhoneCode } from 'types'

type ExtendedProps = InputProps
interface Props extends ExtendedProps {
  label?: string
  error?: string
  onCodeChange: (phoneCode: PhoneCode) => void
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  code?: PhoneCode
}

export const FSGPhoneInput: FC<Props> = ({ marginBottom, label, code, onCodeChange, ...props }) => {
  return (
    <Box marginBottom={marginBottom} w='full'>
      <FormControl>
        <FormLabel
          as='label'
          display='block'
          fontSize='md'
          fontWeight={800}
          htmlFor={props.id}
          marginBottom='0.57rem'
        >
          {label}{' '}
          {props.isRequired && (
            <Box as='span' color='brand.secondary.red'>
              *
            </Box>
          )}
        </FormLabel>
        <InputGroup
          overflow='hidden'
          border='solid 1px'
          borderRadius='0.57rem'
          borderColor='brand.black.200'
          _hover={{
            borderColor: 'brand.black.900',
          }}
          _focus={{
            borderColor: 'brand.black.900',
          }}
        >
          <InputLeftElement width={'8.92rem'} position={'relative'} cursor={'pointer'}>
            <Flex alignItems={'center'} gap='0.57rem'>
              <Box
                sx={{
                  '.countrySelect': {
                    position: 'absolute',
                    opacity: 0,
                    border: 0,
                    zIndex: 1,
                    top: 0,
                    left: 0,
                    width: 'full',
                    height: 'full',
                    cursor: 'pointer',
                  },
                }}
              >
                <select
                  className='countrySelect'
                  value={code?.dial_code || 'CA'}
                  onChange={(e) => {
                    const phoneCode = phoneNumberCodes.find(
                      (code) => code.dial_code === e.target.value,
                    )
                    if (phoneCode && onCodeChange) onCodeChange?.(phoneCode)
                  }}
                >
                  {phoneNumberCodes.map((phoneCode) => (
                    <option key={phoneCode.code} value={phoneCode.dial_code}>
                      {phoneCode.name} ({phoneCode.dial_code})
                    </option>
                  ))}
                  <option></option>
                </select>
                <Flex
                  alignItems={'center'}
                  cursor={'pointer'}
                  bg='brand.black.200'
                  minH='2.85rem'
                  px={'0.57rem'}
                >
                  <Box mr='0.28rem' fontSize={'1.42rem'}>
                    {code && <ReactCountryFlag countryCode={code.code} />}
                  </Box>
                  <Box fontSize={'1rem'} fontWeight={600} mr='0.28rem'>
                    {code?.code}
                  </Box>
                  <Box fontSize={'0.85rem'}>
                    <TriangleDownIcon></TriangleDownIcon>
                  </Box>
                </Flex>
              </Box>
              <Box pt={'0.07rem'}>{code?.dial_code}</Box>
            </Flex>
          </InputLeftElement>

          <Input
            {...props}
            border='none'
            value={(props.value as { countryCode: string; number: string } | undefined)?.number}
            onChange={props.onChange}
            variant='something'
            pl='4'
          ></Input>
        </InputGroup>
      </FormControl>
    </Box>
  )
}
