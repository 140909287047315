import { Box, Divider, Stack } from '@chakra-ui/react'
import React from 'react'
import { Link, LinkProps } from 'react-router-dom'

interface Props extends LinkProps {
  title: string
  subtitle: string
}

export const PayoutLink: React.FC<Props> = ({ title, subtitle, ...props }) => {
  return (
    <Link {...props}>
      <Stack
        spacing={'0.85rem'}
        sx={{
          _hover: {
            '.link-title': {
              textDecoration: 'underline',
            },
          },
        }}
      >
        <Box className='link-title' fontSize={'1.42rem'} fontWeight={800} color='brand.black.900'>
          {title}
        </Box>
        <Box fontSize={'1rem'} fontWeight={500} color='brand.black.700' opacity={0.8}>
          {subtitle}
        </Box>
        <Divider></Divider>
      </Stack>
    </Link>
  )
}
