import { Box, Divider, Flex, Stack } from '@chakra-ui/react'
import { useDealExchangeRate } from 'hooks'
import { useDealTransactionSettings } from 'hooks/useDealTransactionSettings'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { DealTransaction } from 'types'
import { currencyFormat } from 'utils/currencyFormat'

const billRenderer = (text: string, value: string, discountValue?: string, discount?: number) => {
  return (
    <Flex
      position='relative'
      justifyContent={'space-between'}
      color='brand.black.700'
      lineHeight={'120%'}
      width='100%'
    >
      <Box
        position={'absolute'}
        width='100%'
        borderStyle={'dashed'}
        borderWidth='0.07rem'
        opacity={0.5}
        top='50%'
        borderColor={'brand.black.700'}
      ></Box>
      <Box
        fontSize={'1rem'}
        position={'relative'}
        zIndex={3}
        bg='brand.black.100'
        fontWeight={'600'}
        paddingRight={'0.57rem'}
      >
        {text}
      </Box>
      <Box
        fontSize={'1rem'}
        position={'relative'}
        zIndex={3}
        fontWeight='800'
        bg='brand.black.100'
        paddingLeft={'0.57rem'}
        paddingRight='0.14rem'
      >
        {discount ? (
          <Stack direction='row' spacing='0.14rem'>
            <Box as='span' textDecoration='line-through'>
              {discountValue}
            </Box>
            <Box>{value}</Box>
          </Stack>
        ) : (
          value
        )}
      </Box>
    </Flex>
  )
}

interface Props {
  transactionDetails: DealTransaction
}

export const DealTransactionDetails: FC<Props> = ({ transactionDetails }) => {
  const { t } = useTranslation()

  const mainCurrency = useDealExchangeRate(
    transactionDetails.deal.toCurrency.slug,
    transactionDetails.deal.fromCurrency.slug,
    transactionDetails.deal.rate,
  )
  const dealTransactionSettings = useDealTransactionSettings(transactionDetails)

  return (
    <Box bg='brand.black.100' borderRadius={'1.14rem'} p={['0.85rem', '1.14rem']}>
      <Box fontWeight={800} fontSize='1.28rem' color={'brand.black.900'} mb='1.14rem'>
        {t('wallet.transactionDetails')}
      </Box>
      <Stack fontSize={'1rem'} spacing='1.14rem'>
        {billRenderer(t('createDeal.exchangeRate'), mainCurrency)}
        {billRenderer(
          t('createDeal.dealAmount'),
          `${currencyFormat(transactionDetails.deal.amount)} ${
            transactionDetails.deal.fromCurrency.slug
          }`,
        )}
        {billRenderer(
          t('createDeal.commission'),
          `${transactionDetails.deal.commission} ${transactionDetails.deal[dealTransactionSettings.slugKey as 'fromCurrency' | 'toCurrency'].slug}`,
          `${transactionDetails.deal.discount} ${transactionDetails.deal[dealTransactionSettings.slugKey as 'fromCurrency' | 'toCurrency'].slug}`,
          transactionDetails.deal.discount,
        )}
      </Stack>
      <Divider my='1.14rem' borderColor={'brand.black.200'} opacity='1'></Divider>
      <Flex
        justifyContent={'space-between'}
        fontWeight={800}
        fontSize='1.28rem'
        color={'brand.black.900'}
      >
        <Box>
          {transactionDetails.reference.includes('SOL')
            ? t('createDeal.receiveAmount')
            : t('createDeal.payedAmount')}
        </Box>
        <Box>
          {currencyFormat(transactionDetails.amount)} {transactionDetails.deal[dealTransactionSettings.slugKey as 'fromCurrency' | 'toCurrency'].slug}
        </Box>
      </Flex>
    </Box>
  )
}
