import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          // here we will place our translations...
          completeRegistration: {
            title: 'Set up your account',
            fillYourLegalName: 'Fill your legal name',
            createPayoutAccounts: 'Create payout account',
            completeKYC: 'Complete KYC',
            verifyYourEmail: 'Verify your email',
            verifyYourPhoneNumber: 'Verify phone number',
          },
          general: {
            and: 'and',
          },
          links: {
            home: 'Home',
            wallet: 'Wallet',
            deals: 'My deals',
            settings: 'Settings',
            howToUse: 'How to use',
            logOut: 'Log out',
          },
          buttons: {
            save: 'Save',
            edit: 'Edit',
            cancel: 'Cancel',
            close: 'Close',
            changePassword: 'Change password',
            forgotPassword: 'Forgot password',
            signUp: 'Sign up',
            confirm: 'Confirm',
            resetPassword: 'Reset password',
            back: 'Back',
            createDeal: 'Create new deal',
            addNewAccount: 'Add new account',
            delete: 'Delete',
            exchange: 'Exchange',
            verify: 'Verify',
          },
          passwordStrength: {
            title: 'Your password must contain',
            length: 'At least 10 characters',
            upperCase: 'Upper case letters',
            numbers: 'Numbers',
            symbol: 'Symbol',
          },
          fields: {
            firstName: {
              label: 'Given name(s)',
              placeholder: 'Enter your given name(s)',
            },
            middleName: {
              label: 'Middle name',
              placeholder: 'Enter your middle name',
            },
            lastName: {
              label: 'Last name',
              placeholder: 'Enter your last name',
            },
            displayName: {
              label: 'Display name',
              placeholder: 'Enter your display name',
            },
            name: {
              label: 'Display name',
              placeholder: 'Enter your display name',
            },
            email: {
              label: 'Email',
              placeholder: 'Enter your email',
            },
            phoneNumber: {
              label: 'Phone number',
              placeholder: 'Enter your phone number',
            },
            password: {
              label: 'Password',
              placeholder: 'Enter your password',
            },
            repeatPassword: {
              label: 'Repeat password',
              placeholder: 'Enter your password again',
            },
            repeatNewPassword: {
              label: 'Repeat new password',
              placeholder: 'Enter your password',
            },
            newPassword: {
              label: 'New password',
              placeholder: 'Enter your password',
            },
            currentPassword: {
              label: 'Current password',
              placeholder: 'Enter your current password',
            },
            bankAccount: {
              label: 'Bank account',
              placeholder: 'Enter your bank account',
            },
            bank: {
              label: 'Bank',
              placeholder: 'Enter your bank name',
            },
            accountName: {
              label: 'Account name',
              placeholder: 'Enter account name',
            },
          },
          signIn: {
            title: 'Sign in',
            description: 'Welcome again! Choose the way you want to login.',
            noAccount: "Don't have an account?",
          },
          signUp: {
            title: 'Sign up',
            description: 'Welcome! Choose the way you want to get started.',
            agreement: 'By signing up, you agree to our',
            termsOfService: 'Terms of Service',
            privacyPolicy: 'Privacy Policy',
            haveAccount: 'Already have an account?',
          },
          forgotPassword: {
            title: 'Forgot password',
            description: `Enter the email you used to join, and we'll send you instructions to reset your password`,
          },
          resetPassword: {
            title: 'Reset password',
            description: 'Enter the new password you will use',
            successModalTitle: 'Password has been reset',
          },
          settings: {
            title: 'Settings',
            'personal-info': {
              title: 'Personal info',
              description: 'Edit and review all your personal details',
              kyc: 'KYC status',
              infoTitle: 'Which details can be edited?',
              infoDescription: `You can edit any of your details; however, if you edit your name, you will be
                required to redo the KYC process, and will not be able to do any transactions on the
                platform until it is completed.`,
            },
            security: {
              title: 'Login and Security',
              description: 'Update your password and secure your account',
              infoTitle: 'Security',
              infoDescription: `We’re always working on ways to increase safety in our community. That’s why we look at every account to make sure it’s as secure as possible.
The security of your data is important to us. Do not share your login details with anyone. FSG Xchange will never ask for such information by text or email.`,
              passwordSuccessModal: {
                title: 'Password changed',
                subTitle: 'Yor password has been successfully changed!',
              },
            },
            payouts: {
              title: 'Payouts',
              description: 'Review payments, payouts, coupons, and taxes',
              infoTitle: 'When to expect your payout',
              infoDescription: `Processing times may vary: Expect NGN payments within hours; CAD payments between 30 minutes and 4 hours.`,
              cad: {
                title: 'Email address - CAD',
                description: 'For transactions in CAD',
              },
              ngn: {
                title: 'Bank account - NGN',
                description: 'For transactions in Naira',
              },
              infoText:
                'A payout account is a designated bank account or payment method where funds are deposited or transferred as payments. ',
              completeKYCInfoText: 'Complete <1>{{kycLink}}</1> to add a payout account',
              kyc: 'KYC',
            },
            notifications: {
              title: 'Notifications',
              description: 'Choose notification preferences and how you want to be contacted',
              email: 'I want to receive emails',
              sms: 'I want to receive text messages',
            },
            faq: {
              title: 'FAQ',
              description: 'View the Frequently Asked Questions at Xchange',
            },
            documents: {
              title: 'Documents',
              description: 'Review Legal Documents and Help Docs ',
              howToUse: {
                title: 'How to use',
                description:
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra nunc egestas in.',
              },
              terms: {
                title: 'Terms & conditions',
                description:
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra nunc egestas in.',
              },
              tips: {
                title: 'Tips',
                description:
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra nunc egestas in.',
              },
            },
          },
          mainPage: {
            buttonSign:
              'Browse through existing deals below first. If you don’t see any you like - create your own.',
            tableTitle: 'These are deals available if you want {{currency}}',
            acceptDeal: 'Accept {{slug}} from',
            acceptSubtitle: 'To proceed, enter the amount you want to buy from this deal',
            exchangeRate: 'Exchange rate',
            convertedAmount: 'Converted amount',
            commission: 'FSG Commission',
            totalAmount: 'Total amount you will pay',
            amountWillReceive: 'Amount you will receive',
            dealAvailableAmount: 'Deal amount',
            discount: 'Discount',
          },
          createDeal: {
            title: 'Create deal',
            description: 'Chose the amount and the exchange rate you want to create deal',
            rangeInputTitle: 'Choose the exchange rate',
            transactionDetails: 'Transaction details',
            exchangeRate: 'Exchange rate',
            dealAmount: 'Deal amount',
            commission: 'FSG Commission',
            discount: 'Discount',
            payAmount: 'Total amount you will pay',
            receiveAmount: 'Amount you will receive',
            payedAmount: 'Amount you will pay',
            createDeal: 'Create new deal',
            dealCreated: 'Your deal has been created',
            infoText:
              'Tip: To close your deal faster, take a look at the exchange rates being offered by other customers, and price your deal competitively',
            modal: {
              confirm: {
                title: "You're about to create a Deal",
                text: 'Once you create the deal, it will be available to all buyers in the marketplace.',
              },
            },
          },
          transactions: {
            accountName: 'Account name',
            accountNumber: 'Account number',
            paymentReference: 'Payment reference',
            fee: 'Transaction fee',
          },
          tables: {
            mainDeals: {
              headers: {
                offeredBy: 'Offered by',
                exchangeRate: 'Exchange rate',
                rank: 'Rank',
                amount: 'Amount',
                sold: 'Sold',
              },
              viewDetails: 'View',
            },
            transactions: {
              headers: {
                transaction: 'Transaction',
                amount: 'Amount',
                exchangeRate: 'Exchange rate',
                date: 'Date',
                id: 'ID',
                fee: 'Transfer fee',
              },
            },
          },
          wallet: {
            withdraw: 'Withdraw',
            title: 'Wallet',
            withdrawFunds: 'Withdraw funds',
            addFunds: 'Add funds',
            walletTransactions: 'Wallet transactions',
            dealsTransactions: 'Deals transactions',
            details: 'Details',
            transactionDetails: 'Transaction details',
            proceedWithdraw: 'To proceed, write the amount you want to withdraw ',
            willRemain: 'Will remain',
            transferFee: 'Transfer fee',
            totalAmount: 'Total amount',
            commissionFee: 'Commission fee',
            willWithdraw: 'You will withdraw',
            infoWithdraw: 'It should be on your account in 2 working days',
            noWallet: 'Oops! You have no wallet for this currency.',
            noPayoutAccount:
              'You do not have a payout account set up. Please update your account settings to receive payments.',
            verify: 'Verify',
            addingFunds: 'Adding funds',
            proceedAddingFunds: 'To proceed, write the amount you want to add ',
            addingInstructions: {
              title: 'Instructions',
              text: 'To add <1>{{name}}</1> on your wallet, you will need to send the amount you chose to the {{account}} below {{additionalText}}:',
              bank: 'Bank',
              account: 'Bank Account',
              noAccount:
                'You do not have <1>{{currency}}</1> account yet, please go to Settings page to setup',
              doneButton: 'OK',
              promise: 'It should be on your wallet in 20 minutes',
              textCad:
                'To add <1>{{name}}</1> on your wallet, you will need to send the amount and you will receive instructions on how to complete the transaction via email.',
            },
          },
          myDeals: {
            title: 'My deals',
            links: {
              inProgress: 'In progress',
              completed: 'Completed',
            },
            progressTitle: 'These are deals still in progress',
            completedTitle: 'These are completed deals',
          },
        },
      },
    },
  })

export default i18n
