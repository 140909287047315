import { Box, FormControl, FormLabel, HStack, InputGroup, Select } from '@chakra-ui/react'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { banksSelector } from 'store/selectors/currencies'

interface Props {
  onChange?: React.ChangeEventHandler<HTMLSelectElement>
  value?: string
  label?: string
  name?: string
}

export const FSGBankSelect: FC<Props> = ({ onChange, value, label, name }) => {
  const banks = useSelector(banksSelector)
  const { t } = useTranslation()

  return (
    <FormControl>
      <FormLabel
        as='label'
        display='block'
        fontSize='md'
        fontWeight={800}
        htmlFor={'account.destinationBankUUID'}
        marginBottom='0.57rem'
      >
        <HStack justifyContent={'space-between'}>
          <Box>{label || t('fields.bank.label')}</Box>
        </HStack>
      </FormLabel>
      <InputGroup>
        <Select
          placeholder={label || t('fields.bank.placeholder')}
          name={name}
          id='account.destinationBankUUID'
          onChange={onChange}
          value={value}
        >
          {banks.map((val) => (
            <option value={val.uuid} key={val.uuid}>
              {val.name}
            </option>
          ))}
        </Select>
      </InputGroup>
    </FormControl>
  )
}
