import { Box, Button, Flex, Grid, Stack } from '@chakra-ui/react'
import { CADIcon, NGNIcon } from 'assets/icons'
import { FSGInput } from 'components'
import { FormikProvider, useFormik } from 'formik'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootStore } from 'store'
import { useCreateAccountMutation, useUpdateAccountMutation } from 'store/services/user.api'
import { Account, CreateAccountModel } from 'types'
import { FSGBankSelect } from './FSGBankSelect'
import { AmplitudeEvents, useTrackAction } from 'pages/MainLayout/AmplitudeProvider'

interface Props {
  onCancel: () => void
  createNew: boolean
  account: Account | null
  currencySlug: string
}

export default function BasePayoutForm({ onCancel, createNew, account, currencySlug }: Props) {
  const { t } = useTranslation()
  const track = useTrackAction()
  const [createAccount, { isLoading: isCreating }] = useCreateAccountMutation()
  const [updateAccount, { isLoading: isUpdating }] = useUpdateAccountMutation()
  const isLoading = useMemo(() => isCreating || isUpdating, [isCreating, isUpdating])

  const activeCurrencySlug = createNew ? currencySlug : account?.currency.slug

  const supportedCurrencies = useSelector((state: RootStore) => state.currency.supported)
  const payoutRequirements = useSelector((state: RootStore) => state.currency.payoutRequirements)

  const currencyId = supportedCurrencies.find((curr) => curr.slug === activeCurrencySlug)?.id || ''
  const payoutRequirement = payoutRequirements?.[currencyId]?.payoutRequirement

  const defaultFormikValues = Object.keys(payoutRequirement).reduce((acc, key) => {
    return {
      ...acc,
      [key]: account?.account[key] || '',
    }
  }, {})

  const formik = useFormik<CreateAccountModel>({
    initialValues: {
      currencyId: currencyId,
      account: defaultFormikValues,
    },
    onSubmit: async (value) => {
      const body = {
        ...value,
        account: Object.fromEntries(
          Object.entries(value.account).filter(([key]) => key in payoutRequirement),
        ),
      }
      let resp
      if (createNew || !account) {
        resp = await createAccount({ ...body, currencyId })
      } else {
        resp = await updateAccount({
          body: { ...body, currencyId },
          accountId: account.id,
        })
      }
      if ('data' in resp) {
        track?.(
          createNew ? AmplitudeEvents.CREATE_PAYOUT_ACCOUNT : AmplitudeEvents.EDIT_PAYOUT_ACCOUNT,
        )
        onCancel()
      }
    },
  })

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <Box>
          <Box mb='1.14rem'>
            {activeCurrencySlug === 'CAD' ? (
              <CADIcon width={'2.5rem'} height='1.78rem' />
            ) : (
              <NGNIcon width={'2.5rem'} height='1.78rem' />
            )}
          </Box>
          <Flex justifyContent={'space-between'} mb='0.57rem'>
            <Box as='h4' textStyle={'cardTitle'}>
              {t(`settings.payouts.${currencySlug === 'CAD' ? 'cad' : 'ngn'}.title`)}
            </Box>
          </Flex>
          <Box textStyle={'cardSubtitle'} mb='1.14rem'>
            {t(`settings.payouts.${currencySlug === 'CAD' ? 'cad' : 'ngn'}.description`)}
          </Box>
          <Stack spacing={'1.71rem'}>
            {Object.keys(payoutRequirement).map((key) => {
              if (key === 'destinationBankUUID') {
                return (
                  <FSGBankSelect
                    key={key}
                    label={payoutRequirement[key]}
                    onChange={formik.handleChange}
                    value={formik.values.account?.[key]}
                    name={`account.${key}`}
                  />
                )
              }
              return (
                <FSGInput
                  key={key}
                  label={payoutRequirement[key]}
                  placeholder={payoutRequirement[key]}
                  name={`account.${key}`}
                  type={key === 'email' ? 'email' : 'text'}
                  onChange={formik.handleChange}
                  value={formik.values.account?.[key]}
                  isRequired
                />
              )
            })}

            <Grid templateColumns={'1fr 1fr'} gap='1.14rem'>
              <Button variant='outline' w='full' onClick={onCancel} isLoading={isLoading}>
                {t('buttons.cancel')}
              </Button>
              <Button w='full' type='submit' isLoading={isLoading}>
                {t('buttons.save')}
              </Button>
            </Grid>
          </Stack>
        </Box>
      </form>
    </FormikProvider>
  )
}
