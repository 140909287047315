import { Box, Button, useDisclosure, useEditable } from '@chakra-ui/react'
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import {
  AuthError,
  AuthErrorCodes,
  OTPType,
  PurposeType,
  UserSignIn,
  UserSignInResponse,
  UserSignUp,
} from 'types'
import { FSGInput, FSGLink } from 'components'
import { FSGPasswordInput } from 'components/FSGPasswordInput'
import * as Yup from 'yup'
import 'yup-phone'
import { useSignInMutation, useVerifySignUpMutation } from 'store/services/auth.api'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'store'
import { setToken, setRefreshToken } from 'store/slices/auth'
import { useNavigate } from 'react-router-dom'
import { VerifyModal } from 'components/modals/VerifyModal'
import { useTranslation } from 'react-i18next'
import { VerificationContext } from 'App'
import { AmplitudeEvents, useTrackAction } from 'pages/MainLayout/AmplitudeProvider'

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().required('Required'),
})

export const SignInForm: FC = () => {
  const { t } = useTranslation()
  const track = useTrackAction()
  const dispatch = useDispatch<AppDispatch>()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const navigate = useNavigate()
  const { setOnConfirm } = useContext(VerificationContext)

  const handleSuccess = useCallback((userResponse: UserSignInResponse) => {
    localStorage.setItem('token', userResponse.accessToken)
    localStorage.setItem('refreshToken', userResponse.refreshToken)
    dispatch(setToken(userResponse.accessToken))
    dispatch(setRefreshToken(userResponse.refreshToken))
    navigate('/')
  }, [])

  const [loginUser, { isLoading, error }] = useSignInMutation()

  const [userId, setUserId] = useState<string | null>(null)

  const formik = useFormik<UserSignIn>({
    initialValues: {
      email: '',
      password: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    validationSchema: () => validationSchema,
    onSubmit: async (value) => {
      try {
        const response = await loginUser(value)
        if ('data' in response) {
          if (response.data.accessToken && response.data.refreshToken) {
            const user = response.data
            track?.(AmplitudeEvents.SIGN_IN)
            handleSuccess(user)
          }
        }
        if ('error' in response) {
          const error = (response.error as AuthError).data
          if (error.code === AuthErrorCodes.UNVERIFIED_EMAIL) {
            setUserId(error.data.user.id)
            onOpen()
          }
        }
      } catch (e) {
        console.error(e)
      }
    },
  })
  const { values, handleChange, handleSubmit, errors, touched, submitForm } = formik

  const errorMessage = useMemo(() => {
    if (error && 'data' in error) {
      return (error as AuthError).data.message
    }
  }, [error])

  useEffect(() => {
    setOnConfirm?.(handleSuccess)
    return () => setOnConfirm?.(undefined)
  }, [])

  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit}>
        <FSGInput
          label={t('fields.email.label')}
          value={values.email}
          name='email'
          id='email'
          type='email'
          onChange={handleChange}
          autoComplete='off'
          isInvalid={!!errors.email}
          placeholder={t('fields.email.placeholder')}
          marginBottom='1.71rem'
          isDisabled={isLoading}
          error={errors.email}
          isRequired
        />
        <FSGPasswordInput
          label={t('fields.password.label')}
          value={values.password}
          name='password'
          id='password'
          type='password'
          onChange={handleChange}
          autoComplete='off'
          showPassword={true}
          isInvalid={!!errors.password}
          placeholder={t('fields.password.placeholder')}
          marginBottom='1.71rem'
          isDisabled={isLoading}
          error={errors.password}
          isRequired
          link={<FSGLink to='/forgot-password'>{t('buttons.forgotPassword')}</FSGLink>}
        />

        <Box fontSize={'md'} marginBottom='2.28rem' color={'brand.black.700'}>
          {t('signIn.noAccount')}{' '}
          <FSGLink to='/sign-up' fontWeight={700}>
            {t('signUp.title')}
          </FSGLink>
        </Box>
        <Button
          variant='solid'
          w='full'
          size={'lg'}
          type='submit'
          marginBottom='1.71rem'
          isLoading={isLoading}
        >
          Sign in
        </Button>
        <Box textStyle={'p1'} color='brand.secondary.red' display={'flex'}>
          {touched ? errorMessage : ''}
        </Box>
      </form>
    </FormikProvider>
  )
}
