import { HStack } from '@chakra-ui/react'
import { ChevronIcon } from 'assets/icons'
import { useDprWithOs } from 'hooks/useDprWithOs'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

interface Props {
  to?: string
}

export const BackLink: FC<Props> = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dpr = useDprWithOs()
  const iconSize = 12 / dpr

  return (
    <HStack
      onClick={() => navigate(-1)}
      textStyle='h4'
      color={'brand.black.700'}
      alignItems='center'
      cursor={'pointer'}
    >
      <ChevronIcon width={iconSize} height={iconSize}></ChevronIcon>
      <span>{t('buttons.back')}</span>
    </HStack>
  )
}
