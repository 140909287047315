import {
  Box,
  Button,
  Flex,
  Stack,
  Image,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import { DataTable } from 'components/DataTable'
import { FilterButton } from 'components/FilterButton'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createColumnHelper } from '@tanstack/react-table'
import { WalletTransaction } from 'types'
import i18n from 'i18n'
import { currencyFormat } from 'utils/currencyFormat'
import moment from 'moment'
import { TransactionRenderer } from '../TransactionRenderer'
import { TransactionDetailsModal } from '../TransactionDetails'
import { useSelector } from 'react-redux'
import { walletTransactionsSelector } from 'store/selectors/user'
import { Filters } from '../Filters'
import noDataImage from 'assets/images/no-records.svg'
import { ExchangeRateRenderer } from 'pages/HomePage/DealsTable/ExchangeRateRenderer'
import { ScrollContext } from 'pages/MainLayout/ScrollContext'
import { MobileRenderer } from './MobileRenderer'
import { WalletTransactionDetails } from './WalletTransactionDetails'
import { AmplitudeEvents, useTrackAction } from 'pages/MainLayout/AmplitudeProvider'
import { useDevicePixelRatio } from 'hooks/useDevicePixelRatio'
import { useDeviceOs } from 'hooks/useDeviceOs'
import { useInfiniteScroll } from 'hooks/useInfiniteScroll'
import { useGetWalletTransactionsMutation } from 'store/services/user.api'
import { get } from 'lodash'

const columnHelper = createColumnHelper<WalletTransaction>()
const createColumnDefs = (onDetailsClick: (deal: WalletTransaction) => void) => [
  columnHelper.accessor(
    (props) => {
      if (!props) return null
      return {
        remarkType: props.type,
      }
    },
    {
      header: i18n.t('tables.transactions.headers.transaction') as string,
      enableSorting: false,
      cell: (props) => {
        const value = props.getValue()
        return value ? <TransactionRenderer transactionType={value.remarkType} /> : <></>
      },
    },
  ),
  columnHelper.accessor(
    (props) => {
      if (!props) return null
      return {
        amount: props.amount,
        currency: props.wallet.currency.slug,
      }
    },
    {
      header: i18n.t('tables.transactions.headers.amount') as string,
      enableSorting: true,
      cell: (props) => {
        const value = props.getValue()
        return value ? (
          <div>
            {currencyFormat(+value.amount)} {value.currency}
          </div>
        ) : (
          <></>
        )
      },
    },
  ),
  columnHelper.accessor(
    (props) => {
      if (!props) return null
      return {
        fee: props.transferFee,
        currency: props.wallet.currency.slug,
      }
    },
    {
      header: i18n.t('tables.transactions.headers.fee') as string,
      enableSorting: false,
      cell: (props) => {
        const value = props.getValue()
        return value ? (
          <div>
            {currencyFormat(+value.fee)} {value.currency}
          </div>
        ) : (
          <></>
        )
      },
    },
  ),

  columnHelper.accessor(
    (props) => {
      if (!props) return null
      return {
        date: props.createdAt,
      }
    },
    {
      header: i18n.t('tables.transactions.headers.date') as string,
      enableSorting: false,
      cell: (props) => {
        const value = props.getValue()
        return value ? <div>{moment(value.date).format('MMM DD, YYYY')}</div> : <></>
      },
    },
  ),
  columnHelper.accessor(
    (props) => {
      if (!props) return null
      return {
        reference: props.paymentReference,
      }
    },
    {
      header: i18n.t('tables.transactions.headers.id') as string,
      enableSorting: false,
      maxSize: 200,
      cell: (props) => {
        const value = props.getValue()
        return value ? <div>{value.reference}</div> : <></>
      },
    },
  ),
  columnHelper.accessor((props) => props, {
    id: 'action',
    enableSorting: false,
    cell: (props) => {
      return (
        props.getValue() && (
          <Button
            variant={'link'}
            color='brand.primary.purple'
            onClick={() => onDetailsClick(props.getValue())}
          >
            Details
          </Button>
        )
      )
    },
    header: '',
  }),
]

export const WalletTransactionsTable = () => {
  const { t } = useTranslation()
  const track = useTrackAction()
  const pixelRatio = useDevicePixelRatio({ maxDpr: +Infinity, round: false })
  const os = useDeviceOs()
  const { addHandler, removeHandler } = useContext(ScrollContext)
  const transactions = useSelector(walletTransactionsSelector)
  const screenSize = useBreakpointValue({ base: 'mobile', lg: 'desktop' }, { ssr: false })
  const { isOpen: isDetailsOpen, onClose: onDetailsClose, onOpen: onDetailsOpen } = useDisclosure()
  const pageRef = useRef<number>(1)
  const [currentDeal, setCurrentDeal] = useState<WalletTransaction | null>(null)
  const [
    getWalletTransactions,
    { data: dealsTransactions, isLoading: isDealsTransactionsLoading },
  ] = useGetWalletTransactionsMutation()

  const handleScroll = useInfiniteScroll<{ transactions: WalletTransaction[] }>(
    pageRef,
    isDealsTransactionsLoading,
    dealsTransactions,
    () => {
      getWalletTransactions({
        page: pageRef.current,
        size: 10,
        isMobile: screenSize === 'mobile',
      })
    },
  )
  // )
  const handleDetailsClick = useCallback((data: WalletTransaction) => {
    setCurrentDeal(data)
    onDetailsOpen()
  }, [])

  useEffect(() => {
    track?.(AmplitudeEvents.VIEW_WALLET_TRANSACTIONS)
    getWalletTransactions({
      page: pageRef.current,
      size: 10,
      isMobile: screenSize === 'mobile',
    })
    addHandler?.('wallet', handleScroll)
    return () => {
      removeHandler?.('wallet')
    }
  }, [])

  return (
    <>
      <Flex gap={'2.28rem'} direction='column'>
        {/* <Flex justifyContent={'flex-end'}>
          <FilterButton>
            <Filters ref={ref}></Filters>
          </FilterButton>
        </Flex> */}

        <DataTable
          boxSx={{
            boxShadow: '0 0 5.14rem rgba(15, 3, 30, 0.08)',
            borderRadius: [0, '1.71rem'],
            background: ['transparent', 'white'],
            px: {
              base: '1.42rem',
              md: 0,
            },
            mx: {
              base: '-1.42rem',
              md: 0,
            },
            overflow: {
              base: 'hidden',
              md: 'auto',
            },
          }}
          isFetching={isDealsTransactionsLoading}
          height={{
            base: 'none',
            md: pixelRatio > 1 && os === 'Windows' ? 'calc(100vh - 100px)' : 'calc(100vh - 415px)',
          }}
          data={screenSize !== 'mobile' ? dealsTransactions?.transactions || [] : transactions}
          meta={dealsTransactions?.meta}
          columns={createColumnDefs(handleDetailsClick)}
          variant='transactions'
          mobileRenderer={(data: WalletTransaction) => (
            <MobileRenderer dealTransaction={data} onClick={handleDetailsClick} />
          )}
          onScroll={handleScroll}
          onPaginationChange={(page) => {
            if (page !== pageRef.current) {
              pageRef.current = page
              getWalletTransactions({
                page,
                size: 10,
                isMobile: screenSize === 'mobile',
              })
            }
          }}
          noDataComponent={
            <Stack spacing={'1.14rem'}>
              <Image src={noDataImage} h='8rem'></Image>
              <Box color='brand.primary.purple' fontSize={'1.14rem'}>
                No transactions found
              </Box>
            </Stack>
          }
        ></DataTable>
      </Flex>

      <WalletTransactionDetails
        isOpen={isDetailsOpen}
        onClose={() => {
          setCurrentDeal(null)
          onDetailsClose()
        }}
        transaction={currentDeal}
      ></WalletTransactionDetails>
    </>
  )
}
