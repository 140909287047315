import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Bank, Currency, PayoutRequirement } from 'types'

interface CurrencyState {
  supported: Currency[]
  payoutRequirements: Record<string, PayoutRequirement>
  banks: Bank[]
}

const initialState = {
  supported: [],
  payoutRequirements: {},
  banks: [],
} as CurrencyState

const currencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    setSupportedCurrencies: (
      state: CurrencyState,
      action: PayloadAction<{ currencies: Currency[] }>,
    ) => {
      return {
        ...state,
        supported: action.payload.currencies,
      }
    },
    setPayoutRequirement: (
      state: CurrencyState,
      action: PayloadAction<{ currencyId: string; requirement: PayoutRequirement }>,
    ) => {
      return {
        ...state,
        payoutRequirements: {
          ...state.payoutRequirements,
          [action.payload.currencyId]: action.payload.requirement,
        },
      }
    },
    setBank: (state: CurrencyState, action: PayloadAction<Bank[]>) => {
      return {
        ...state,
        banks: action.payload,
      }
    },
  },
})

export const { setSupportedCurrencies, setPayoutRequirement, setBank } = currencySlice.actions
export default currencySlice.reducer
