import { Box, Flex } from '@chakra-ui/react'
import { useDprWithOs } from 'hooks/useDprWithOs'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

export const NavigationLink: FC<{
  linkImage: (size?: number) => React.ReactElement
  linkActiveImage: (size?: number) => React.ReactElement
  to: string
  linkName: string
  variant?: 'mobile' | 'desktop'
}> = ({ linkImage, linkActiveImage, to, linkName, variant = 'desktop' }) => {
  const { t } = useTranslation()
  const isMobile = variant === 'mobile'
  const dpr = useDprWithOs()
  const size = isMobile ? 26 : 20 / dpr
  return (
    <NavLink to={to} end={to === ''}>
      {({ isActive }) => {
        const bg = isActive ? 'rgba(255,255,255, 0.12)' : 'transparent'
        return (
          <Flex
            w='full'
            h={isMobile ? 'full' : '2.85rem'}
            borderRadius={'0.85rem'}
            bg={isMobile ? 'transparent' : bg}
            px='1.14rem'
            alignItems={'center'}
            justifyContent={isMobile ? 'center' : 'flex-start'}
            gap={isMobile ? '0' : '0.85rem'}
            direction={isMobile ? 'column' : 'row'}
          >
            <Box fontSize={'2.14rem'}>{isActive ? linkActiveImage(size) : linkImage(size)}</Box>
            <Box
              color={'white'}
              fontSize={'1.14rem'}
              fontWeight={600}
              opacity={isActive ? 1 : 0.6}
              whiteSpace='nowrap'
            >
              {t(linkName)}
            </Box>
          </Flex>
        )
      }}
    </NavLink>
  )
}
