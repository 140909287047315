import {
  AuthLayout,
  BackLink,
  FSGPasswordInput,
  FSGPhoneInputFormikField,
  PasswordStrengthIndicator,
} from 'components'
import React, { FC, useEffect } from 'react'
import bg from 'assets/images/sign-up-bg.png'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { FormikProvider, useFormik } from 'formik'
import { ResetPasswordModel } from 'types/ResetPassword'
import { Box, Button, Stack, useDisclosure } from '@chakra-ui/react'
import { useResetPasswordMutation } from 'store/services/auth.api'
import { SuccessModal } from 'components/modals/SuccessModal'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { AmplitudeEvents, useTrackAction } from 'pages/MainLayout/AmplitudeProvider'

const validationSchema = Yup.object().shape({
  password: Yup.string().required('Required'),
  passwordConfirm: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('password'), null], "Password don't match"),
})

export const ResetPassword: FC = () => {
  const { t } = useTranslation()
  const track = useTrackAction()
  const { isOpen, onOpen } = useDisclosure()
  const [resetPassword] = useResetPasswordMutation()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const formik = useFormik<ResetPasswordModel>({
    initialValues: {
      verificationToken: searchParams.get('token') || '',
      email: searchParams.get('email') || '',
      password: '',
      passwordConfirm: '',
    },
    validationSchema,
    onSubmit: async (value) => {
      const resp = await resetPassword(value)
      if ('data' in resp) {
        onOpen()
      }
    },
  })
  const { values, handleChange, handleSubmit, errors } = formik

  useEffect(() => {
    if (!searchParams.has('token') || !searchParams.has('email')) {
      navigate('/')
    }
  }, [searchParams])

  return (
    <AuthLayout bg={bg}>
      <FormikProvider value={formik}>
        <Stack spacing={'1.71rem'}>
          <BackLink to='/sign-in'></BackLink>
          <Box textStyle='h1' color='brand.black.900' fontWeight={800} mb='1.71rem'>
            {t('resetPassword.title')}
          </Box>
          <Box fontSize='lg' mb='1.71rem'>
            {t('resetPassword.description')}
          </Box>
          <form onSubmit={handleSubmit}>
            <FSGPasswordInput
              label={t('fields.newPassword.label')}
              value={values.password}
              name='password'
              id='password'
              type='password'
              onChange={handleChange}
              autoComplete='off'
              isInvalid={!!errors.password}
              placeholder={t('fields.newPassword.placeholder')}
              showPassword={true}
              marginBottom='1.71rem'
              isDisabled={false}
            />
            <FSGPasswordInput
              label={t('fields.repeatNewPassword.label')}
              value={values.passwordConfirm}
              name='passwordConfirm'
              id='passwordConfirm'
              type='password'
              onChange={handleChange}
              autoComplete='off'
              isInvalid={!!errors.passwordConfirm}
              placeholder={t('fields.repeatNewPassword.placeholder')}
              showPassword={true}
              marginBottom='1.71rem'
              isDisabled={false}
            />
            <PasswordStrengthIndicator password={values.password} />
            <Box color={'brand.secondary.red'}>{Object.values(formik.errors)}</Box>
            <Button
              variant='solid'
              w='full'
              size={'lg'}
              type='submit'
              marginTop='2.28rem'
              isLoading={false}
            >
              {t('buttons.resetPassword')}
            </Button>
          </form>
        </Stack>
      </FormikProvider>
      <SuccessModal
        isOpen={isOpen}
        onClose={() => navigate('/')}
        title={t('resetPassword.successModalTitle')}
        onConfirm={() => {
          track?.(AmplitudeEvents.RESET_PASSWORD)
          navigate('/')
        }}
      ></SuccessModal>
    </AuthLayout>
  )
}
