import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DealTransaction, PaginationResponse, WalletTransaction } from 'types'

interface WalletState {
  dealTransactions: Array<DealTransaction | any>
  walletTransactions: Array<WalletTransaction | any>
  dealsTransactionsPerPage: Record<string, DealTransaction[]>
  walletTransactionsPerPage: Record<string, WalletTransaction[]>
}

const initialState = {
  dealTransactions: [],
  walletTransactions: [],
  dealsTransactionsPerPage: {},
  walletTransactionsPerPage: {},
} as WalletState

const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setDealsTransactionsPerPage: (
      state: WalletState,
      action: PayloadAction<PaginationResponse<{ transactions: DealTransaction[] }>>,
    ) => {
      const { transactions, meta } = action.payload
      if (transactions.length) {
        return {
          ...state,
          dealsTransactionsPerPage: {
            ...state.dealsTransactionsPerPage,
            [meta.currentPage]: transactions,
          },
        }
      }
    },
    setWalletTransactionsPerPage: (
      state: WalletState,
      action: PayloadAction<PaginationResponse<{ transactions: WalletTransaction[] }>>,
    ) => {
      const { transactions, meta } = action.payload
      if (transactions.length) {
        return {
          ...state,
          walletTransactionsPerPage: {
            ...state.walletTransactionsPerPage,
            [meta.currentPage]: transactions,
          },
        }
      }
    },
    setDealsTransactions: (
      state: WalletState,
      action: PayloadAction<PaginationResponse<{ transactions: DealTransaction[] }>>,
    ) => {
      const { perPage, currentPage } = action.payload.meta
      const { transactions } = action.payload
      const duplicate = [...transactions]

      const start = perPage * (currentPage - 1) > 0 ? perPage * (currentPage - 1) - 1 : 0
      const end = start + perPage - 1
      if (currentPage === 1) {
        return {
          ...state,
          dealTransactions: [...Array(action.payload.meta.total)]
            .map((item) => null)
            .map((_, index) => {
              if (index >= start && index <= end) {
                //const shifted = action.payload.data.shift()

                return duplicate.shift()
              }
              return _
            }),
        }
      }

      return {
        ...state,
        dealTransactions: [...state.dealTransactions].map((_, index) => {
          if (index >= start && index <= end) {
            //const shifted = action.payload.data.shift()
            return duplicate.shift()
          }
          return _
        }),
      }
    },
    setWalletTransactions: (
      state: WalletState,
      action: PayloadAction<PaginationResponse<{ transactions: WalletTransaction[] }>>,
    ) => {
      const { perPage, currentPage } = action.payload.meta
      const { transactions } = action.payload
      const duplicate = [...transactions]

      const start = perPage * (currentPage - 1) > 0 ? perPage * (currentPage - 1) - 1 : 0
      const end = start + perPage - 1
      if (currentPage === 1) {
        return {
          ...state,
          walletTransactions: [...Array(action.payload.meta.total)]
            .map((item) => null)
            .map((_, index) => {
              if (index >= start && index <= end) {
                //const shifted = action.payload.data.shift()

                return duplicate.shift()
              }
              return _
            }),
        }
      }

      return {
        ...state,
        walletTransactions: [...state.walletTransactions].map((_, index) => {
          if (index >= start && index <= end) {
            //const shifted = action.payload.data.shift()
            return duplicate.shift()
          }
          return _
        }),
      }
    },
  },
})

export const {
  setDealsTransactions,
  setWalletTransactions,
  setDealsTransactionsPerPage,
  setWalletTransactionsPerPage,
} = walletSlice.actions
export default walletSlice.reducer
