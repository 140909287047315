import { AccordionButton, Box, Flex } from '@chakra-ui/react'
import { UserIcon } from 'assets/icons'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  title: string
  value?: string
  hideButton?: boolean
  isDisabled?: boolean
  isExpanded?: boolean
  expandable?: boolean
  buttonText?: string
  placeholder?: string
  icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined
    }
  >
  customButton?: React.ReactNode
}

export const AccordionItemContent: FC<Props> = ({
  title,
  value,
  isExpanded,
  isDisabled,
  expandable = true,
  buttonText = 'Edit',
  hideButton = false,
  icon = UserIcon,
  placeholder = '-',
  customButton,
}) => {
  const { t } = useTranslation()

  return (
    <Flex justifyContent={'space-between'} h='5.14rem' px='1.71rem' alignItems={'center'}>
      <Flex h={'2.28rem'} alignItems='center' gap='0.57rem' fontSize='lg'>
        <Flex padding='0.57rem' borderRadius={'0.57rem'} bg='brand.black.100'>
          {React.createElement(icon, {
            fill: isExpanded ? '#0F031E' : '#524A5D',
            width: '1.14rem',
            height: '1.14rem',
          })}
        </Flex>
        <Box
          fontWeight={isExpanded ? 800 : 400}
          color={isExpanded ? 'brand.black.900' : 'brand.black.700'}
        >
          {title}:
        </Box>
        {value || placeholder}
      </Flex>
      {expandable && (
        <AccordionButton disabled={isDisabled}>
          {!hideButton && (
            <Box fontWeight={800} color='brand.primary.purple' fontSize={'1.14rem'}>
              {isExpanded ? t('buttons.cancel') : buttonText}
            </Box>
          )}
        </AccordionButton>
      )}
      {customButton}
    </Flex>
  )
}
