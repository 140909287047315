import { Box, Button, HStack, Select, useDisclosure } from '@chakra-ui/react'
import React, { FC, useCallback, useContext, useEffect, useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import { PhoneNumber, PurposeType, UserSignInResponse, UserSignUp } from 'types'
import { FSGInput, FSGLink, FSGPhoneInputFormikField } from 'components'
import { FSGPasswordInput } from 'components/FSGPasswordInput'
import * as Yup from 'yup'
import 'yup-phone'
import { useSignUpMutation, useVerifySignUpMutation } from 'store/services/auth.api'
import { SuccessModal } from 'components/modals/SuccessModal'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { VerificationContext } from 'App'
import { AmplitudeEvents, useTrackAction } from 'pages/MainLayout/AmplitudeProvider'

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().min(10, 'At least 10 characters').required('Required'),
  passwordConfirm: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('password'), null], "Password don't match"),
  phoneNumber: Yup.object().shape({
    number: Yup.string().required('Required'),
    countryCode: Yup.string().required('Required'),
  }),
})

export const SignUpForm: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { setOnConfirm } = useContext(VerificationContext)
  const track = useTrackAction()

  const [userId, setUserId] = useState<string | null>(null)

  const [signUp, { data, error, isLoading }] = useSignUpMutation()
  const { isOpen: isSuccessOpen, onOpen: onSuccessOpen, onClose: onSuccessClose } = useDisclosure()

  const formik = useFormik<UserSignUp>({
    initialValues: {
      name: '',
      email: '',
      phoneNumber: {
        countryCode: '',
        number: '',
      },
      password: '',
      passwordConfirm: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    validationSchema: () => validationSchema,
    onSubmit: async (value) => {
      const resp = await signUp(value)
      if ('error' in resp) {
        console.error(resp.error)
      } else {
        track?.(AmplitudeEvents.SIGN_UP)
        setUserId(resp.data.userId)
      }
    },
  })
  const { values, handleChange, handleSubmit, errors } = formik

  const handleConfirm = useCallback(
    async (data: UserSignInResponse) => {
      if (data) {
        localStorage.setItem('token', data.accessToken)
        localStorage.setItem('refreshToken', data.refreshToken)
        onSuccessOpen()
      }
    },
    [userId],
  )

  const handleSuccessConfirm = useCallback(() => {
    navigate('/')
  }, [])

  useEffect(() => {
    setOnConfirm?.(handleConfirm)
    return () => setOnConfirm?.(undefined)
  }, [handleConfirm])

  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit}>
        <FSGInput
          label={t('fields.name.label')}
          value={values.name}
          name='name'
          id='name'
          type='text'
          onChange={handleChange}
          isInvalid={!!errors.name}
          placeholder={t('fields.name.placeholder')}
          marginBottom='1.71rem'
          isRequired
          error={errors.name}
        />
        <FSGInput
          label={t('fields.email.label')}
          value={values.email}
          name='email'
          id='email'
          type='email'
          onChange={handleChange}
          autoComplete='off'
          isInvalid={!!errors.email}
          placeholder={t('fields.email.placeholder')}
          marginBottom='1.71rem'
          isRequired
          error={errors.email}
        />
        <FSGPasswordInput
          label={t('fields.password.label')}
          value={values.password}
          name='password'
          id='password'
          type='password'
          onChange={handleChange}
          autoComplete='off'
          showPassword={true}
          isInvalid={!!errors.password}
          placeholder={t('fields.password.placeholder')}
          marginBottom='1.71rem'
          isRequired
          error={errors.password}
        />

        <FSGInput
          label={t('fields.repeatPassword.label')}
          value={values.passwordConfirm}
          name='passwordConfirm'
          id='passwordConfirm'
          type='password'
          onChange={handleChange}
          autoComplete='off'
          isInvalid={!!errors.passwordConfirm}
          placeholder={t('fields.repeatPassword.placeholder')}
          marginBottom='1.71rem'
          isRequired
          error={errors.passwordConfirm}
        />
        <HStack>
          <FSGPhoneInputFormikField
            label={t('fields.phoneNumber.label')}
            value={
              values.phoneNumber as (string | number | readonly string[] | undefined) & PhoneNumber
            }
            name='phoneNumber'
            id='phoneNumber'
            type='phone'
            onChange={handleChange}
            autoComplete='off'
            isInvalid={!!errors.phoneNumber}
            placeholder={t('fields.phoneNumber.placeholder')}
            marginBottom='1.71rem'
            isRequired
            onBlur={formik.handleBlur}
            error={errors.phoneNumber?.number}
          />
        </HStack>

        <Box fontSize={'md'} marginBottom='32px' color={'brand.black.700'}>
          {t('signUp.haveAccount')}{' '}
          <FSGLink to='/sign-in' fontWeight={700}>
            {t('signIn.title')}
          </FSGLink>
        </Box>
        <Button
          variant='solid'
          w='full'
          size={'lg'}
          type='submit'
          marginBottom='1.71rem'
          isLoading={isLoading}
        >
          {t('buttons.signUp')}
        </Button>

        <SuccessModal
          isOpen={isSuccessOpen}
          onClose={onSuccessClose}
          onConfirm={handleSuccessConfirm}
          title='Your account was verified'
        />
      </form>
    </FormikProvider>
  )
}
