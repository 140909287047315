import { Grid } from '@chakra-ui/react'
import React, { FC } from 'react'
import { links } from './common/links'
import { NavigationLink } from './common/NavigationLink'

export const BottomNavigation: FC = () => {
  return (
    <Grid templateColumns={'repeat(4, 1fr)'} w='full' height={'full'} zIndex='10'>
      {links.slice(0, -1).map((link, index) => (
        <React.Fragment key={index}>
          {link.to ? <NavigationLink variant='mobile' {...link} /> : null}
        </React.Fragment>
      ))}
    </Grid>
  )
}
