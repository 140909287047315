import React, { useContext, useMemo, useState } from 'react'
import Persona from 'persona'
import { FSGModalWrapper } from 'components'
import { ModalProps } from 'types'
import { Box, Button, Flex, Progress } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { kycStatusSelector } from 'store/selectors/user'
import { CloseIcon } from '@chakra-ui/icons'
import { toast } from 'components/FSGToast'
import { TOAST_ERROR_TIMEOUT } from 'utils/constants'

interface Props extends ModalProps {
  onSuccess?: (inquiryId: string) => void
  refetchData?: () => void
}

export const KYCModal: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState(true)
  const kycStatus = useSelector(kycStatusSelector)
  const inquiryId = useMemo(() => {
    if (kycStatus && 'code' in kycStatus)
      return kycStatus.code === 'KYC_REQUIRED' && kycStatus.data.inquiryId
        ? kycStatus.data.inquiryId
        : undefined
  }, [kycStatus])

  if (!kycStatus) return <></>

  if ('code' in kycStatus) {
    if (kycStatus.code === 'KYC_REQUIRED' || kycStatus.code === 'NEW_INQUIRY') {
      return (
        <FSGModalWrapper {...props} height={['100vh', 'auto']}>
          <Box
            h={['full', '45rem']}
            w='full'
            sx={{
              '& .persona-widget__iframe': {
                height: 'full',
                width: 'full',
              },
            }}
          >
            <Flex justifyContent={'flex-end'}>
              <Button variant={'link'} onClick={props.onClose}>
                <CloseIcon></CloseIcon>
              </Button>
            </Flex>
            <Persona.Inquiry
              environmentId={process.env.REACT_APP_PERSONA_ENVIRONMENT_ID as string}
              templateId={!inquiryId ? process.env.REACT_APP_PERSONA_TEMPLATE_ID : undefined}
              referenceId={kycStatus.data.referenceId}
              sessionToken={
                kycStatus.code === 'NEW_INQUIRY' ? kycStatus.data.sessionToken : undefined
              }
              inquiryId={inquiryId}
              onLoad={() => {
                setLoading(true)
              }}
              onReady={() => {
                setLoading(false)
              }}
              onComplete={({ inquiryId, status, fields }) => {
                if (status === 'completed') {
                  props.onSuccess?.(inquiryId)
                }
              }}
              onError={() => {
                toast({
                  status: 'error',
                  title: 'KYC verification',
                  description: 'KYC verification process has been failed, contact administrator',
                  duration: TOAST_ERROR_TIMEOUT,
                  isClosable: true,
                  position: 'top',
                })
              }}
            />
            {loading && <Progress isIndeterminate colorScheme={'purple'}></Progress>}
          </Box>
        </FSGModalWrapper>
      )
    }
  }
  return <></>
}
