import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react'
import { setDeals, setTotal } from 'store/slices/deals'
import { setAccounts, setNotificationSettings, setUser } from 'store/slices/user'
import { Deal, PaginationResponse } from 'types'
import { makeDealsData } from 'utils/mocks'

type DealsResponse = PaginationResponse<{ data: Deal[] }>
type CustomError = { message: string }

const Q_TY = 221
const perPage = 20
const deals = makeDealsData(Q_TY)

// Define a service using a base URL and expected endpoints
export const dealsFakeApi = createApi({
  reducerPath: 'dealsFakeApi',
  baseQuery: fakeBaseQuery<CustomError>(),
  endpoints: (builder) => ({
    getDeals: builder.query<DealsResponse, number>({
      queryFn(page) {
        if (page > Math.round(Q_TY / perPage)) {
          return {
            error: {
              message: 'Wrong page',
            },
          }
        }

        const start = perPage * (page - 1) > 0 ? perPage * (page - 1) - 1 : 0
        const end = start + perPage

        return {
          data: {
            data: deals.slice(start, end),
            meta: {
              lastPage: 0,
              total: Q_TY,
              from: 0,
              to: 0,
              perPage,
              currentPage: page,
              prevPage: page - 1 > 0 ? page - 1 : null,
              nextPage: page + 1 > Math.round(Q_TY / perPage) ? null : page + 1,
            },
          },
        }
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          dispatch(setDeals(data))
          dispatch(setTotal(data.meta.total))
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
  }),
})

export const { useGetDealsQuery } = dealsFakeApi
