import { Box, ListItem, OrderedList, UnorderedList } from '@chakra-ui/react'
import React from 'react'

export const LegalHeading: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <Box
      as='h2'
      color='brand.primary.purple'
      fontSize={{
        base: '3xl',
        md: '4xl',
      }}
      mt={{
        base: '1rem',
        md: '2rem',
      }}
      fontWeight={'bold'}
      textAlign={'center'}
    >
      {children}
    </Box>
  )
}

export const LegalParagraph: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <Box
      as='p'
      color='brand.black.700'
      fontSize={{
        base: 'sm',
        md: 'md',
      }}
      mt={{
        base: '1rem',
        md: '2rem',
      }}
      textAlign={'left'}
    >
      {children}
    </Box>
  )
}

export const LegalSubHeading: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <Box
      as='h3'
      color='brand.secondary.purpleDark'
      fontSize={{
        base: '2xl',
        md: '3xl',
      }}
      mt={{
        base: '1rem',
        md: '2rem',
      }}
      fontWeight={'bold'}
      textAlign={'left'}
    >
      {children}
    </Box>
  )
}

export const LegalSubSubHeading: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <Box
      as='h4'
      color='brand.secondary.purpleDark'
      fontSize={{
        base: 'xl',
        md: '2xl',
      }}
      mt={{
        base: '1rem',
        md: '2rem',
      }}
      fontWeight={'bold'}
      textAlign={'left'}
    >
      {children}
    </Box>
  )
}

export const LegalUnorderedList: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <UnorderedList
      color='brand.black.700'
      fontSize={{
        base: 'sm',
        md: 'md',
      }}
      mt={{
        base: '1rem',
        md: '2rem',
      }}
      textAlign={'left'}
    >
      {children}
    </UnorderedList>
  )
}

export const LegalLink: React.FC<React.PropsWithChildren<{ href: string }>> = ({
  children,
  href,
}) => {
  return (
    <Box
      as='a'
      href={href}
      color='brand.primary.purple'
      fontSize={{
        base: 'sm',
        md: 'md',
      }}
      mt={{
        base: '1rem',
        md: '2rem',
      }}
      textAlign={'left'}
    >
      {children}
    </Box>
  )
}

export const LegalUnorderedListItem: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <ListItem
      color='brand.black.700'
      fontSize={{
        base: 'sm',
        md: 'md',
      }}
      mt={{
        base: '0.5rem',
        md: '1rem',
      }}
      textAlign={'left'}
    >
      {children}
    </ListItem>
  )
}

export const LegalOrderedList: React.FC<
  React.PropsWithChildren<{ listType?: '1' | 'a' | 'I' | 'A' | 'i' }>
> = ({ children, listType = '1' }) => {
  return (
    <OrderedList
      color='brand.black.700'
      fontSize={{
        base: 'sm',
        md: 'md',
      }}
      mt={{
        base: '1rem',
        md: '2rem',
      }}
      textAlign={'left'}
      type={listType}
    >
      {children}
    </OrderedList>
  )
}

export const LegalOrderedListItem: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <ListItem
      as='li'
      color='brand.black.700'
      fontSize={{
        base: 'sm',
        md: 'md',
      }}
      mt={{
        base: '0.5rem',
        md: '1rem',
      }}
      textAlign={'left'}
    >
      {children}
    </ListItem>
  )
}
