import {
  Accordion,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  ExpandedIndex,
} from '@chakra-ui/react'
import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { userInfoSelector } from 'store/selectors/user'
import { AccordionItemContent } from '../common/AccordionItemContent'
import { Information } from '../common/Information'
import check from 'assets/images/check-security.svg'
import { ChangePassword } from './ChangePassword'
import { ChangePhoneNumber } from './ChangePhoneNumber'
import { KeyIcon, MailIcon, PhoneIcon } from 'assets/icons'
import { SettingsLayout } from '../common/SettingsLayout'
import { useTranslation } from 'react-i18next'
import { RootStore } from 'store'
import { useVerifyEmail } from 'hooks/useVerifyEmail'

export const Security: FC = () => {
  const { t } = useTranslation()
  const userInfo = useSelector(userInfoSelector)
  const [index, setIndex] = useState<ExpandedIndex>([])
  const verifyEmail = useVerifyEmail(userInfo?.id)
  const emailVerified = useSelector((state: RootStore) => state.user.user?.emailVerified)

  return (
    <SettingsLayout
      title={t('settings.security.title')}
      subTitle={t('settings.security.description')}
      templateColumns={{ base: '1fr', lg: 'auto 23.57rem' }}
    >
      <div>
        <Box
          overflow={'hidden'}
          boxShadow='0 0 5.14rem rgba(15, 3, 30, 0.08)'
          borderRadius={'1.14rem'}
        >
          <Accordion allowToggle onChange={(index: ExpandedIndex) => setIndex(index)} index={index}>
            <AccordionItem>
              <AccordionItemContent
                title={t('fields.email.label')}
                value={userInfo?.email}
                isExpanded={false}
                expandable={false}
                icon={MailIcon}
                customButton={
                  !emailVerified ? (
                    <Button
                      fontSize={'1.14rem'}
                      variant={'link'}
                      color='brand.primary.purple'
                      onClick={verifyEmail}
                    >
                      Verify
                    </Button>
                  ) : null
                }
              ></AccordionItemContent>
            </AccordionItem>
            <AccordionItem>
              {({ isExpanded }) => (
                <>
                  <AccordionItemContent
                    title={t('fields.phoneNumber.label')}
                    value={`${userInfo?.phoneNumber.countryCode}${userInfo?.phoneNumber.number}`}
                    isExpanded={isExpanded}
                    icon={PhoneIcon}
                  ></AccordionItemContent>
                  <AccordionPanel>
                    <ChangePhoneNumber onSuccess={() => setIndex([])}></ChangePhoneNumber>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
            <AccordionItem>
              {({ isExpanded }) => (
                <>
                  <AccordionItemContent
                    buttonText={t('buttons.changePassword')}
                    title={t('fields.password.label')}
                    isExpanded={isExpanded}
                    icon={KeyIcon}
                    placeholder='•••••••••'
                  ></AccordionItemContent>
                  <AccordionPanel>
                    <ChangePassword onSuccess={() => setIndex([])}></ChangePassword>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          </Accordion>
        </Box>
      </div>
      <div>
        <Information
          image={check}
          title={t('settings.security.infoTitle')}
          description={t('settings.security.infoDescription')}
        />
      </div>
    </SettingsLayout>
  )
}
