import React from 'react'
import { ForgotPassword, MainLayout, SignIn, SignUp } from 'pages'
import { createBrowserRouter, Navigate } from 'react-router-dom'
import { RequireAuth } from './RequireAuth'
import { ResetPassword } from 'pages/ResetPassword'
import {
  PersonalInfo,
  SettingsMainPage,
  Notifications,
  Security,
  Payouts,
  FAQ,
  Documents,
} from 'pages/Settings'
import { HomePage } from 'pages/HomePage'
import { DealsTable } from 'pages/HomePage/DealsTable'
import { CreateDeal } from 'pages/CreateDeal'
import { Wallet } from 'pages/Wallet'
import { DealsTransactionsTable } from 'pages/Wallet/DealsTransactionsTable'
import { MyDeals } from 'pages/MyDeals'
import { MyDealsTable } from 'pages/MyDeals/MyDealsTable'
import { PayoutAccounts } from 'pages/Settings/Payouts/PayoutAccounts'
import { WalletTransactionsTable } from 'pages/Wallet/WalletTransactions'
import { TermsAndConditions } from 'pages/Legal/TermsAndConditions'
import { PrivacyPolicy } from 'pages/Legal/PrivacyPolicy'
import Maintenance from 'pages/Maintenance'

// take from env
const isMaintenance = process.env.REACT_APP_MAINTENANCE === 'true'

const defaultRoutes = [
  {
    path: '/',
    element: (
      <RequireAuth>
        <MainLayout />
      </RequireAuth>
    ),
    children: [
      {
        path: 'settings',
        children: [
          {
            path: '',
            element: <SettingsMainPage></SettingsMainPage>,
          },
          {
            path: 'personal-info',
            element: <PersonalInfo></PersonalInfo>,
          },
          {
            path: 'notifications',
            element: <Notifications />,
          },
          {
            path: 'security',
            element: <Security />,
          },
          {
            path: 'payouts',
            children: [
              {
                path: '',
                element: <Payouts />,
              },
              {
                path: 'accounts',
                element: <PayoutAccounts></PayoutAccounts>,
              },
            ],
          },
          {
            path: 'faq',
            element: <FAQ />,
          },
          {
            path: 'documents',
            element: <Documents></Documents>,
          },
        ],
      },
      {
        path: 'wallet',
        element: <Wallet />,
        children: [
          {
            path: 'deals-transactions',
            element: <DealsTransactionsTable />,
          },
          {
            path: 'wallet-transactions',
            element: <WalletTransactionsTable />,
          },
          {
            path: '',
            element: <Navigate to='deals-transactions'></Navigate>,
          },
        ],
      },
      {
        path: 'deals',
        element: <MyDeals></MyDeals>,
        children: [
          {
            path: ':dealsStatus',
            element: <MyDealsTable></MyDealsTable>,
          },
        ],
      },
      // {
      //   path: 'how-to-use',
      //   element: <div>My Deals</div>,
      // },
      {
        path: 'main',
        element: <HomePage></HomePage>,
        children: [
          {
            path: ':currency',
            element: <DealsTable />,
          },
        ],
      },
      {
        path: 'create-deal',
        element: <CreateDeal></CreateDeal>,
      },
      {
        path: '/',
        element: <Navigate to={'main'}></Navigate>,
      },
    ],
  },
  {
    path: '/sign-up',
    element: <SignUp />,
  },
  {
    path: '/sign-in',
    element: <SignIn />,
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
  },
  {
    path: '/terms-and-conditions',
    element: <TermsAndConditions />,
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />,
  },
]


export const router = createBrowserRouter(
  isMaintenance
    ? [
      {
        path: '/',
        element: <Maintenance />,
      },
      {
        path: '*',
        element: <Navigate to='/' />,
      }
    ] : defaultRoutes
)
