import { User } from './User'

export interface BaseError {
  error: {
    status: number
    data?: {
      message?: string
      success?: boolean
    }
  }
}

export enum AuthErrorCodes {
  UNVERIFIED_EMAIL = 'UNVERIFIED_EMAIL',
}

export interface AuthError {
  data: {
    code: AuthErrorCodes
    data: {
      user: User
    }
    message: string
    success: boolean
  }
  status: number
}
