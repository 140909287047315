import { Box, Flex, Switch, SwitchProps } from '@chakra-ui/react'
import React, { FC, useState } from 'react'

type Props = SwitchProps & {
  withText?: boolean
}

export const FSGSwitch: FC<Props> = ({ withText, ...props }) => {
  return (
    <Flex alignItems={'center'} gap='0.57rem'>
      <Switch {...props} />
      <Box>{props.isChecked ? 'ON' : 'OFF'}</Box>
    </Flex>
  )
}
