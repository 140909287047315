import { Box, Flex } from '@chakra-ui/react'
import React, { PropsWithChildren } from 'react'

export const NoData: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Flex
      borderRadius={'0.57rem'}
      p='0.57rem'
      fontSize={'1.14rem'}
      fontWeight={800}
      justifyContent='center'
    >
      <Flex justifyContent={'center'} alignItems='center' gap='1.42rem' py='2.84rem' w='full'>
        {children || <Box>No data available</Box>}
      </Flex>
    </Flex>
  )
}
