import React, { FC } from 'react'
import { User } from 'types'
import {
  Avatar,
  Flex,
  List,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Button,
  Box,
  Divider,
} from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { useLogout } from 'hooks'
import { userInfoSelector } from 'store/selectors/user'
import { FSGAvatar } from 'components/FSGAvatar'
import { LogoutMobileIcon } from 'assets/images/linkIcons'
import { WalletInfo } from './WalletInfo'

export const AvatarMenu: FC = () => {
  const logout = useLogout()
  const userInfo = useSelector(userInfoSelector)

  return (
    <Popover>
      <PopoverTrigger>
        <Flex alignItems={'center'} gap='0.28rem'>
          <FSGAvatar text={userInfo?.name || ''} />
          <ChevronDownIcon fontSize={'1.42rem'}></ChevronDownIcon>
        </Flex>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />

        <PopoverHeader>
          <Box textStyle='link' color='brand.black.900'>
            {userInfo?.name}
          </Box>
        </PopoverHeader>
        <PopoverBody>
          <WalletInfo></WalletInfo>
          <Divider my={'0.57rem'}></Divider>
          <List>
            <ListItem py='0.57rem'>
              <Button variant={'link'} onClick={logout} display='flex' gap='0.57rem'>
                <LogoutMobileIcon />
                Logout
              </Button>
            </ListItem>
          </List>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
