import { Box, Button, Stack, useDisclosure } from '@chakra-ui/react'
import { AuthLayout, BackLink, FSGInput } from 'components'
import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react'
import bg from 'assets/images/sign-up-bg.png'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useForgotPasswordMutation, useVerifyForgotPasswordMutation } from 'store/services/auth.api'
import { useLocation, useNavigate, useSearchParams, createSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { userInfo } from 'os'
import { useSelector } from 'react-redux'
import { userInfoSelector } from 'store/selectors/user'
import { VerificationContext } from 'App'
import { AmplitudeEvents, useTrackAction } from 'pages/MainLayout/AmplitudeProvider'

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
})

const ForgotPassword: FC = () => {
  const { t } = useTranslation()
  const track = useTrackAction()
  const navigate = useNavigate()
  const [forgotPassword, { isLoading, error }] = useForgotPasswordMutation()
  const [verifyPassword, { isLoading: isVerificationLoading }] = useVerifyForgotPasswordMutation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const userInfo = useSelector(userInfoSelector)
  const { setOnConfirm } = useContext(VerificationContext)

  const { values, handleChange, handleSubmit, errors, touched } = useFormik<{ email: string }>({
    initialValues: {
      email: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    validationSchema: () => validationSchema,
    onSubmit: async (value) => {
      try {
        const data = await forgotPassword(value)
        if ('data' in data) {
          onOpen()
        }
      } catch (e) {
        console.error(e)
      }
    },
  })
  const errorMessage = useMemo(() => {
    if (error && 'data' in error) {
      return (
        error.data as {
          success: boolean
          message: string
        }
      ).message
    }
  }, [error])

  const handleConfirm = useCallback(
    (data: any) => {
      if ('token' in data) {
        const { token } = data
        track?.(AmplitudeEvents.CHANGE_PASSWORD, { email: values.email })

        navigate({
          pathname: '/reset-password',
          search: `?${createSearchParams({ email: values.email, token })}`,
        })
        // onClose()
      }
    },
    [values],
  )

  useEffect(() => {
    track?.(AmplitudeEvents.FORGOT_PASSWORD)
    setOnConfirm?.(handleConfirm)
    return () => setOnConfirm?.(undefined)
  })

  return (
    <AuthLayout bg={bg}>
      <Stack spacing={'1.71rem'}>
        <BackLink to='/sign-in'></BackLink>
        <Box textStyle='h1' color='brand.black.900' fontWeight={800} mb='1.71rem'>
          {t('forgotPassword.title')}
        </Box>
        <Box fontSize='lg' mb='1.71rem'>
          {t('forgotPassword.description')}
        </Box>
        <form onSubmit={handleSubmit}>
          <FSGInput
            label={t('fields.email.label')}
            value={values.email}
            name='email'
            id='email'
            type='email'
            onChange={handleChange}
            autoComplete='off'
            isInvalid={!!errors.email}
            placeholder={t('fields.email.placeholder')}
            marginBottom='1.71rem'
            isDisabled={isLoading}
            isRequired
          />
          <Button
            variant='solid'
            w='full'
            size={'lg'}
            type='submit'
            marginBottom='1.71rem'
            isLoading={isLoading}
          >
            {t('buttons.confirm')}
          </Button>
          <Box textStyle={'p1'} color='brand.secondary.red' display={'flex'}>
            {touched ? errorMessage : ''}
          </Box>
        </form>
      </Stack>
    </AuthLayout>
  )
}

export default ForgotPassword
