import { InfoIcon } from '@chakra-ui/icons'
import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react'
import React, { FC } from 'react'
import { Deal } from 'types'

interface Props {
  deal: Deal
}

export const InfoPopover: FC<Props> = () => {
  return (
    <Popover>
      <PopoverTrigger>
        <Button minW={'none'} variant={'link'} aria-label='info'>
          <InfoIcon fontSize={'1rem'}></InfoIcon>
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverHeader>Confirmation!</PopoverHeader>
        <PopoverBody>Are you sure you want to have that milkshake?</PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
