import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { Box, Button, Flex, chakra } from '@chakra-ui/react'
import { range } from 'lodash'
import React, { FC } from 'react'
import { PaginationMeta } from 'types'
import { usePagination, DOTS } from './usePagination'

interface Props {
  meta: PaginationMeta
  currentPage: number
  onPaginationChange: (page: number) => void
}

export const PaginationPages: FC<Props> = ({ meta, currentPage, onPaginationChange }) => {
  const pagination = usePagination({ meta })

  if (meta.lastPage === 1 && !pagination) {
    return <Box></Box>
  }
  return (
    <Flex>
      {pagination && (
        <>
          <Button
            variant='link'
            isDisabled={meta.currentPage === 1}
            color='brand.primary.purple'
            _disabled={{
              color: 'brand.black.400',
            }}
            onClick={() => onPaginationChange(meta.currentPage - 1)}
          >
            <ChevronLeftIcon fontSize={'1.42rem'}></ChevronLeftIcon>
          </Button>
          {pagination.map((item, index) => {
            if (item === DOTS) {
              return <chakra.span key={index}>...</chakra.span>
            }
            return (
              <Button
                key={index}
                bg={index + 1 === meta.currentPage ? 'brand.primary.purple' : 'white'}
                color={index + 1 === meta.currentPage ? 'white' : 'brand.black.900'}
                fontWeight={index + 1 === meta.currentPage ? 800 : 400}
                variant='pageButton'
                onClick={() => onPaginationChange(index + 1)}
              >
                {item}
              </Button>
            )
          })}
          <Button
            variant='link'
            isDisabled={meta.currentPage === meta.lastPage || meta.lastPage === 0}
            color='brand.primary.purple'
            _disabled={{
              color: 'brand.black.400',
            }}
            onClick={() => onPaginationChange(meta.currentPage + 1)}
          >
            <ChevronRightIcon fontSize={'1.42rem'}></ChevronRightIcon>
          </Button>
        </>
      )}
    </Flex>
  )
}
