import React, { FC, useEffect, useMemo } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { RootStore } from '../store'
import { setToken } from '../store/slices/auth'
import { InitialLoaderProvider } from 'pages/MainLayout/InitialDataLoaderContext'

export const RequireAuth: FC<{ children: React.ReactNode }> = ({ children }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const token = useSelector((state: RootStore) => state.auth.token)
  const location = useLocation()
  const [isLoggedIn, setIsLoggedIn] = React.useState(false)
  useEffect(() => {
    const tokenFromStorage = localStorage.getItem('token')
    if (tokenFromStorage || token) {
      dispatch(setToken(tokenFromStorage || token))
      setIsLoggedIn(true)
    } else {
      navigate('/sign-in', { state: { from: location }, replace: true })
      setIsLoggedIn(false)
    }
  }, [token, dispatch, navigate])

  return <>{isLoggedIn && <InitialLoaderProvider>{children}</InitialLoaderProvider>}</>
}
