import React, { HTMLAttributes, forwardRef } from 'react'
import ReactDatePicker from 'react-datepicker'
import { InputGroup, Input, InputRightElement, InputProps } from '@chakra-ui/react'
import { CalendarIcon } from '@chakra-ui/icons'

import 'react-datepicker/dist/react-datepicker.css'
import './styles.css'

const customDateInput = ({ value, onClick, onChange }: any, ref: any) => (
  <Input autoComplete='off' value={value} ref={ref} onClick={onClick} onChange={onChange} />
)
customDateInput.displayName = 'DateInput'

const CustomInput = forwardRef(customDateInput)

export interface Props {
  isClearable?: boolean
  onChange: (date: Date) => any
  selectedDate: Date | undefined
  showPopperArrow?: boolean
  name: string
}

export const FSGDatePicker = ({ selectedDate, onChange, isClearable, ...props }: Props) => {
  return (
    <>
      <InputGroup className='dark-theme'>
        <ReactDatePicker
          selected={selectedDate}
          onChange={onChange}
          className='react-datapicker__input-text'
          customInput={<CustomInput />}
          dateFormat='dd MMM yyyy'
          //portalId='root-portal'
          {...props}
        />
        <InputRightElement color='gray.500'>
          <CalendarIcon fontSize='sm' />
        </InputRightElement>
      </InputGroup>
    </>
  )
}
