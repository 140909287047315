// terms and conditions page
import { Box, Container } from '@chakra-ui/react'
import { BackLink } from 'components'
import { Header } from 'pages/MainLayout/Header'
import React from 'react'
import {
  LegalHeading,
  LegalLink,
  LegalOrderedList,
  LegalOrderedListItem,
  LegalParagraph,
  LegalSubHeading,
  LegalSubSubHeading,
  LegalUnorderedList,
  LegalUnorderedListItem,
} from './Typography'

export const PrivacyPolicy = () => {
  return (
    <div>
      <Header noLogin></Header>
      <Container maxW={'1182px'} py={'2rem'} px={'2rem'}>
        <BackLink></BackLink>
        <LegalHeading>FSG XCHANGE PRIVACY POLICY</LegalHeading>
        <LegalParagraph>
          FSG Xchange Inc is a fully registered Canadian business and operates under Canadian laws.
          Canada has two federal privacy laws that are enforced by the Office of the Privacy
          Commissioner of Canada: the Privacy Act, which covers how the federal government handles
          personal information; the Personal Information Protection and Electronic Documents Act
          (PIPEDA), which covers how businesses handle personal information.{' '}
        </LegalParagraph>
        <LegalParagraph>
          PIPEDA regulates how commercial and private-sector companies store, handle, process, and
          share personal data as part of their routine business activities. The purpose of this
          policy therefore is to inform our customers and the public what personal data we will
          collect, and how the data will be used and stored.
        </LegalParagraph>
        <LegalParagraph>
          This Privacy Policy (the “Policy”) represents the policy of FSG Xchange Inc (the
          “Company”) regarding the collection, use, and management of personal data belonging to:
        </LegalParagraph>
        <LegalOrderedList listType='i'>
          <LegalOrderedListItem>All visitors to our Company&apos;s website</LegalOrderedListItem>
          <LegalOrderedListItem>
            Anyone who downloads or uses our Company&apos;s applications, and{' '}
          </LegalOrderedListItem>
          <LegalOrderedListItem>
            Anyone who downloads or uses our Company&apos;s applications, and{' '}
          </LegalOrderedListItem>
        </LegalOrderedList>
        <LegalParagraph>
          To ensure the free, informed, and unambiguous consent of our Users to the collection and
          use of their personal data, we require all Users to read this Policy and accept the terms
          and conditions contained herein before the release of any personal data to us.
        </LegalParagraph>
        <LegalSubHeading>1. What data do we collect?</LegalSubHeading>
        <LegalParagraph>The Company collects the following data on all Users:</LegalParagraph>
        <LegalUnorderedList>
          <LegalUnorderedListItem>
            Personal identification information, such as name, email, address, and phone number
          </LegalUnorderedListItem>
          <LegalUnorderedListItem>
            Information on your browser, IP address, location, and device, including your behavior
            on our website.
          </LegalUnorderedListItem>
          <LegalUnorderedListItem>
            Transaction information, including information related to the use of the Services, types
            of Services requested, dates and times of such requests, payment methods, and other
            similar information.
          </LegalUnorderedListItem>
          <LegalUnorderedListItem>
            Communications data, including messages, comments, or file transmissions.
          </LegalUnorderedListItem>
        </LegalUnorderedList>
        <LegalSubSubHeading>Children and Non-Adults.</LegalSubSubHeading>
        <LegalParagraph>
          Our Services and Businesses are designed and operates only for persons who have attained
          adulthood in their jurisdictions. No underaged persons may provide any information to us.
          We do not knowingly collect personal information from any such persons without
          verification of parental consent.{' '}
        </LegalParagraph>
        <LegalSubHeading>2. How do we collect your data?</LegalSubHeading>
        <LegalParagraph>
          Users directly provide data to us, and we also collect data and processes data when Users:
        </LegalParagraph>
        <LegalUnorderedList>
          <LegalUnorderedListItem>
            Register online or place an order for any of our products or Services.
          </LegalUnorderedListItem>
          <LegalUnorderedListItem>
            Voluntarily complete a customer survey or provide feedback on any of our message boards
            or via email
          </LegalUnorderedListItem>
          <LegalUnorderedListItem>
            Use or view the Site via your browser&apos;s cookies
          </LegalUnorderedListItem>
          <LegalUnorderedListItem>Download or use the Application</LegalUnorderedListItem>
          <LegalUnorderedListItem>
            Submit content to the Site, like reviews, messages, or comments
          </LegalUnorderedListItem>
          <LegalUnorderedListItem>
            Enable access to their device&apos;s location-tracking functionality.
          </LegalUnorderedListItem>
          <LegalUnorderedListItem>
            Clients expressly and directly provide personal data, including payment information, to
            the Company via the Site when enrolling for the Services.
          </LegalUnorderedListItem>
        </LegalUnorderedList>
        <LegalSubHeading>3. How will we use your data?</LegalSubHeading>
        <LegalParagraph>The Company collects data from Users in order to:</LegalParagraph>
        <LegalUnorderedList>
          <LegalUnorderedListItem>
            To meet our obligations under The Financial Transactions and Reports Analysis Centre of
            Canada (FINTRAC) regulations
          </LegalUnorderedListItem>
          <LegalUnorderedListItem>
            To Know our customers and provide the required Services
          </LegalUnorderedListItem>
          <LegalUnorderedListItem>
            To enhance the safety and security of our Users and Services
          </LegalUnorderedListItem>
          <LegalUnorderedListItem>To provide customer support and research.</LegalUnorderedListItem>
          <LegalUnorderedListItem>
            To send marketing and non-marketing communications to users
          </LegalUnorderedListItem>
          <LegalUnorderedListItem>
            To collate and retain relevant data in connection with legal proceedings and regulatory
            audits.
          </LegalUnorderedListItem>
        </LegalUnorderedList>
        <LegalParagraph>
          We do not sell or share user personal data with private third parties for their direct
          marketing, except with users&apos; consent.
        </LegalParagraph>
        <LegalParagraph>
          Clients consent to the release of this information and data to the Company for the purpose
          of the performance of the Company&apos;s Services for the Client&apos;s benefit. This
          release of information and the relationship between the Company and its Clients is subject
          to the User Agreement.
        </LegalParagraph>
        <LegalSubHeading>4. How do we store your data?</LegalSubHeading>
        <LegalParagraph>
          The Company securely stores Client data on servers leased from a reputable third party
          hosting service providers. Data is retained in accordance with the Company&apos;s User
          Agreement.
        </LegalParagraph>
        <LegalSubHeading>5. Data retention and deletion</LegalSubHeading>
        <LegalParagraph>
          The Company retains user profile, transaction, and other information for as long as a user
          maintains their account.
        </LegalParagraph>
        <LegalParagraph>
          Users may request deletion of their account at any time. Following such requests, the
          Company deletes the data that it is not required to be retained for purposes of
          regulatory, tax, insurance, litigation, or other legal requirements.
        </LegalParagraph>
        <LegalSubHeading>6. Data usage and processing</LegalSubHeading>
        <LegalParagraph>
          We collect and use personal data only where we have one or more lawful grounds for doing
          so. Processing of personal data will depend on the type of services we are providing but
          will generally include the following:
        </LegalParagraph>
        <LegalOrderedList>
          <LegalOrderedListItem>
            <Box as='span' fontWeight={'bold'}>
              To provide our Services, we must collect and use certain personal data. These
              includes:
            </Box>
            <LegalUnorderedList>
              <LegalUnorderedListItem>
                User profile data, which we use to establish and maintain User accounts; verify User
                identity; communicate with Users about their orders and accounts; and enable Users
                to make or receive payments.
              </LegalUnorderedListItem>
              <LegalUnorderedListItem>
                Usage data, which is necessary to maintain, optimize, and enhance the Company&apos;s
                Services
              </LegalUnorderedListItem>
              <LegalUnorderedListItem>Transaction information</LegalUnorderedListItem>
              <LegalUnorderedListItem>
                Information relating to customer support
              </LegalUnorderedListItem>
            </LegalUnorderedList>
          </LegalOrderedListItem>
          <LegalOrderedListItem>
            <Box as='span' fontWeight={'bold'}>
              To fulfill the Company&apos;s legal obligations:
            </Box>
            <LegalParagraph>
              We collect and use personal data to comply with applicable laws. The Company may also
              share data with law enforcement regarding criminal acts or threats to public safety,
              or requests by third parties pursuant to legal processes.
            </LegalParagraph>
          </LegalOrderedListItem>
          <LegalOrderedListItem>
            <Box as='span' fontWeight={'bold'}>
              With consent:
            </Box>
            <LegalParagraph>
              The Company may collect and use personal data based on the User&apos;s consent. A User
              who has provided consent to the collection or use of their personal data can revoke it
              at any time. However, the User will not be able to use any Service or feature that
              requires collection or use of that personal data.
            </LegalParagraph>
          </LegalOrderedListItem>
        </LegalOrderedList>
        <LegalSubHeading>7. Data sharing and disclosure</LegalSubHeading>
        <LegalParagraph>The Company may share the data we collect:</LegalParagraph>
        <LegalOrderedList>
          <LegalOrderedListItem>
            <Box as='span' fontWeight={'bold'}>
              With Other Users:
            </Box>
            <LegalParagraph>
              This includes sharing User&apos;s personally identifying information shared on the
              Application, including to such User&apos;s Profile on the Application
            </LegalParagraph>
          </LegalOrderedListItem>
          <LegalOrderedListItem>
            <Box as='span' fontWeight={'bold'}>
              At the User&apos;s request:
            </Box>
            <LegalParagraph>This includes sharing data with:</LegalParagraph>
            <LegalUnorderedList>
              <LegalUnorderedListItem>
                Other people at the User&apos;s request.
              </LegalUnorderedListItem>
              <LegalUnorderedListItem>
                Company business partners. For example, if a User requests a service through a
                partner-ship or promotional offering made by a third party, the Company may share
                certain data with those third parties. This may include, for example, other
                services, platforms, apps or websites that integrate with our APIs.
              </LegalUnorderedListItem>
            </LegalUnorderedList>
          </LegalOrderedListItem>
          <LegalOrderedListItem>
            <Box as='span' fontWeight={'bold'}>
              With the general public:
            </Box>
            <LegalParagraph>
              Questions or comments from Users submitted through public forums such as the Company
              blogs and the Company social media pages may be viewable by the public, including any
              personal data included in the questions or comments submitted by a User.
            </LegalParagraph>
          </LegalOrderedListItem>
          <LegalOrderedListItem>
            <Box as='span' fontWeight={'bold'}>
              With the User account owner:
            </Box>
            <LegalParagraph>
              If an account is operated by a User other than the registered User account owner, we
              will share information with the registered User account owner.
            </LegalParagraph>
          </LegalOrderedListItem>
          <LegalOrderedListItem>
            <Box as='span' fontWeight={'bold'}>
              With the Company subsidiaries and affiliates:
            </Box>
            <LegalParagraph>
              We share data with our subsidiaries and affiliates to help us provide our services or
              conduct data processing on our behalf. For example, the Company processes and stores
              data in the United States on behalf of its international subsidiaries and affiliates.
            </LegalParagraph>
          </LegalOrderedListItem>
          <LegalOrderedListItem>
            <Box as='span' fontWeight={'bold'}>
              With the Company service providers and business partners:
            </Box>
            <LegalParagraph>
              The Company provides data to vendors, consultants, marketing partners, research firms,
              and other service providers or business partners. These include:
            </LegalParagraph>
            <LegalUnorderedList>
              <LegalUnorderedListItem>Payment processors and facilitators.</LegalUnorderedListItem>
              <LegalUnorderedListItem>
                Background check and identity verification providers (as applicable)
              </LegalUnorderedListItem>
              <LegalUnorderedListItem>Cloud storage providers.</LegalUnorderedListItem>
              <LegalUnorderedListItem>
                Marketing partners and marketing platform providers, including social media
                advertising services
              </LegalUnorderedListItem>
              <LegalUnorderedListItem>Data analytics providers.</LegalUnorderedListItem>
              <LegalUnorderedListItem>
                Research partners, including those performing surveys or research projects in
                partnership with the Company or on the Company&apos;s behalf
              </LegalUnorderedListItem>
              <LegalUnorderedListItem>
                Vendors that assist the Company to enhance the safety and security of its apps
              </LegalUnorderedListItem>
              <LegalUnorderedListItem>
                Consultants, lawyers, accountants, and other professional service providers
              </LegalUnorderedListItem>
              <LegalUnorderedListItem>Insurance and financing partners</LegalUnorderedListItem>
              <LegalUnorderedListItem>
                Vehicle solution vendors or third-party vehicle suppliers
              </LegalUnorderedListItem>
            </LegalUnorderedList>
          </LegalOrderedListItem>
          <LegalOrderedListItem>
            <Box as='span' fontWeight={'bold'}>
              For legal reasons or in the event of a dispute:
            </Box>
            <LegalParagraph>
              The Company may share Users&apos; personal data if we believe it&apos;s required by
              applicable law, regulation, operating license or agreement, legal process or
              governmental request, or where the disclosure is otherwise appropriate due to safety
              or similar concerns. This includes sharing personal data with law enforcement
              officials, public health officials, other government authorities, or other third
              parties as necessary to enforce our User Agreement or other policies; to protect the
              Company&apos;s rights or property or the rights, safety, or property of others; or in
              the event of a claim or dispute relating to the use of our services. If you use
              another person&apos;s credit card, we may be required by law to share your personal
              data, including trip or order information, with the owner of that credit card.
            </LegalParagraph>
            <LegalParagraph>
              This also includes sharing personal data with others in connection with, or during
              negotiations of, any merger, sale of company assets, consolidation or restructuring,
              financing, or acquisition of all or a portion of our business by or into another
              company.
            </LegalParagraph>
          </LegalOrderedListItem>
        </LegalOrderedList>
        <LegalSubHeading>8. Marketing</LegalSubHeading>
        <LegalParagraph>
          The Company may send its Users information about products and services of the Company,
          always with the consent of the User. Clients consent to marketing and promotional
          communication when they enroll for the Services pursuant to the User Agreement.
        </LegalParagraph>
        <LegalParagraph>
          If you have agreed to receive such marketing materials, you may always opt out at a later
          date. You have the right at any time to stop the Company from contacting you for marketing
          purposes.
        </LegalParagraph>
        <LegalSubHeading>9. What are your data protection rights?</LegalSubHeading>
        <LegalParagraph>
          The Company is committed to making you fully aware of all of your data protection rights.
          Every User is entitled to the following:
        </LegalParagraph>
        <LegalParagraph>
          <Box as='span' fontWeight={'bold'}>
            The right to access
          </Box>{' '}
          - You have the right to request from the Company copies of your personal data. We may
          charge you a small fee for this service.
        </LegalParagraph>
        <LegalParagraph>
          <Box as='span' fontWeight={'bold'}>
            The right to rectification
          </Box>{' '}
          - You have the right to request that the Company correct any information you believe is
          inaccurate. You also have the right to request the Company to complete information you
          believe is incomplete.
        </LegalParagraph>
        <LegalParagraph>
          <Box as='span' fontWeight={'bold'}>
            The right to erasure
          </Box>{' '}
          - You have the right to request that the Company erase your personal data, under certain
          conditions.
        </LegalParagraph>
        <LegalParagraph>
          <Box as='span' fontWeight={'bold'}>
            The right to restrict processing
          </Box>{' '}
          - You have the right to request that the Company restrict the processing of your personal
          data, under certain conditions.
        </LegalParagraph>
        <LegalParagraph>
          <Box as='span' fontWeight={'bold'}>
            The right to object to processing
          </Box>{' '}
          - You have the right to object to the Company&apos;s processing of your personal data,
          under certain conditions.
        </LegalParagraph>
        <LegalParagraph>
          <Box as='span' fontWeight={'bold'}>
            The right to data portability
          </Box>{' '}
          - You have the right to request that the Company transfer the data collected to another
          organization, or directly to you, under certain conditions.
        </LegalParagraph>
        <LegalParagraph>
          Clients who voluntarily provide their personal data in order for the Company to provide
          Services do so pursuant to the Company&apos;s User Agreement. The retention, return,
          transfer, and destruction of personal data provided for such purposes is subject to the
          terms of such User Agreement. If you make a request, the Company has one month to respond
          to you. If you would like to exercise any of these rights, please contact us at the
          information provided below.
        </LegalParagraph>

        <LegalSubHeading>10. What are cookies?</LegalSubHeading>
        <LegalParagraph>
          Cookies are text files placed on your computer to collect standard Internet log
          information and visitor behavior information. When you visit the Site, we may collect
          information from you automatically through cookies or similar technology. For further
          information, visit{' '}
          <LegalLink href='https://allaboutcookies.org/'>allaboutcookies.org</LegalLink>.
        </LegalParagraph>

        <LegalSubHeading>11. How do we use cookies?</LegalSubHeading>
        <LegalParagraph>
          The Company uses cookies and similar technologies for purposes such as:
        </LegalParagraph>
        <LegalUnorderedList>
          <LegalUnorderedListItem>Authenticating Users</LegalUnorderedListItem>
          <LegalUnorderedListItem>Remembering User preferences and settings</LegalUnorderedListItem>
          <LegalUnorderedListItem>Determining the popularity of content</LegalUnorderedListItem>
          <LegalUnorderedListItem>
            Delivering and measuring the effectiveness of advertising campaigns
          </LegalUnorderedListItem>
          <LegalUnorderedListItem>
            Analyzing site traffic and trends, and generally understanding the online behaviors and
            interests of people who interact with our services
          </LegalUnorderedListItem>
        </LegalUnorderedList>
        <LegalParagraph>
          We may also allow others to provide audience measurement and analytics services for us, to
          serve advertisements on our behalf across the Internet, and to track and report on the
          performance of those.
        </LegalParagraph>

        <LegalSubHeading>12. How to manage your cookies?</LegalSubHeading>
        <LegalParagraph>
          You can set your browser not to accept cookies, and you can remove cookies from your
          browser. However, in a few cases, some of the Site features may not function as a result.
        </LegalParagraph>

        <LegalSubHeading>13. Privacy policies of other websites</LegalSubHeading>
        <LegalParagraph>
          The Site contains links to other websites. This Policy applies only to the Site. If you
          navigate to any other website from a link on the Site, you should review the privacy
          policy of such website.
        </LegalParagraph>

        <LegalSubHeading>14. Changes to our privacy policy</LegalSubHeading>
        <LegalParagraph>
          It is our policy to post any changes we make to our Policy on this page. If we make
          material changes to how we treat our users&apos; personal information, through a notice on
          the Website home page or in the Application. The date the Policy was last revised is
          identified at the top of the page. You are responsible for ensuring we have an up-to-date
          active and deliverable email address for you, and for periodically visiting our Resources
          and this Policy to check for any changes.
        </LegalParagraph>

        <LegalSubHeading>15. How to contact us</LegalSubHeading>
        <LegalParagraph>
          If you have any questions about this Policy, the data we hold from you, or you would like
          to exercise one of your data protection rights, please do not hesitate to contact us:
        </LegalParagraph>
        <LegalParagraph>
          Email us at:{' '}
          <LegalLink href='mailto:Support@Fsgxchange.com'>Support@Fsgxchange.com</LegalLink>
        </LegalParagraph>
      </Container>
    </div>
  )
}
