import { Box, Flex } from '@chakra-ui/react'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useSetRun } from '../common/JoyrideWrapper'
import { AmplitudeEvents, useTrackAction } from '../AmplitudeProvider'
import { useDprWithOs } from 'hooks/useDprWithOs'

export const HowToUseLink: FC<{
  linkImage: (size?: number) => React.ReactElement
  linkActiveImage: (size?: number) => React.ReactElement
  linkName: string
  variant?: 'mobile' | 'desktop'
}> = ({ linkImage, linkActiveImage, linkName, variant = 'desktop' }) => {
  const dpr = useDprWithOs()
  const track = useTrackAction()
  const setRun = useSetRun()
  const { t } = useTranslation()
  const isMobile = variant === 'mobile'
  const size = isMobile ? 26 : 20 / dpr
  return (
    <Box
      onClick={() => {
        track?.(AmplitudeEvents.RESET_TUTORIAL)
        setRun(true)
      }}
      cursor='pointer'
      _hover={{
        '.text': {
          opacity: 1,
        },
        '.inactive-icon': {
          display: 'none',
        },
        '.active-icon': {
          display: 'block',
        },
      }}
    >
      <Flex
        w='full'
        h={isMobile ? 'full' : '2.85rem'}
        borderRadius={'0.85rem'}
        // bg={isMobile ? 'transparent' : 'rgba(255,255,255, 0.12)'}
        px='1.14rem'
        alignItems={'center'}
        justifyContent={isMobile ? 'center' : 'flex-start'}
        gap={isMobile ? '0' : '0.85rem'}
        direction={isMobile ? 'column' : 'row'}
      >
        <Box fontSize={'2.14rem'} className='inactive-icon'>
          {linkImage(size)}
        </Box>
        <Box fontSize={'2.14rem'} className='active-icon' display={'none'}>
          {linkActiveImage(size)}
        </Box>

        <Box
          color={'white'}
          fontSize={'1.14rem'}
          fontWeight={600}
          opacity={0.6}
          whiteSpace='nowrap'
          className='text'
        >
          {t(linkName)}
        </Box>
      </Flex>
    </Box>
  )
}
