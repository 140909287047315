import { Box } from '@chakra-ui/react'
import React, { FC } from 'react'
import { Link, LinkProps } from 'react-router-dom'

type Props = LinkProps & {
  fontWeight?: number
}

export const FSGLink: FC<Props> = ({ children, fontWeight, ...props }) => {
  return (
    <Link {...props}>
      <Box as='span' fontWeight={fontWeight} color='brand.primary.purple'>
        {children}
      </Box>
    </Link>
  )
}
