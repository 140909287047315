import { Box, Flex, Progress } from '@chakra-ui/react'
import React, { FC } from 'react'
import { Deal } from 'types'

interface Props {
  deal: Deal
  maxWidth?: string
  minWidth?: string
}

export const ProgressRenderer: FC<Props> = ({ deal, maxWidth, minWidth }) => {
  return (
    <Flex alignItems={'center'} gap='0.85rem'>
      <Progress
        bg='brand.black.100'
        borderRadius={'0.57rem'}
        width='100%'
        value={deal.progress}
        maxWidth={maxWidth}
        minWidth={minWidth}
      />
      <Box>{(+deal.progress).toFixed(2) || 0}%</Box>
    </Flex>
  )
}
