export const fonts = {
  fonts: {
    body: `'Nunito Sans', sans-serif`,
    heading: `'Nunito Sans', sans-serif`,
  },
  fontSizes: {
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.15rem',
    xl: '1.28rem',
    '2xl': '1.42rem',
    '3xl': '1.71rem',
    '4xl': '2.28rem',
    '5xl': '3rem',
    '6xl': '3.75rem',
    '7xl': '4.5rem',
    '8xl': '6rem',
    '9xl': '8rem',
  },
}
