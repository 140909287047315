import { Stack, Image, Box } from '@chakra-ui/react'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  image: string
  title: string
  content: string
  docLink: string
}

export const DocumentCard: FC<Props> = (props) => {
  const { t } = useTranslation()
  return (
    <Stack bg='brand.black.100' borderRadius={'1.14rem'} p='1.71rem' spacing={'1.14rem'}>
      <Image src={props.image} w='1.28rem' h='2rem' />
      <Stack spacing={'0.57rem'}>
        <Box textStyle={'cardTitle'} as='h4'>
          {t(props.title)}
        </Box>
        <Box fontSize={'1.14rem'} color='brand.black.700' lineHeight={'150%'}>
          {t(props.content)}
        </Box>
      </Stack>
      <Box as='a' href={props.docLink} textStyle='link' target={'_blank'}>
        Download
      </Box>
    </Stack>
  )
}
