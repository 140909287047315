import { Box, Button, useDisclosure } from '@chakra-ui/react'
import { FSGPasswordInput, PasswordStrengthIndicator } from 'components'
import { SuccessModal } from 'components/modals/SuccessModal'
import { FormikProvider, useFormik } from 'formik'
import { AmplitudeEvents, useTrackAction } from 'pages/MainLayout/AmplitudeProvider'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useChangePasswordMutation } from 'store/services/user.api'
import { ChangePassword as ChangePasswordModel } from 'types'

interface Props {
  onSuccess: () => void
}

export const ChangePassword: FC<Props> = ({ onSuccess }) => {
  const { t } = useTranslation()
  const track = useTrackAction()
  const [changePassword, { isLoading }] = useChangePasswordMutation()
  const { onOpen } = useDisclosure()
  const formik = useFormik<ChangePasswordModel>({
    initialValues: {
      oldPassword: '',
      password: '',
      passwordConfirm: '',
    },
    onSubmit: async (val) => {
      const resp = await changePassword(val)
      if ('data' in resp) {
        onSuccess()
        onOpen()
        track?.(AmplitudeEvents.CHANGE_PASSWORD)
        formik.resetForm()
      }
    },
  })
  const { values, handleChange, handleSubmit } = formik

  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit}>
        <FSGPasswordInput
          name='oldPassword'
          label={t('fields.currentPassword.label')}
          placeholder={t('fields.currentPassword.placeholder')}
          showPassword={true}
          value={values.oldPassword}
          mb='1.71rem'
          onChange={handleChange}
        />
        <FSGPasswordInput
          name='password'
          label={t('fields.newPassword.label')}
          placeholder={t('fields.newPassword.placeholder')}
          showPassword={true}
          value={values.password}
          mb='1.71rem'
          onChange={handleChange}
        />
        <FSGPasswordInput
          name='passwordConfirm'
          label={t('fields.repeatNewPassword.label')}
          placeholder={t('fields.repeatNewPassword.placeholder')}
          showPassword={true}
          value={values.passwordConfirm}
          mb='1.71rem'
          onChange={handleChange}
        />
        <Box w='full' mb='1.71rem'>
          <PasswordStrengthIndicator password={values.password}></PasswordStrengthIndicator>
        </Box>
        <Button type='submit' w='full' isLoading={isLoading}>
          {t('buttons.save')}
        </Button>
        {/* <SuccessModal
          isOpen={isOpen}
          onConfirm={handleModalClose}
          onClose={onClose}
          title={t('settings.security.passwordSuccessModal.title')}
          subTitle={t('settings.security.passwordSuccessModal.subTitle')}
        ></SuccessModal> */}
      </form>
    </FormikProvider>
  )
}
