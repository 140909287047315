import { Box, Flex } from '@chakra-ui/react'
import React, { FC } from 'react'
import { DealLabel } from 'types'
import { convertHexToRGBA } from 'utils/hexToRgba'

const labelColor: Record<DealLabel, string> = {
  [DealLabel.GOOD_DEAL]: '#0066FF',
  [DealLabel.LOWEST_PRICE]: '#00A524',
}

export const LabelRenderer: FC<{ label?: number }> = ({ label }) => {
  if (!label) return <></>
  const rank =
    label && (+label === 1 ? DealLabel.LOWEST_PRICE : +label === 2 ? DealLabel.GOOD_DEAL : null)
  if (!rank) return <></>
  return (
    <Flex borderRadius={'0.42rem'} fontSize={'0.85rem'} color={labelColor[rank]}>
      <Flex
        px='0.85rem'
        py='0.28rem'
        borderRadius={'0.42rem'}
        bg={convertHexToRGBA(labelColor[rank], 12)}
        fontSize={'0.85rem'}
        color={labelColor[rank]}
        justifyContent='center'
        w={'7.14rem'}
      >
        {rank}
      </Flex>
    </Flex>
  )
}
