import { Box, Flex, Stack, Image, Grid, Button } from '@chakra-ui/react'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Currency, ModalProps } from 'types'
import { Trans, useTranslation } from 'react-i18next'
import addFundsImage from 'assets/images/transactionTypes/add-funds.svg'
import { CurrencySelector, FSGModalWrapper, WarningMessage } from 'components'
import { useSelector } from 'react-redux'
import { getPayinAccountsByCurrency } from 'store/selectors/user'
import { InfoIcon } from '@chakra-ui/icons'
import { NGNAddFunds } from './NGNAddFunds'
import { CADAddFunds } from './CADAddFunds'

export const AddFunds: FC<ModalProps> = (props) => {
  const { t } = useTranslation()
  const ref = useRef<{ submit: () => void }>(null)
  const payinAccounts = useSelector(getPayinAccountsByCurrency)
  const [interacReference, setReference] = useState<string>()
  const [currency, setCurrency] = useState<Currency | null>()
  const name = currency?.name || ''

  const handleClick = useCallback(async () => {
    if (currency?.slug === 'CAD') {
      ref.current?.submit?.()
    }
    props.onClose()
  }, [interacReference, props.onClose, currency, payinAccounts])

  useEffect(() => {
    setReference('')
  }, [currency, props.isOpen])

  return (
    <FSGModalWrapper {...props} size={{ base: 'full', md: 'md' }}>
      <Stack spacing={'1.14rem'} mb='1.14rem'>
        <Flex justifyContent={'center'}>
          <Image src={addFundsImage}></Image>
        </Flex>

        <Box textAlign={'center'} fontSize={['1.71rem']} fontWeight={800}>
          {t('wallet.addingFunds')}
        </Box>
        <Box textAlign={'center'} fontSize={['1.14rem']}>
          {t('wallet.proceedAddingFunds')}
        </Box>
        <CurrencySelector onCurrencyChange={(currency) => setCurrency(currency)}></CurrencySelector>

        <Box bg='brand.black.100' borderRadius={'1.14rem'} p='1.14rem'>
          <Box fontSize={['1.8rem']} fontWeight={800} mb={'1.14rem'}>
            {t('wallet.addingInstructions.title')}
          </Box>
          <Stack spacing={'0.85rem'}>
            {currency?.slug ? (
              <>
                <Box>
                  {currency.slug === 'NGN' ? (
                    <Trans
                      i18nKey={'wallet.addingInstructions.text'}
                      values={{
                        name,
                        account: 'account',
                        additionalText: '',
                      }}
                      components={{ 1: <strong />, 2: <strong /> }}
                    >
                      {`<1>{{name}}</1> {{account}} {{additionalText}}`}
                    </Trans>
                  ) : (
                    <Trans
                      i18nKey={'wallet.addingInstructions.textCad'}
                      values={{
                        name,
                        account: 'email',
                        additionalText: '',
                      }}
                      components={{ 1: <strong />, 2: <strong /> }}
                    >
                      {`<1>{{name}}</1> {{account}} {{additionalText}}`}
                    </Trans>
                  )}
                </Box>
                {currency?.slug === 'NGN' ? (
                  <NGNAddFunds></NGNAddFunds>
                ) : (
                  <CADAddFunds
                    onClose={props.onClose}
                    currencyId={currency?.id}
                    ref={ref}
                  ></CADAddFunds>
                )}
              </>
            ) : (
              <WarningMessage>
                <Trans
                  i18nKey={'wallet.addingInstructions.noAccount'}
                  values={{ currency: currency?.name || '' }}
                  components={{ 1: <strong /> }}
                >
                  {`<1>{{currency}}</1>`}
                </Trans>
              </WarningMessage>
            )}
          </Stack>
        </Box>
      </Stack>
      <Flex gap='0.57rem' alignItems={'center'}>
        <InfoIcon fontSize={'1.14rem'} color={'brand.secondary.blue'} opacity={0.2}></InfoIcon>
        {t('wallet.addingInstructions.promise')}
      </Flex>
      <Grid templateColumns={'1fr 1fr'} gap={'2.14rem'} mt='2.28rem'>
        <Button variant={'outline'} onClick={props.onClose}>
          {t('buttons.cancel')}
        </Button>
        <Button onClick={handleClick}>{t('wallet.addingInstructions.doneButton')}</Button>
      </Grid>
    </FSGModalWrapper>
  )
}
